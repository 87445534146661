<template>
  <div>
    <van-search v-model="searchName" placeholder="找你想要了解的" @search="onSearch"/>
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <van-empty description="" v-if="this.list.length==0"/>
      <div style="margin: 10px 5px 10px 5px;border:1px solid #f3f2f2;border-radius: 5px;background-color: white"
           v-for="item in list"
           :key="item.doctorId"
           @click="goInfo(item.id)">
        <div style="margin: 10px 10px 0px 10px;">
          <van-row>
            <van-col span="3">
              <van-image v-if="item.detailList"
                         radius="5"
                         width="40px"
                         height="40px"
                         :src="kyz.$getFileAccessHttpUrl(item.detailList.ansAvatar)"
              />
              <van-image v-else
                         radius="5"
                         width="40px"
                         height="40px"
                         :src="kyz.$getFileAccessHttpUrl(item.askAvatar)"
              />
            </van-col>
            <van-col span="16" style="font-size: 12pt;color:#6d6c6c">
              <div v-if="item.detailList">{{ item.detailList.ansName }}</div>
              <div v-else>{{ item.askName }}</div>
              <div style="color: gray;font-size: 9pt">{{ item.createTime.substring(0, 10) }} 发布了问题</div>
            </van-col>
          </van-row>
        </div>
        <div style="margin: 5px 10px 5px 10px;font-weight: bold;font-size: 14pt">
          {{ item.content }}
        </div>
        <div style="margin: 5px 10px 5px 10px;font-weight: bold;font-size: 14pt;color: #4f998a" v-if="item.detailList">
          {{ item.detailList.title }}
        </div>
        <div style="margin: 5px 10px 5px 10px;font-size: 11pt;color: gray" v-if="item.detailList">
          {{ item.detailList.content }}
        </div>
        <van-divider style="margin: 10px 10px 10px 10px"/>
        <div style="margin: 12px 10px 10px 10px;color:#a2a1a1;">
          <van-row>
            <van-col span="5">
              <div style="font-size: 12pt;text-align: left">{{ item.readCount == 0 ? '1' : item.readCount }}看过</div>
            </van-col>
            <!--            <van-col span="14">-->
            <!--              <div style="font-size: 14pt;text-align: right;margin-right: 1px">-->
            <!--                <van-icon name="good-job-o"/>-->
            <!--                <div style="font-size: 12pt;margin-left: 5px;float: right"> 10</div>-->
            <!--              </div>-->
            <!--            </van-col>-->
            <van-col span="19">
              <div style="font-size: 14pt;text-align: right;margin-right: 1px">
                <van-icon name="chat-o"/>
                <div style="font-size: 12pt;margin-left: 5px;float: right"> {{ item.msgCount }}</div>
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
    </van-list>
    <van-tabbar v-model="active" active-color="#2a9f93">
      <van-tabbar-item icon="home-o">推荐</van-tabbar-item>
      <van-tabbar-item @click="goAdd()">
        <template v-slot:icon>
          <!--          <van-icon name="add" size="40" color="#2a9f93"/>-->
          <div
              style="background-color: #2a9f93;width: 40px;height:35px;text-align: center;line-height: 35px;border-radius: 5px">
            <span style="color:#ffffff;">
            问
          </span>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="user-o" @click="goMy()">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import {Cell, CellGroup, Field, Dialog, Button, Search, Divider, Tabbar, TabbarItem, List} from 'vant';
import {postAction} from '@/api/manage';
import wx from 'weixin-js-sdk'


export default {
  components: {
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Field.name]: Field, [List.name]: List,
    [Dialog.name]: Dialog,
    [Search.name]: Search, [Divider.name]: Divider,
    [Dialog.Component.name]: Dialog.Component,
    [CellGroup.name]: CellGroup, [Tabbar.name]: Tabbar, [TabbarItem.name]: TabbarItem
  },
  data() {
    return {
      active: 0,
      userInfo: {},
      list: [],
      formHospId: this.$route.query.formHospId,
      kyz: this,
      searchName: '',
      doctorType: -1,
      pageNumber: 1,
      loading: false,
      finished: false,
      show: true,
    };
  },
  mounted() {
    this.bindHosp()
    var that = this;
    // 微信分享配置
    wx.ready(function () {
      wx.updateTimelineShareData({
        title: '闻诚星球', // 分享标题
        link: 'http://wap.wenchengjiankang.com/xingqiu/index', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'http://wap.wenchengjiankang.com/img/logo.jpg', // 分享图标
        success: function () {
          // 设置成功
        }
      })
      wx.updateAppMessageShareData({
        title: '闻诚星球', // 分享标题
        link: 'http://wap.wenchengjiankang.com/xingqiu/index', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'http://wap.wenchengjiankang.com/img/logo.jpg', // 分享图标
        desc: '', // 分享描述
        success: function () {
          // 设置成功
        }
      })
    })
  },
  methods: {
    loadUserData(urls) {
      let that = this;
      //发送 post 请求
      this.$nextTick(() => {
        postAction('/web/api/xingqiu/getUserInfo', {}).then(function (response) {
          console.log(response.result);
          that.doctorType = response.result.doctorType;
          that.$router.push({name: urls});
        })
      })
    },
    //绑定医院
    bindHosp() {
      let that = this;
      postAction('/web/api/xingqiu/updatePatientHopsitalId', {
        hospitalId: that.formHospId
      }).then(function (response) {
        console.log(response.result);
      })
    },
    goInfo(msgId) {
      this.$router.push({name: 'xingqiu/questionInfo', query: {msgId: msgId}});
    },
    goAdd() {
      this.loadUserData('xingqiu/addQuestion')
    },
    goMy() {
      this.loadUserData('xingqiu/myQuestion')
    },
    onLoad() {
      this.loadData();
    },
    onSearch() {
      this.pageNumber = 1;
      this.list = []
      this.loadData();
    },
    loadData() {
      let that = this;
      //发送 post 请求
      this.$nextTick(() => {
        postAction('/web/api/xingqiu/messageList', {
          "pageNo": that.pageNumber, "searchName": this.searchName
        }).then(function (response) {
          // eslint-disable-next-line no-debugger
          console.log(response.result);
          // 异步更新数据
          for (let i = 0; i < 10; i++) {
            if (response.result.data.records[i]) {
              that.list.push(response.result.data.records[i]);
            }
          }
          that.pageNumber = that.pageNumber + 1;
          // 加载状态结束
          that.loading = false;
          // 数据全部加载完成
          if (that.list.length >= response.result.data.total) {
            that.finished = true;
          }
        })
      })
    },
  }
};
</script>

<style lang="less">
</style>
