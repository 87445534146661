<template>
    <div class="container">
        <div class="page-detail">
            <div class="module-pic">
                <div class="container_box" id="audio_detail">
                    <img  v-if="hasBuy==0" v-lazy="kyz.$getFileAccessHttpUrl(videoDetail.pic)" class="audio_cover" style="width: 100%"/>
                    <div v-if="hasBuy==0">
                        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
                            当前内容购买专栏后才能查看
                        </van-notice-bar>
                    </div>
                    <div v-if="hasBuy==1">
                        <vue-aliplayer-v2 ref="VueAliplayerV2" :options="options"/>
                    </div>
                </div>
            </div>
            <div class="module-main">
                <div class="base-info-block"><!----><!---->
                    <div class="price">
                        <div>
                            <div>
                            <span class="pl-container"
                                  style="color: rgb(255, 68, 68); font-size: 20px; font-weight: 600;">
                                <span class="pl-item">
                                    <span class="pl-price-container ">
                                        <span class="pl-price-symbol">￥</span>
                                        <span class="pl-int-number-1">{{videoSpecial.amount}}</span>
                                    </span>
                                </span>
                            </span>
                            </div>
                            <div class="origin"></div>
                        </div>
                    </div>
                    <div>
                        <div class="title-area">
                            <div>
                                <span class="title">{{videoDetail.title}}</span>
                            </div>
                        </div>
                        <div class="knowledge-info">
                            <table class="table">
                                <tr class="knowledge-info-line">
                                    <td>
                                        <span class="knowledge-info-label">已学</span>
                                    </td>
                                    <td>
                                        <span>{{videoSpecial.playCount}}人</span>
                                    </td>
                                </tr>
                                <tr class="knowledge-info-line">
                                    <td>
                                        <span class="knowledge-info-label">讲师</span>
                                    </td>
                                    <td>
                                        <span>{{videoSpecial.doctorName}}</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="column-entry-block" @click="goInfo(videoSpecial.id)">
                    <div class="cover imgWrap" style="width: 80px; height: 46px;">
                        <img v-lazy="kyz.$getFileAccessHttpUrl(videoSpecial.pic)" style="width: 100%"/>
                    </div>
                    <div><p class="title">
                        {{videoSpecial.title}}
                    </p>
                        <div class="desc">
                            <span class="desc-item">已更新{{videoSpecial.fileCount}}期</span>
                            <span class="link">查看专栏
                                <i class="arrow van-icon van-icon-arrow"></i>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="intro-block">
                    <div class="van-cell-group">
                        <div></div>
                        <div>
                            <div class="intro cap-richtext" style="background-color: rgb(255, 255, 255);">
                                <div v-html="videoDetail.remark" v-if="hasBuy==0"></div>
                                <div v-html="videoDetail.buyRemark" v-if="hasBuy==1"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block-exam"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        DropdownMenu,
        DropdownItem,
        Button,
        List, Tab, Tabs, NoticeBar,
        Tag,
        Grid,
        GridItem, Slider,
        Image
    } from 'vant';
    import VueAliplayerV2 from 'vue-aliplayer-v2';
    import {postAction} from '@/api/manage';
    import wx from 'weixin-js-sdk'

    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tag.name]: Tag,
            [Grid.name]: Grid,
            [GridItem.name]: GridItem,
            [Image.name]: Image, [Tab.name]: Tab, [Tabs.name]: Tabs,
            [Slider.name]: Slider, [NoticeBar.name]: NoticeBar, VueAliplayerV2: VueAliplayerV2.Player
        },

        data() {
            return {
                active: 0,
                orderType: 0,
                list: [],
                dataId: this.$route.query.id,
                videoSpecial: {},
                videoDetail: {},
                hasBuy: {},
                kyz: this,
                departId: 0,
                options: {
                    "skinLayout": [
                        {
                            "name": "bigPlayButton",
                            "align": "blabs",
                            "x": 30,
                            "y": 80
                        },
                        {
                            "name": "H5Loading",
                            "align": "cc"
                        },
                        {
                            "name": "errorDisplay",
                            "align": "tlabs",
                            "x": 0,
                            "y": 0
                        },
                        {
                            "name": "infoDisplay"
                        },
                        {
                            "name": "tooltip",
                            "align": "blabs",
                            "x": 0,
                            "y": 56
                        },
                        {
                            "name": "thumbnail"
                        },
                        {
                            "name": "controlBar",
                            "align": "blabs",
                            "x": 0,
                            "y": 0,
                            "children": [
                                {
                                    "name": "progress",
                                    "align": "blabs",
                                    "x": 0,
                                    "y": 44
                                },
                                {
                                    "name": "playButton",
                                    "align": "tl",
                                    "x": 15,
                                    "y": 12
                                },
                                {
                                    "name": "timeDisplay",
                                    "align": "tl",
                                    "x": 10,
                                    "y": 7
                                },
                                {
                                    "name": "fullScreenButton",
                                    "align": "tr",
                                    "x": 10,
                                    "y": 12
                                },
                                {
                                    "name": "volume",
                                    "align": "tr",
                                    "x": 5,
                                    "y": 10
                                }
                            ]
                        }
                    ]
                }
            };
        },
        mounted() {
            // 微信分享配置
            const that=this
            wx.ready(function () {
                wx.updateTimelineShareData({
                    title: that.videoDetail.title, // 分享标题
                    link: 'http://wap.wenchengjiankang.com/patient/videoPlay?id='+that.videoDetail.id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: that.$getFileAccessHttpUrl(that.videoDetail.pic), // 分享图标
                    success: function () {
                        // 设置成功
                    }
                })
                wx.updateAppMessageShareData({
                    title: that.videoDetail.title, // 分享标题
                    link: 'http://wap.wenchengjiankang.com/patient/videoPlay?id='+that.videoDetail.id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: that.$getFileAccessHttpUrl(that.videoDetail.pic), // 分享图标
                    desc: '', // 分享描述
                    success: function () {
                        // 设置成功
                    }
                })
            })
            this.getVideoInfo()
        },
        methods: {
            getVideoInfo() {
                let that = this;
                //发送 post 请求
                postAction('/web/api/index/videoDataInfo', {
                    "dataId": this.dataId
                }).then(function (response) {
                    console.log(response.result);
                    // 异步更新数据
                    that.videoSpecial = response.result.videoSpecial
                    that.videoDetail = response.result.videoDetail
                    that.hasBuy = response.result.hasBuy
                    document.title = that.videoDetail.title;
                    that.options.source = that.videoDetail.fileUrl
                    that.options.autoplay = false
                    that.options.height='210px'
                    that.options.cover = that.$getFileAccessHttpUrl(that.videoDetail.pic)
                })
            },
            goInfo(id) {
                this.$router.push({name: 'patient/videoInfo', query: {id: id}});
            },
            play() {
                this.$refs.VueAliplayerV2.play()
            },

            pause() {
                this.$refs.VueAliplayerV2.pause();
            },

            replay() {
                this.$refs.VueAliplayerV2.replay();
            },

            getCurrentTime() {
                this.$refs.VueAliplayerV2.getCurrentTime();
            }
        },
        watch: {
            departId() {
                this.list = [];
                this.pageNumber = 1;
                this.loadData()
            }
        }
    };
</script>
<style lang="less" scoped>
    @import '/css/videoPlay.css';
</style>
