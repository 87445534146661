<template>
    <div>
        <van-nav-bar :right-text="types(orderInfo.orderFlag)">
            <div slot="left">
                <div style="margin-left: 1px;">
                    <span style="padding-top: -10px">订单状态</span>
                </div>
            </div>
        </van-nav-bar>
        <van-cell-group>
            <van-cell-group>
                <van-cell title="金额" v-model="orderInfo.amount">
                </van-cell>
                <van-cell title="是否套餐" :value="this.$sysDictFilter(orderInfo.orderType, 'orderType')">
                </van-cell>
                <van-cell title="订单编号" :value="orderInfo.ordersn"></van-cell>
                <van-cell title="下单时间" :value="orderInfo.createTime"></van-cell>
                <van-cell title="付款时间" v-if="orderInfo.payTime" :value="orderInfo.payTime"></van-cell>
                <van-cell title="支付方式" v-if="orderInfo.payTime" :value="this.$sysDictFilter(orderInfo.payFormFlag, 'payFormFlag')"></van-cell>
                <van-cell title="评价时间" v-if="orderInfo.commentTime" :value="orderInfo.commentTime">
                </van-cell>
                <van-cell title="评分"  v-if="orderInfo.commentTime">
                    <template #right-icon>
                        <van-rate :value="orderComment.star/2" readonly/>
                    </template>
                </van-cell>
            </van-cell-group>
        </van-cell-group>
        <van-field
                v-if="orderInfo.commentTime"
                rows="2"
                autosize
                label="评价内容"
                type="textarea"
                maxlength="500"
                placeholder=""
                :value="orderComment.content"
        />
        <van-button type="primary" size="large" @click="goback"
                    style="position: fixed;bottom: 0;background-color: #07c160">返回
        </van-button>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        Button, Field,
        List, Card, SubmitBar,
        Tab, Tabs, NavBar, Rate
    } from 'vant';
    import {postAction} from '@/api/manage';

    export default {
        components: {
            [NavBar.name]: NavBar,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Field.name]: Field,
            [Card.name]: Card,
            [Rate.name]: Rate,
            [SubmitBar.name]: SubmitBar,
        },

        data() {
            return {
                orderInfo: {},
                orderComment: {},
                value: 2,
                ordersn: this.$route.query.ordersn
            };
        },
        mounted() {
            this.onLoad()
        },
        methods: {
            onLoad() {
                //发送 post 请求
                let that = this;
                this.$nextTick(() => {
                    postAction('/web/api/patient/myOrderInfo', {
                        "ordersn": that.ordersn
                    }).then(function (response) {
                        console.log(response.result);
                        that.orderInfo = response.result.data;
                        that.orderComment = response.result.orderc;

                    })
                })
            },
            onSubmit(values) {
                console.log('submit', values);
            },
            types(tt) {
                if (tt == 0) {
                    return "待付款"
                } else if (tt == 20) {
                    return "待接单"
                } else if (tt == 30) {
                    return "待完成"
                } else if (tt == 40) {
                    return "待评价"
                } else if (tt == 100) {
                    return "已取消"
                } else if (tt == 50) {
                    return "已评价"
                }
            },
            goback() {
                history.go(-1)
            }
        }
    };
</script>

<style lang="less" scoped>
    .van-doc-demo-block__title {
        margin: 0;
        padding: 7px 14px 0px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 12px;
    }
</style>
