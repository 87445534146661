<template>
  <div>
    <van-nav-bar
        title="预约详情"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
    />
    <div style="border:1px solid #f3f2f2;background-color: white">
      <div style="margin: 10px 10px 0px 10px;">
        <van-row>
          <van-col span="3">
            <van-image
                style="margin-top: 5px"
                radius="5"
                width="35px"
                height="35px"
                :src="kyz.$getFileAccessHttpUrl(patientInfo.avatar)"
            />
          </van-col>
          <van-col span="16" style="font-size: 12pt;color:#6d6c6c;margin-left: -8px">
            <div>{{ msgData.checkName }}</div>
            <div style="color: gray;font-size: 9pt">{{ msgData.createTime }} 预约</div>
            <div style="color: gray;font-size: 9pt">支付金额：{{ msgData.checkPrice }}元</div>
            <!--            <div style="color: gray;font-size: 9pt">状态：{{ msgData.checkFlag == 1 ? '处理中' : '已处理' }}</div>-->
          </van-col>
        </van-row>
      </div>
      <div style="margin: 20px 10px 20px 10px;font-weight: bold;font-size: 13pt">
        {{ msgData.remark }}
      </div>
    </div>
    <div style="margin-top: 6px">
      <div style="border:1px solid #f3f2f2;background-color: white">
        <div style="margin: 15px 10px 5px 10px;font-size: 10pt;color: gray">用户信息：
        </div>
        <div style="margin: 0px 10px 5px 10px;font-size: 10pt;color: gray">
          {{ patientInfo.name }}[{{ patientInfo.mobile }}]<br>
          地址：{{ msgData.address }}<br>
          <div v-if="patientChildInfo">
            <van-divider/>
            <span style="font-size: 11pt">年龄:{{ kyz.$sysGetAge2(patientChildInfo.birthday) }}</span>
            <span style="font-size: 11pt;margin-left: 10px">性别:{{
                kyz.$sysDictFilter(patientChildInfo.sex, 'sex')
              }}</span><br>
            <span style="font-size: 11pt">身份证号:{{ patientChildInfo.idcard }}</span><br>
            <span style="font-size: 11pt"
                  v-if="patientChildInfo.chengrenFlag==0">出生体重:{{ patientChildInfo.weight }}<br></span>
            <span style="font-size: 11pt"
                  v-if="patientChildInfo.chengrenFlag==0">分娩方式:{{
                kyz.$sysDictFilter(patientChildInfo.fenmianFlag, 'fenmianFlag')
              }}<br></span>
            <span style="font-size: 11pt"
                  v-if="patientChildInfo.chengrenFlag==0">孕:{{ patientChildInfo.yunCount }} </span>
            <span style="font-size: 11pt"
                  v-if="patientChildInfo.chengrenFlag==0"> 产:{{ patientChildInfo.chanCount }}<br></span>
            <span style="font-size: 11pt"
                  v-if="patientChildInfo.chengrenFlag==0">喂养方式:{{
                kyz.$sysDictFilter(patientChildInfo.weiyangFlag, 'weiyangFlag')
              }}<br></span>
            <span style="font-size: 11pt"
                  v-if="patientChildInfo.chengrenFlag==1&&patientChildInfo.sex==0">初潮年龄:{{ patientChildInfo.chuchaoAge }}<br></span>
            <span style="font-size: 11pt"
                  v-if="patientChildInfo.chengrenFlag==1&&patientChildInfo.sex==0">月经周期:{{ patientChildInfo.yuejingRange }}<br></span>
            <span style="font-size: 11pt"
                  v-if="patientChildInfo.chengrenFlag==1&&patientChildInfo.sex==0">末次月经周期:{{
                patientChildInfo.moyuejingRange
              }}<br></span>
            <span style="font-size: 11pt">过敏史:{{ patientChildInfo.guomin }}</span><br>
            <span style="font-size: 11pt">既往疾病:{{ patientChildInfo.jiwangjibing }}</span><br>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 6px">
      <div style="border:1px solid #f3f2f2;background-color: white">
        <div style="margin: 15px 10px 5px 10px;font-size: 10pt;color: gray">医生处理：
        </div>
        <div style="margin: 15px 10px 5px 10px;font-size: 10pt;color: gray">
          <div class="image-upload-tile">添加照片 (检查单等)</div>
          <van-field name="uploader" label="">
            <template #input>
              <van-uploader v-model="imagePathShow" :max-count="6"
                            :after-read="onRead"
                            :before-delete="onDelete"
                            :before-read="beforeRead"/>
            </template>
          </van-field>
          <van-field
              v-model="content"
              rows="4"
              autosize
              type="textarea"
              maxlength="500"
              placeholder="内容（0-500字）"
              show-word-limit
          />
          <van-button type="primary" @click="addMessage" block round :loading="loading">提交</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Cell, NavBar,
  CellGroup,
  Field,
  Dialog,
  Button,
  Divider,
  Tabbar,
  TabbarItem,
  Popup, Uploader,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton
} from 'vant';
import {postAction, postActionMultipart} from '@/api/manage';
import {Toast} from 'vant';
import wx from 'weixin-js-sdk'

export default {
  components: {
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Field.name]: Field, [NavBar.name]: NavBar,
    [Dialog.name]: Dialog,
    [Popup.name]: Popup, [Uploader.name]: Uploader,
    [Divider.name]: Divider,
    [GoodsAction.name]: GoodsAction,
    [GoodsActionIcon.name]: GoodsActionIcon,
    [GoodsActionButton.name]: GoodsActionButton,
    [Dialog.Component.name]: Dialog.Component,
    [CellGroup.name]: CellGroup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  data() {
    return {
      active: 4,
      userInfo: {},
      id: this.$route.query.id,
      patientInfo: {},
      msgData: {},
      patientChildInfo: {},
      kyz: this,
      loading: false,
      title: '',
      content: '',
      userType: '',
      imagePath: [],
      imagePathTmp: [],
      imagePathShow: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    onClickLeft() {
      history.go(-1)
    },
    loadData() {
      let that = this;
      postAction('/web/api/doctor/checkOrder/orderCheckInfo', {id: that.id}).then(function (response) {
        console.log(response.result);
        that.msgData = response.result.data
        that.patientInfo = response.result.patientInfo
        that.patientChildInfo = response.result.patientChildInfo
        that.content = that.msgData.sysRemark;
        if (that.msgData.sysImg) {
          that.imagePath = [];
          that.imagePathShow = [];
          that.msgData.sysImg.split(",").map(x => {
            that.imagePath.push(that.$getFileAccessHttpUrl(x));
            that.imagePathShow.push({url: that.$getFileAccessHttpUrl(x)});
          })
        }
      })
    },
    addMessage() {
      if (this.content == '' || this.content.trim() == '') {
        Toast("请输入内容信息");
        return
      }
      this.loading = true;
      let that = this;
      postAction('/web/api/doctor/checkOrder/updateOrderCheck', {
        id: that.id,
        remark: this.content,
        "pics": that.imagePath.join(','),
      }).then(function (response) {
        console.log(response.result);
        Toast("保存成功");
        that.loadData();
      }).finally(() => {
        this.loading = false;
      })
    },
    dataURLtoFile(dataurl, filename) { // 将base64转换为file文件
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, {type: mime})
    },
    beforeRead(file) { // 上传之前检测图片类似返回true和false会影响到onRead函数
      let regex = /(.jpg|.jpeg|.png|.bmp)$/
      if (!regex.test(file.type)) {
        Toast('图片格式不支持上传')
        return false
      } else {
        return true
      }
    },
    onDelete(file) { // 上传之前检测图片类似返回true和false会影响到onRead函数
      console.log(this.imagePath)
      // eslint-disable-next-line no-debugger
      debugger
      var ipt = [];
      for (let i = 0; i < this.imagePathTmp.length; i++) {
        var index = this.imagePathTmp[i].url.indexOf(file.url);
        // eslint-disable-next-line no-empty
        if (index > -1) {
          ip1
        } else {
          ipt.push(this.imagePathTmp[i]);
        }
      }

      var urlTmp = file.url.substring(file.url.lastIndexOf("/att"));
      var ip1 = [];
      for (let i = 0; i < this.imagePath.length; i++) {
        var indexpath = this.imagePath.indexOf(urlTmp);
        // eslint-disable-next-line no-empty
        if (indexpath > -1) {
        } else {
          ip1.push(this.imagePath[i]);
        }
      }
      this.imagePathTmp = ipt;
      this.imagePath = ip1;
      this.imagePathShow = ipt;
      return true
    },
    onRead(file) { // 上传图片
      // 大于10MB的图片都缩小像素上传
      if (file.file.size > 1485760) {
        let canvas = document.createElement('canvas') // 创建Canvas对象(画布)
        let context = canvas.getContext('2d')
        let img = new Image()
        img.src = file.content // 指定图片的DataURL(图片的base64编码数据)
        img.onload = () => {
          canvas.width = 400
          canvas.height = 300
          context.drawImage(img, 0, 0, 400, 300)
          file.content = canvas.toDataURL(file.file.type, 0.92) // 0.92为默认压缩质量
          let files = this.dataURLtoFile(file.content, file.file.name)
          const data = new FormData()
          data.append('file', files)
          data.append('type', 'chat')
          postActionMultipart("publicinterface/getImgurlbyfile?imgtype=yuyuejiancha", {data}).then(res => {
            this.imagePath.push(res.content)
            console.log(222)
            this.imagePathTmp.push({url: this.$getFileAccessHttpUrl(res.content)})
            this.imagePathShow = this.imagePathTmp;
            Toast('上传成功')
          })
        }
      } else { //小于10M直接上传
        const data = new FormData()
        data.append('file', file.file)
        data.append('type', 'chat')
        console.log(1111)
        postActionMultipart("publicinterface/getImgurlbyfile?imgtype=yuyuejiancha", data).then(res => {
          this.imagePath.push(res.content)
          this.imagePathTmp.push({url: this.$getFileAccessHttpUrl(res.content)})
          console.log(333)
          this.imagePathShow = this.imagePathTmp;
          console.log(this.imagePathShow);
          Toast('上传成功')
        })
      }
    }
  }
};
</script>

<style lang="less">
.xuanfu {
  left: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  height: 80px;
  background-color: white;
  text-align: center;
  font-size: 15pt;
  line-height: 70px;
  color: #5889de;
}
</style>
