<template>
  <div>
    <div class="bg" style="text-align: center">
      <van-image class="aui-shop-logo" round
                 :src="this.$getFileAccessHttpUrl(userInfo.avatar)" width="75px"/>
      <div class="aui-shop-logo-txt" v-cloak>
        <!--                <van-button round type="primary" style="height: 30px;" v-if="goLogin">立即登录</van-button>-->
        {{ userInfo.name }}
      </div>
    </div>
    <van-cell-group class="user-group">
      <van-row class="user-links">
        <van-col span="8" @click="goMyOrder(20)">
          <van-icon name="label-o" :badge="ordersStatus[20]"/>
          待接单
        </van-col>
        <van-col span="8" @click="goMyOrder(30)">
          <van-icon name="tosend" :badge="ordersStatus[30]"/>
          咨询中
        </van-col>
        <van-col span="8" @click="goMyOrder(-1)">
          <van-icon name="records"/>
          全部订单
        </van-col>
      </van-row>
    </van-cell-group>

    <van-cell-group>
      <van-cell icon="clock-o" title="预约检查单" is-link @click="goMyCheckOrder()"/>
      <van-cell icon="gold-coin-o" title="我的佣金" :value="balanceLast+'元'" is-link @click="goMyBalanceList()"/>
      <van-cell icon="phone-o" title="联系我们" :value="this.$sysDictFilter(0, 'telephone')"
                :url="'tel:'+this.$sysDictFilter(0, 'telephone')"/>
      <van-cell icon="envelop-o" title="意见反馈" is-link @click="goFeedback()"/>
    </van-cell-group>
    <Footer :active="active"></Footer>
  </div>
</template>

<script>
import {Cell, CellGroup, Button} from 'vant';
import Footer from '@/view/doctor/user/footer'
import {postAction} from '@/api/manage';

export default {
  components: {
    [Cell.name]: Cell,
    [Button.name]: Button,
    Footer,
    [CellGroup.name]: CellGroup
  },
  data() {
    return {
      active: 3,
      userInfo: {},
      balanceLast: 0,
      ordersStatus: {}
    };
  },
  mounted() {
    this.loadData();
    this.getOrdersStatus();
  },
  methods: {
    goMyCheckOrder(){
      this.$router.push({name: 'doctor/yuyuejiancha/myOrderCheckList'});
    },
    loadData() {
      let that = this;
      //发送 post 请求
      postAction('/web/api/doctor/userInfo', {}).then(function (response) {
        console.log(response.result);
        that.userInfo = response.result.data;
        that.balanceLast = response.result.balanceLast;
      }).finally(() => {
      })
    },
    getOrdersStatus() {
      let that = this;
      //发送 post 请求
      postAction('/web/api/doctor/getOrdersStatus', {}).then(function (response) {
        console.log(response.result);
        that.ordersStatus = response.result.data;
      }).finally(() => {
      })
    },
    goFeedback() {
      this.$router.push({name: 'feedback'});
    },
    goMyBalanceList() {
      this.$router.push({name: 'doctor/myBalance'});
    },
    goMyOrder(type) {
      this.$router.push({name: 'doctor/myOrder', query: {type: type}});
    },
  }
};
</script>

<style lang="less">
.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}

.bg {
  background: linear-gradient(#54b279, #6bc193);
  height: 160px;
}

.aui-shop-logo {
  z-index: 99999;
  top: 35px;
}

.aui-shop-logo-txt {
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 99999;
  top: 126px;
  color: white;
}

.wHide {
  /*display: none;*/
}

.van-info {
  right: 46px;
}

.wShow {
  display: inline-block !important;
}
</style>
