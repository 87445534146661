<template>
    <div>
        <van-tabs v-model="active">
            <van-tab title="我的充值记录">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                        <van-card
                                v-for="item in list" :key="item.id"
                                :price="item.amount.toFixed(2)"
                                :title="times(item.createTime)"
                        >
                            <template #footer>
                                <span>充值方式：管理员充值</span>
                            </template>
                        </van-card>
                </van-list>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        Button, Field,
        List, Form, SubmitBar,
        Tag, Step, Steps, Tab, Tabs, Card
    } from 'vant';
    import {postAction} from '@/api/manage';

    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tag.name]: Tag,
            [Step.name]: Step,
            [Field.name]: Field,
            [Form.name]: Form,
            [SubmitBar.name]: SubmitBar,
            [Steps.name]: Steps, [Tab.name]: Tab, [Tabs.name]: Tabs, [Card.name]: Card,
        },

        data() {
            return {
                active: 0,
                list: [],
                loading: false,
                finished: false,
                pageNumber: 1
            };
        },
        methods: {
            onLoad() {
                this.loadData();
            },
            loadData() {
                let that = this;
                //发送 post 请求
                this.$nextTick(() => {
                    postAction('/web/api/patient/rechargeList', {
                        "pageNo": that.pageNumber
                    }).then(function (response) {
                        console.log(response.result);
                        // 异步更新数据
                        for (let i = 0; i < 10; i++) {
                            if (response.result.data.records[i]) {
                                that.list.push(response.result.data.records[i]);
                            }
                        }
                        that.pageNumber = that.pageNumber + 1;
                        // 加载状态结束
                        that.loading = false;
                        // 数据全部加载完成
                        if (that.list.length >= response.result.data.total) {
                            that.finished = true;
                        }
                    })
                })
            },
            times(t) {
                return "充值日期：" + this.$formatDate(t, 'yyyy-MM-dd');
            }
        }
    };
</script>

<style lang="less" scoped>
    .van-card__bottom {
        padding-bottom: 20px;
    }

    .van-card__title {
        font-size: 12pt;
    }

    .van-card__price {
        font-size: 13pt !important;
    }

    .van-card__price-integer {
        font-size: 13pt !important;
    }
</style>
