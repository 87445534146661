<template>
    <div>
        <van-nav-bar>
            <div slot="left">
                <div style="margin-left: 1px;">
                    <span style="padding-top: -10px">可提现金额：{{balanceLast}}元</span>
                </div>
            </div>
        </van-nav-bar>
        <van-field
                v-model="amount"
                name="amount"
                label="金额"
                placeholder="请填写金额"
        />
        <div style="margin: 16px;">
            <van-button round block type="primary" @click="toSub()" style="background-color: #07c160">
                提交
            </van-button>
        </div>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        Button, Field,
        List, Form, SubmitBar,
        Tag, NavBar
    } from 'vant';
    import {postAction} from '@/api/manage';
    import {Toast} from 'vant';

    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tag.name]: Tag,
            [Field.name]: Field,
            [Form.name]: Form, [NavBar.name]: NavBar,
            [SubmitBar.name]: SubmitBar,
        },

        data() {
            return {
                active: 0,
                amount: "",
                balanceLast: 0,
            };
        },
        mounted() {
            this.loadData()
        },
        methods: {
            loadData() {
                let that = this;
                //发送 post 请求
                postAction('/web/api/doctor/userInfo', {}).then(function (response) {
                    console.log(response.result);
                    that.balanceLast = response.result.balanceLast;
                }).finally(() => {
                })
            },
            toSub() {
                const that = this;
                if (this.amount == "" || this.amacr == 0) {
                    Toast("请输入提现金额");
                    return
                }
                postAction('/web/api/doctor/toCash', {"amount": that.amount}).then(function (response) {
                    console.log(response.result);
                    Toast(response.content);
                }).finally(() => {
                })
            },
            onConfirm() {
            },
        }
    };
</script>

<style lang="less" scoped>
    .van-card__bottom {
        padding-bottom: 20px;
    }

    .van-doc-demo-block__title {
        margin: 0;
        padding: 7px 14px 0px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 12px;
    }
</style>
