<template>
  <div>
    <div>
      <div class="InfoCard">
        <div class="InfoCard-paperClip"></div>
        <div class="InfoCard-container">
          <div class="InfoCard-base-box">
            <div class="InfoCard-avatar-box">
              <div class="MedicalAvatar" style="height: 90px;">
                <img resizemode="cover"
                     :src="this.$getFileAccessHttpUrl(doctorInfo.avatar)"
                     :title="doctorInfo.name" :alt="doctorInfo.name" width="100%"
                     height="90"></div>
            </div>
            <div class="InfoCard-info-box">
              <div class="InfoCard-info-nameTitle"><span
                  class="InfoCard-info-name">{{ doctorInfo.name }}</span><span
                  class="InfoCard-info-title">{{ this.$sysDictFilter(doctorInfo.postionFlag, 'postionFlag') }}</span>
              </div>
              <div class="guanzhu">
                <div class="Footer-common-box2" style="margin-top: 0px;" v-if="followFlag==1"
                     @click="toFollowFlag(0)">
                  <img class="img2" src="/img/guanzhu1.png">
                  <div data-spm-anchor-id="a2oe4.11666344.0.i0">已关注</div>
                </div>
                <div class="Footer-common-box" style="margin-top: 0px;" v-if="followFlag==0"
                     @click="toFollowFlag(1)">
                  <img class="img1" src="/img/guanzhu.png">
                  <div>关注</div>
                </div>
              </div>
              <div class="InfoCard-info-hospital">{{ hospital.name }}
                {{ this.$sysDictFilter(doctorInfo.hospDepartFlag, 'hospDepartFlag') }}
              </div>
            </div>
            <div class="InfoCard-label-box">
                            <span class="InfoCard-label-unit"
                                  v-if="hospital.a3Flag!=0&&kyz.$sysDictFilter(hospital.a3Flag, 'a3Flag')">
                                <span class="InfoCard-label-unitDot" style="background-color: rgb(238, 136, 0);"></span>
                                <span
                                    class="InfoCard-label-unitText">{{ kyz.$sysDictFilter(hospital.a3Flag, 'a3Flag') }}医院</span>
                            </span>
              <span class="InfoCard-label-unit">
                                <span class="InfoCard-label-unitDot" style="background-color: rgb(0, 180, 164);"></span>
                                <span class="InfoCard-label-unitText">优质服务</span>
                            </span>
            </div>
            <div class="InfoCard-comment-box">
              <div>
                <van-icon name="hot" color="red"/>
                <span
                    class="InfoCard-comment-text">{{ doctorInfo.diagnosisCount == undefined ? 0 : doctorInfo.diagnosisCount }}条问诊,</span>
                <span
                    class="InfoCard-comment-text">好评率:{{ ((doctorInfo.commitSourceTotal / doctorInfo.commitCount / 10).toFixed(2) * 100 || '0') + '%' }}</span>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="ServiceList-box ui-mod" style="width: 95%">
        <!-- link 模式，在右侧显示链接箭头 -->
        <van-notice-bar mode="link" @click="$router.push({name: 'patient/vip'});">
          <van-image src="/img/wan_vip.png" width="15px"/>
          会员咨询8.8折，追问延长24小时
        </van-notice-bar>
        <div class="ServiceList-content">
          <div class="ServiceList-item" v-if="doctorInfo.textPrice!=0&&doctorInfo.textFlag!=0">
            <div class="ServiceList-item-main">
              <div class="ServiceList-item-icon">
                <img class="ServiceList-itemico" src="/img/zixun.png">
              </div>
              <div class="ServiceList-item-body">
                <div class="ServiceList-item-primary">
                  <div class="ServiceList-itemName">图文咨询</div>
                  <div class="ServiceList-item-price">{{ parseFloat(doctorInfo.price.toFixed(2)) }}元/次
                  </div>
                </div>
                <div class="ServiceList-item-second">
                  <div class="ServiceList-item-hint" v-if="vipFlag=1">
                    {{ parseInt(this.$sysDictFilter(5, 'chatRule')) / 60 }}小时内图文{{ this.$sysDictFilter(0, 'chatRule') }}次沟通
                  </div>
                  <div class="ServiceList-item-hint" v-if="vipFlag=0">
                    {{ parseInt(this.$sysDictFilter(1, 'chatRule')) / 60 }}小时内图文{{ this.$sysDictFilter(0, 'chatRule') }}次沟通
                  </div>
                  <div class="Popover-box"></div>
                </div>
              </div>
            </div>
            <div class="ServiceList-item-action ServiceList-action-typeB" @click="zixun(0)">立即咨询</div>
          </div>
          <div class="ServiceList-item" v-if="doctorInfo.textPrice==0||doctorInfo.textFlag==0">
            <div class="ServiceList-item-main">
              <div class="ServiceList-item-icon">
                <img class="ServiceList-itemico" src="/img/zixun.png"
                     style="-webkit-filter: grayscale(100%); filter: grayscale(100%);">
              </div>
              <div class="ServiceList-item-body">
                <div class="ServiceList-item-primary">
                  <div class="ServiceList-itemName">图文咨询</div>
                  <div class="ServiceList-item-price">
                  </div>
                </div>
                <div class="ServiceList-item-second">
                  <div class="ServiceList-item-hint" v-if="vipFlag=1">
                    {{ parseInt(this.$sysDictFilter(5, 'chatRule')) / 60 }}小时内图文{{ this.$sysDictFilter(0, 'chatRule') }}次沟通
                  </div>
                  <div class="ServiceList-item-hint" v-if="vipFlag=0">
                    {{ parseInt(this.$sysDictFilter(1, 'chatRule')) / 60 }}小时内图文{{ this.$sysDictFilter(0, 'chatRule') }}次沟通
                  </div>
                  <div class="Popover-box"></div>
                </div>
              </div>
            </div>
            <div class="ServiceList-item-action" style="background-color: gray;color: #fff;">暂未开通</div>
          </div>
          <div class="ServiceList-item" v-if="doctorInfo.groupBuyFlag==1">
            <div class="ServiceList-item-main">
              <div class="ServiceList-item-icon">
                <img class="ServiceList-itemico" src="/img/taocan.png">
              </div>
              <div class="ServiceList-item-body">
                <div class="ServiceList-item-primary">
                  <div class="ServiceList-itemName">包年咨询套餐</div>
                  <div class="ServiceList-item-price">
                    {{ parseFloat(doctorInfo.groupPrice.toFixed(2)) }}元/{{ doctorInfo.groupCount }}次
                  </div>
                </div>
                <div class="ServiceList-item-second">
                  <div class="ServiceList-item-hint">支付一次问多次，更实惠</div>
                  <div class="Popover-box"></div>
                </div>
              </div>
            </div>
            <div class="ServiceList-item-action ServiceList-action-typeB" @click="zixun(1)" v-if="mealFlag==0">立即咨询
            </div>
            <div class="ServiceList-item-action" style="background-color: gray;color: #fff;" v-if="mealFlag==1">已购买
            </div>
          </div>
          <div class="ServiceList-item" v-if="doctorInfo.callPrice!=0&&doctorInfo.callFlag!=0">
            <div class="ServiceList-item-main">
              <div class="ServiceList-item-icon">
                <img class="ServiceList-itemico" src="/img/dianhua.png">
              </div>
              <div class="ServiceList-item-body">
                <div class="ServiceList-item-primary">
                  <div class="ServiceList-itemName">电话咨询</div>
                  <div class="ServiceList-item-price">
                    {{ parseFloat(doctorInfo.callPrice.toFixed(2)) }}元/次
                  </div>
                </div>
                <div class="ServiceList-item-second">
                  <div class="ServiceList-item-hint">
                    {{ this.$sysDictFilter(6, 'chatRule') }}分钟电话沟通
                  </div>
                  <div class="Popover-box"></div>
                </div>
              </div>
            </div>
            <div class="ServiceList-item-action ServiceList-action-typeB" @click="zixun(2)">立即咨询</div>
          </div>
          <div class="ServiceList-item" v-if="doctorInfo.callPrice==0||doctorInfo.callFlag==0">
            <div class="ServiceList-item-main">
              <div class="ServiceList-item-icon">
                <img class="ServiceList-itemico" src="/img/dianhua_gray.png">
              </div>
              <div class="ServiceList-item-body">
                <div class="ServiceList-item-primary">
                  <div class="ServiceList-itemName">电话咨询</div>
                </div>
                <div class="ServiceList-item-second">
                  <div class="ServiceList-item-hint">
                    {{ this.$sysDictFilter(6, 'chatRule') }}分钟电话沟通
                  </div>
                  <div class="Popover-box"></div>
                </div>
              </div>
            </div>
            <div class="ServiceList-item-action" style="background-color: gray;color: #fff;">暂未开通</div>
          </div>
          <div class="ServiceList-item" v-if="doctorInfo.waterPrice!=0&&doctorInfo.waterFlag!=0">
            <div class="ServiceList-item-main">
              <div class="ServiceList-item-icon">
                <img class="ServiceList-itemico" src="/img/yuyuejiancha.png">
              </div>
              <div class="ServiceList-item-body">
                <div class="ServiceList-item-primary">
                  <div class="ServiceList-itemName">线下预约</div>
                  <div class="ServiceList-item-price">
                    {{ (doctorInfo.waterPrice) }}元/次
                  </div>
                </div>
                <div class="ServiceList-item-second">
                  <div class="ServiceList-item-hint">
                   {{doctorInfo.waterRemark}}
                  </div>
                  <div class="Popover-box"></div>
                </div>
              </div>
            </div>
            <div class="ServiceList-item-action ServiceList-action-typeB" @click="zixun(5)">立即预约</div>
          </div>
        </div>
      </div>

      <!--            <div class="ServiceList-box ui-mod">-->
      <!--                <div class="ServiceList-content" style="width: 100%;">-->
      <!--                    <div class="ServiceList-item" style="width: calc(50vw);">-->
      <!--                        <img class="ServiceList-itemico"-->
      <!--                             src="/img/zixun.png">-->
      <!--                        <div class="ServiceList-itemName">图文咨询</div>-->
      <!--                        <div class="ServiceList-itemPrice">{{doctorInfo.price}}元/次</div>-->
      <!--                    </div>-->

      <!--                    <div class="ServiceList-item" style="width: calc(50vw);" v-if="doctorInfo.groupBuyFlag==1">-->
      <!--                        <img class="ServiceList-itemico"-->
      <!--                             src="/img/taocan.png">-->
      <!--                        <div class="ServiceList-itemName">咨询套餐</div>-->
      <!--                        <div class="ServiceList-itemPrice">{{doctorInfo.groupPrice}}元/{{doctorInfo.groupCount}}次</div>-->
      <!--                    </div>-->

      <!--                    <div class="ServiceList-item" style="width: calc(50vw);">-->
      <!--                        <img class="ServiceList-itemico"-->
      <!--                             src="/img/guahao.png">-->
      <!--                        <div class="ServiceList-itemName">预约挂号</div>-->
      <!--                        <div class="ServiceList-itemPrice">暂未开通</div>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </div>-->

      <div class="Expert ui-mod">
        <div class="CommonTitle-titleCont"><span class="CommonTitle-titleText">专业擅长</span></div>
        <div class="Expert-content" data-expanded="true" ref="jo">
          <div class="Expert-info">
            <p>
              <span class="Expert-tag">专长</span>
              {{ doctorInfo.goodAt }}
            </p>
          </div>
          <div class="Expert-info "
               :class="(doctorInfo.remark!== undefined && doctorInfo.remark!== null&&doctorInfo.remark.length>100&&!expandFlag)?'Expert-desc':''">
            <div class="Expert-info_content">
              <span class="Expert-tag">简介</span>
              {{ doctorInfo.remark }}
            </div>
          </div>
          <div class="Expert-collapse">
                        <span class="Expert-arrow Expert-arrow__down">
                          <van-icon name="arrow-down"
                                    v-if="doctorInfo.remark!== undefined && doctorInfo.remark!== null&&doctorInfo.remark.length>100&&!expandFlag"
                                    @click="expandFlag=!expandFlag"/>
                          <van-icon name="arrow-up"
                                    v-if="doctorInfo.remark!== undefined && doctorInfo.remark!== null&&doctorInfo.remark.length>100&&expandFlag"
                                    @click="expandFlag=!expandFlag"/>
                        </span>
          </div>
        </div>
      </div>
      <div class="PatientReview-box ui-mod">
        <div class="CommonTitle-titleCont"><span class="CommonTitle-titleText">患者评论</span></div>
        <div class="custom-image van-empty" v-if="commentList.length==0"><p class="van-empty__description">
          暂无患者评价</p></div>
        <div class="PatientReview-content" v-for="item in commentList" :key="item.id">
          <div class="PatientReview-topContent">
            <div class="PatientReview-topContentName">{{ kyz.$parseStringToStar(item.patientName) }}</div>
            <div class="PatientReview-topContentStar">
              <div class="Medical-Star">
                <van-rate v-model="item.star" allow-half void-icon="star" void-color="#eee" readonly/>
              </div>
            </div>
            <div class="PatientReview-topContentTime">{{ item.createTime|substrs }}</div>
          </div>
          <div class="PatientReview-labelItem">
            <span class="PatientReview-label">{{ item.content }}</span>
          </div>
        </div>
        <div class="SeeMore-content" v-if="commentList.length!=0" @click="toCommitList()"><span
            class="SeeMore-title">更多</span>
          <van-icon name="arrow"/>
        </div>
      </div>
    </div>
    <br><br><br>

    <!--        <van-goods-action>-->
    <!--            <van-goods-action-icon icon="chat-o" text="客服"/>-->
    <!--            <van-goods-action-icon icon="shop-o" text="首页"/>-->
    <!--            <van-goods-action-icon icon="star" text="已关注" color="#ff5000"/>-->
    <!--            &lt;!&ndash;            <van-goods-action-button color="#be99ff" type="warning" text="加入购物车" />&ndash;&gt;-->
    <!--            <van-goods-action-button color="#00caab" type="danger" text="立即咨询"/>-->
    <!--        </van-goods-action>-->
    <Footer :active="active"></Footer>
  </div>
</template>

<script>
import {
  Cell,
  CellGroup,
  Tabbar,
  TabbarItem,
  DropdownMenu,
  DropdownItem,
  Button,
  List,
  Tag,
  Rate,
  GoodsAction, GoodsActionIcon, GoodsActionButton
} from 'vant';
import {NoticeBar} from 'vant';
import {postAction} from '@/api/manage';
import Footer from '@/view/patient/index/footer'
import wx from 'weixin-js-sdk'

export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Button.name]: Button,
    [List.name]: List,
    [Tag.name]: Tag,
    [GoodsAction.name]: GoodsAction,
    [GoodsActionIcon.name]: GoodsActionIcon,
    [GoodsActionButton.name]: GoodsActionButton,
    [Rate.name]: Rate, [NoticeBar.name]: NoticeBar, Footer
  },

  data() {
    return {
      active: 9,
      doctorInfo: {},
      followFlag: 0,
      hospital: {},
      commentList: [],
      loading: false,
      finished: false,
      doctorId: this.$route.query.doctorId,
      kyz: this,
      expandFlag: false,
      mealFlag: 0
    };
  },
  mounted() {
    this.getDoctorInfo();
    this.doctorCommentList();

  },
  methods: {
    getDoctorInfo() {
      console.log(this.doctorId)
      var that = this;
      postAction('/web/api/index/doctorInfo', {
        "doctorId": this.doctorId
      }).then(function (response) {
        console.log(response.result);
        that.doctorInfo = response.result.doctor;
        that.followFlag = response.result.followFlag;
        that.hospital = response.result.hospital;
        that.mealFlag = response.result.mealFlag

        // 微信分享配置
        wx.ready(function () {
          wx.updateTimelineShareData({
            title: that.doctorInfo.name + '[' + that.hospital.name + ']', // 分享标题
            link: 'http://wap.wenchengjiankang.com/doctorInfo?doctorId=' + that.doctorInfo.id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: that.$getFileAccessHttpUrl(that.doctorInfo.avatar), // 分享图标
            success: function () {
              // 设置成功
            }
          })
          wx.updateAppMessageShareData({
            title: that.doctorInfo.name + '[' + that.hospital.name + ']', // 分享标题
            link: 'http://wap.wenchengjiankang.com/doctorInfo?doctorId=' + that.doctorInfo.id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: that.$getFileAccessHttpUrl(that.doctorInfo.avatar), // 分享图标
            desc: that.doctorInfo.shareContent, // 分享描述
            success: function () {
              // 设置成功
            }
          })
        })
      })
    },
    doctorCommentList() {
      var that = this;
      postAction('/web/api/index/doctorCommentList', {
        "doctorId": this.doctorId, "pageNo": 1, "pageSize": 3
      }).then(function (response) {
        console.log(response.result);
        that.commentList = response.result.data.records;
      })
    },
    toFollowFlag(type) {
      console.log(type)
      var that = this;
      postAction('/web/api/patient/toFollowFlag', {
        "doctorId": this.doctorId, "type": type
      }).then(function (response) {
        console.log(response.result);
        that.followFlag = response.result.data;
      })
    },
    zixun(type) {
      var that = this;
      postAction('/web/api/patient/toFollowFlagCheck', {
        "doctorId": this.doctorId, "type": type
      }).then(function (response) {
        console.log(response.result);
        that.$router.push({name: 'patient/toOrderStep1', query: {type: type, doctorId: that.doctorId}});
      })
    },
    toCommitList() {
      this.$router.push({name: 'doctorCommitList', query: {doctorId: this.doctorId}});
    }
  }
};
</script>

<style lang="less" scoped>
@import '/css/doctorInfo.css';

.header::-webkit-scrollbar {
  display: none;
}

.van-goods-action-button--last {
  margin-right: 5px;
  border-radius: 999px 999px 999px 999px;
}

.custom-image {
  margin-top: -30px;
}


</style>
