<template>
    <van-tabbar v-model="actives" active-color="#07c160">
        <van-tabbar-item icon="home-o" @click="goHref(0)">首页</van-tabbar-item>
        <van-tabbar-item icon="like-o" @click="goHref(1)">我的医生</van-tabbar-item>
        <van-tabbar-item icon="chat-o" @click="goHref(2)">问医记录</van-tabbar-item>
        <van-tabbar-item icon="notes-o" @click="goHref(4)">健康学院</van-tabbar-item>
        <van-tabbar-item icon="user-o" @click="goHref(3)">我的</van-tabbar-item>
    </van-tabbar>
</template>

<script>
    import {Tabbar, TabbarItem} from 'vant';
    export default {
        name: 'Footer',
        props: {
            active: {
                type: Number,
                default: 0
            }
        },
        components: {
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem
        },

        data() {
            return {
                actives: this.active
            };
        },
        methods: {
            goHref(type) {
                if (type == 0) {
                    this.$router.push({name: 'patient/index'});
                } else if (type == 1) {
                    this.$router.push({name: 'patient/myDoctor'});
                } else if (type == 2) {
                    this.$router.push({name: 'patient/doctorRecord'});
                } else if (type == 3) {
                    this.$router.push({name: 'patient/userCenter'});
                }else if (type == 4) {
                    this.$router.push({name: 'patient/videoIndex'});
                }
            }
        }


    };
</script>

<style lang="less">
</style>
