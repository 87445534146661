<template>
    <div>
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text=""
                @load="onLoad"
        >
            <van-card
                    v-for="item in list" :key="item.id"
                    @click="goInfo(item.id)"
            >
                <template #desc>
                    <div class="descc"><span class="bgray">昵称:</span>{{jiatings(item.name)}}</div>
                    <div class="descc"><span class="bgray">年龄:</span>{{rens(item.birthday)}}</div>
                    <div class="descc"><span class="bgray">性别:</span>{{ wx.$sysDictFilter(item.sex, 'sex')}}</div>
                </template>
                <template #num>
                    <van-icon name="edit" size="20" style="top: -60px;margin-right: 20px"/>
                </template>
            </van-card>

            <van-empty description="" v-if="!list"/>
        </van-list>
        <div style="margin: 0px 10px 0px 10px" v-if="list.length<3">
            <van-button type="primary" size="large" @click="toAdd" round block
                        style="bottom: 0;background-color: #07c160">添加家庭成员
            </van-button>
        </div>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        Button, Field,
        List, Form, SubmitBar,
        Tag, Step, Card
    } from 'vant';
    import {Empty} from 'vant';
    import {postAction} from '@/api/manage';

    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tag.name]: Tag,
            [Step.name]: Step,
            [Field.name]: Field,
            [Form.name]: Form,
            [Empty.name]: Empty,
            [SubmitBar.name]: SubmitBar, [Card.name]: Card,
        },

        data() {
            return {
                active: 0,
                list: [],
                loading: false,
                finished: false,
                pageNumber: 1,
                wx: this
            };
        },
        methods: {
            onLoad() {
                this.loadData();
            },
            loadData() {
                let that = this;
                //发送 post 请求
                this.$nextTick(() => {
                    postAction('/web/api/patient/user/patientUserList', {
                        "pageNo": that.pageNumber
                    }).then(function (response) {
                        console.log(response.result);
                        // 异步更新数据
                        for (let i = 0; i < 10; i++) {
                            if (response.result.data.records[i]) {
                                that.list.push(response.result.data.records[i]);
                            }
                        }
                        that.pageNumber = that.pageNumber + 1;
                        // 加载状态结束
                        that.loading = false;
                        // 数据全部加载完成
                        if (that.list.length >= response.result.data.total) {
                            that.finished = true;
                        }
                    })
                })
            },
            jiatings(t) {
                return t;
            },
            goInfo(id) {
                this.$router.push({name: 'patient/myPatientUserInfo', query: {id: id}});
            },
            toAdd() {
                this.$router.push({name: 'patient/myPatientUserInfo'});
            },
            rens(birthday) {
                return this.$sysGetAge2(birthday.substring(0, 10));
            }
        }
    };
</script>

<style lang="less" scoped>
    .van-card__bottom {
        padding-bottom: 20px;
    }

    .van-card__title {
        font-size: 12pt;
    }

    .van-card__price {
        font-size: 13pt !important;
    }

    .van-card__price-integer {
        font-size: 13pt !important;
    }

    .van-card__header {
        height: 105px;
    }

    .bgray {
        color: #7d7c7c;
    }

    .descc {
        font-size: 12pt;
        padding-top: 10px;
    }
</style>
