<template>
  <div>
    <div class="bg" style="text-align: center">
      <van-image class="aui-shop-logo" round
                 :src="this.$getFileAccessHttpUrl(userInfo.avatar)" width="75px"/>
      <div class="aui-shop-logo-txt" v-cloak>
        {{ userInfo.name }}
      </div>
    </div>
    <van-tabs v-model="active">
      <van-tab title="咨询列表">
        <van-dropdown-menu>
          <van-dropdown-item v-model="value1" :options="option1" @change="initData"/>
        </van-dropdown-menu>
        <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
        >
          <van-empty description="" v-if="this.list.length==0"/>
          <div style="margin: 10px 5px 10px 5px;border:1px solid #f3f2f2;border-radius: 5px;background-color: white"
               v-for="item in list"
               :key="item.doctorId"
               @click="goInfo(item.id)">
            <div style="margin: 10px 10px 0px 10px;">
              <van-row>
                <van-col span="3">
                  <van-image
                      radius="5"
                      width="40px"
                      height="40px"
                      :src="kyz.$getFileAccessHttpUrl(item.askAvatar)"
                  />
                </van-col>
                <van-col span="21" style="font-size: 12pt;color:#6d6c6c">
                  <div>{{ item.hospName }}
                    <span v-if="item.hospitalName">{{ item.hospitalName }}</span>
                    <span v-else>{{ item.askName }}</span></div>
                  <div style="color: gray;font-size: 9pt">{{ item.createTime.substring(0, 10) }} 发布了问题</div>
                </van-col>
              </van-row>
            </div>
            <div style="margin: 5px 10px 5px 10px;font-weight: bold;font-size: 14pt">
              {{ item.content }}
            </div>
            <div style="margin: 5px 10px 5px 10px;font-weight: bold;font-size: 14pt;color: #4f998a"
                 v-if="item.detailList">
              {{ item.detailList.title }}
            </div>
            <div style="margin: 5px 10px 5px 10px;font-size: 11pt;color: gray" v-if="item.detailList">
              {{ item.detailList.content }}
            </div>
            <van-divider style="margin: 10px 10px 10px 10px"/>
            <div style="margin: 12px 10px 10px 10px;color:#a2a1a1;">
              <van-row>
                <van-col span="5">
                  <div style="font-size: 12pt;text-align: left">{{ item.readCount == 0 ? '1' : item.readCount }}看过</div>
                </van-col>
                <!--            <van-col span="14">-->
                <!--              <div style="font-size: 14pt;text-align: right;margin-right: 1px">-->
                <!--                <van-icon name="good-job-o"/>-->
                <!--                <div style="font-size: 12pt;margin-left: 5px;float: right"> 10</div>-->
                <!--              </div>-->
                <!--            </van-col>-->
                <van-col span="19">
                  <div style="font-size: 14pt;text-align: right;margin-right: 1px">
                    <van-icon name="chat-o"/>
                    <div style="font-size: 12pt;margin-left: 5px;float: right"> {{ item.msgCount }}</div>
                  </div>
                </van-col>
              </van-row>
            </div>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="我的客户" v-if="doctorType>=0">
        <van-list
            v-model="loadingPatient"
            :finished="finishedPatient"
            finished-text="没有更多了"
            @load="onLoadPatient"
        >
          <div v-for="item in listPatient"
               :key="item.id">
            <div class="van-doc-demo-block__title">
              <van-row>
                <van-col span="24">
                  <div style="text-align: right;">添加时间：{{ item.createTime }}</div>
                </van-col>
              </van-row>
            </div>
            <van-card
                :title="'微信昵称：'+item.patientName"
                :thumb="kyz.$getFileAccessHttpUrl(item.avatar)"
            >
              <template #desc>
                真实姓名：{{ item.hospitalName }}<br>
                所在会所：{{ item.hospName }}
              </template>
              <template #footer>
                <van-button size="small" style="margin-top: -20px"
                            @click.stop="updateNames(item.patientId,item.hospitalName)">修改信息
                </van-button>
                <van-button size="small" style="margin-top: -20px"
                            @click.stop="updateChuYuan(item.patientId,item.patientName)">出院
                </van-button>
              </template>
            </van-card>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>

    <van-tabbar v-model="active" active-color="#2a9f93">
      <van-tabbar-item icon="home-o" @click="goIndex()">推荐</van-tabbar-item>
      <van-tabbar-item @click="goAdd()">
        <template v-slot:icon>
          <div
              style="background-color: #2a9f93;width: 40px;height:35px;text-align: center;line-height: 35px;border-radius: 5px">
            <span style="color:#ffffff;">
            问
          </span>
          </div>
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="user-o">我的</van-tabbar-item>
    </van-tabbar>

    <van-dialog v-model="show" title="修改姓名" show-cancel-button @confirm="dOk">
      <van-field v-model="userName" placeholder="请输入用户名" style="margin-top: 20px;margin-bottom: 20px"/>
    </van-dialog>
  </div>
</template>

<script>

import {
  Cell,
  CellGroup,
  Field,
  Dialog,
  Button,
  Search,
  Divider,
  Tabbar,
  TabbarItem,
  List,
  Tab,
  Tabs,
  Card,
  DropdownMenu,
  DropdownItem
} from 'vant';
import {postAction} from '@/api/manage';
import {Toast} from 'vant';

export default {
  components: {
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [List.name]: List,
    [Dialog.name]: Dialog,
    [Search.name]: Search,
    [Divider.name]: Divider,
    [Card.name]: Card,
    [Dialog.Component.name]: Dialog.Component,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [CellGroup.name]: CellGroup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  data() {
    return {
      active: 2,
      userInfo: {},
      value1: -1,
      option1: [
        {text: '全部消息', value: -1},
        {text: '已回复', value: 1},
        {text: '未回复', value: 0},
      ],
      list: [],
      kyz: this,
      searchName: '',
      doctorType: -1,
      tmpPatientId: 0,
      pageNumber: 1,
      loading: false,
      finished: false,
      show: false,
      userName: '',
      listPatient: [],
      pageNumberPatient: 1,
      loadingPatient: false,
      finishedPatient: false,
    };
  },
  mounted() {
  },
  methods: {
    goIndex(msgId) {
      this.$router.push({name: 'xingqiu/index'});
    },
    goAdd() {
      this.$router.push({name: 'xingqiu/addQuestion'});
    },
    goInfo(msgId) {
      this.$router.push({name: 'xingqiu/questionInfo', query: {msgId: msgId}});
    },
    onLoad() {
      this.loadUserData();
    },
    initData() {
      // eslint-disable-next-line no-debugger
      debugger
      let that = this;
      that.pageNumber = 1;
      that.loading = false
      that.finished = false
      that.list = []
      that.loadData();
    },
    dOk() {
      if (this.userName === "") {
        Toast('请输入姓名')
        return false;
      }
      let that = this;

      postAction('/web/api/xingqiu/updatePatientName', {
        nickName: this.userName,
        patientId: this.tmpPatientId
      }).then(function (response) {
        console.log(response.result);
        Toast('修改成功')
        that.listPatient.forEach(item => {
          // eslint-disable-next-line no-debugger
          debugger
          if (item.patientId === that.tmpPatientId) {
            item.hospitalName = that.userName;
          }
        })
      })
    },
    updateNames(patId, patName) {
      this.show = true
      this.tmpPatientId = patId;
      this.userName = patName
    },
    updateChuYuan(patId, patName) {
      let that = this;
      Dialog.confirm({
        message: '确定要将' + patName + '出院吗？',
      })
          .then(() => {
            postAction('/web/api/xingqiu/updatePatientHopsitalId', {
              patientId: this.tmpPatientId,
              hospitalId: 0
            }).then(function (response) {
              console.log(response.result);
              Toast('出院成功')
              that.listPatient = [];
              that.pageNumberPatient = 1
              that.loadingPatient = false
              that.finishedPatient = false
              that.loadUserData();
            })
          })
          .catch(() => {
          });
    },
    loadUserData() {
      let that = this;
      //发送 post 请求
      this.$nextTick(() => {
        postAction('/web/api/xingqiu/getUserInfo', {}).then(function (response) {
          console.log(response.result);
          that.doctorType = response.result.doctorType;
          that.userInfo = response.result.userInfo;
          that.loadData();
        })
      })
    },
    onLoadPatient() {
      let that = this;
      //发送 post 请求
      this.$nextTick(() => {
        postAction('/web/api/xingqiu/myPatientList', {
          "pageNo": that.pageNumberPatient,
        }).then(function (response) {
          console.log(response.result);
          // 异步更新数据
          for (let i = 0; i < 10; i++) {
            if (response.result.data.records[i]) {
              that.listPatient.push(response.result.data.records[i]);
            }
          }
          that.pageNumberPatient = that.pageNumberPatient + 1;
          // 加载状态结束
          that.loadingPatient = false;
          // 数据全部加载完成
          if (that.listPatient.length >= response.result.data.total) {
            that.finishedPatient = true;
          }
        })
      })
    },
    loadData() {
      let that = this;
      //发送 post 请求
      this.$nextTick(() => {
        var url = "";
        if (that.doctorType < 0) {
          url = '/web/api/xingqiu/messagePatientList'
        } else {
          url = '/web/api/xingqiu/myMessageList'
        }
        postAction(url, {
          "pageNo": that.pageNumber, "searchName": this.searchName, "myFlag": 1, readFlag: this.value1
        }).then(function (response) {
          console.log(response.result);
          // 异步更新数据
          for (let i = 0; i < 10; i++) {
            if (response.result.data.records[i]) {
              that.list.push(response.result.data.records[i]);
            }
          }
          that.pageNumber = that.pageNumber + 1;
          // 加载状态结束
          that.loading = false;
          // 数据全部加载完成
          if (that.list.length >= response.result.data.total) {
            that.finished = true;
          }
        })
      })
    },
  }
};
</script>

<style lang="less" scoped>
.van-doc-demo-block__title {
  margin: 0;
  padding: 7px 14px 0px;
  color: rgba(69, 90, 100, 0.6);
  font-weight: normal;
  font-size: 12px;
  background-color: white;
}

.van-card__footer {
  margin-top: -20px;
}

.van-card__desc {
  margin-top: 10px;
}
.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}

.bg {
  background: linear-gradient(#54b279, #6bc193);
  height: 160px;
}

.aui-shop-logo {
  z-index: 99999;
  top: 35px;
}

.aui-shop-logo-txt {
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 99999;
  top: 126px;
  color: white;
}

.wHide {
  /*display: none;*/
}

.van-info {
  right: 46px;
}

.wShow {
  display: inline-block !important;
}
</style>
