<template>
    <div>
        <van-search v-model="searchInfo" placeholder="搜索课程" @search="onSearch"/>
        <van-swipe :autoplay="3000">
            <van-swipe-item v-for="(item, index) in images" :key="index" @click="goInfo(item.id)">
                <img v-lazy="kyz.$getFileAccessHttpUrl(item.pic)" style="width: 100%"/>
            </van-swipe-item>
        </van-swipe>
        <van-grid :column-num="4" :border="false">
            <van-grid-item @click="gridToggle(1)" :class="catalogId==1?'fumu':''">
                <van-image src="/img/parent.png" width="40px"/>
                <span style="font-size: 10pt;margin-top: 5px">父母必修课</span>
            </van-grid-item>
            <van-grid-item @click="gridToggle(2)" :class="catalogId==2?'erke':''">
                <van-image src="/img/erke.png" width="40px"/>
                <span style="font-size: 10pt;margin-top: 5px">儿童健康</span>
            </van-grid-item>
            <van-grid-item @click="gridToggle(3)" :class="catalogId==3?'xinli':''">
                <van-image src="/img/woman.png" width="40px"/>
                <span style="font-size: 10pt;margin-top: 5px">女性健康</span>
            </van-grid-item>
            <van-grid-item @click="gridToggle(4)" :class="catalogId==4?'xinli':''">
                <van-image src="/img/xinli.png" width="40px"/>
                <span style="font-size: 10pt;margin-top: 5px">儿童心理行为</span>
            </van-grid-item>
        </van-grid>
        <div>
            <div class="showcase-knowledge-goods">
                <div class="knowledgeGoods">
                    <van-list
                            v-model="loading"
                            :finished="finished"
                            finished-text="没有更多了"
                            @load="onLoad"
                    >
                        <div class="knowledgeGoods__wrap knowledgeGoods__wrap--listMode0" style="margin: 6px"
                             v-for="item in list"
                             :key="item.id"
                             @click="goInfo(item.id)">
                            <div class="columnItem columnItem--size0 columnItem--goodsStyle2 columnItem--goodsRadius0 columnItem--imgSize0 columnItem--textStyle0 columnItem--textAlign0 columnItem--displayLabel columnItem--stageCount columnItem--displayPrice columnItem--displayCount">
                                <div class="columnItem__img">
                                    <div class="imgWrap" style="width: 100%; height: 100%;">
                                        <img v-lazy="kyz.$getFileAccessHttpUrl(item.pic)">
                                    </div>
                                    <div class="columnItem__infoTag">
                                        <div class="columnItem__before">已更新{{item.fileCount}}期</div>
                                        <div class="columnItem__count">{{item.buyCount}}人已购</div>
                                    </div>
                                </div>
                                <div class="columnItem__info">
                                    <div class="columnItem__mainInfo">
                                        <div class="columnItem__label"><span>{{item.title}}</span></div>
                                    </div>
                                    <div class="columnItem__other">
                                        <div class="columnItem__price"><i class="price__icon">¥</i>
                                            <span class="price__num">{{item.amount}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <van-empty description="" v-if="this.list.length==0"/>
                    </van-list>
                </div>
            </div>
        </div>
        <br><br>
        <Footer :active="active"></Footer>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        Search,
        DropdownMenu,
        DropdownItem,
        Button,
        List,
        Tag,
        Grid,
        GridItem, Swipe, SwipeItem,
        Image
    } from 'vant';

    import Footer from '@/view/patient/index/footer'
    import {postAction} from '@/api/manage';
    import wx from 'weixin-js-sdk'

    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            Footer,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tag.name]: Tag,
            [Grid.name]: Grid,
            [GridItem.name]: GridItem,
            [Image.name]: Image, [Swipe.name]: Swipe, [SwipeItem.name]: SwipeItem,
            [Search.name]: Search
        },

        data() {
            return {
                images: [],
                active: 3,
                searchInfo: '',
                list: [],
                pageNumber: 1,
                loading: false,
                finished: false,
                kyz: this,
                catalogId: ''
            };
        },
        mounted() {
            this.getSwipe()
            // 微信分享配置
            wx.ready(function () {
                wx.updateTimelineShareData({
                    title: '闻诚健康学院', // 分享标题
                    link: 'http://wap.wenchengjiankang.com/patient/videoIndex', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: 'http://wap.wenchengjiankang.com/img/logo.jpg', // 分享图标
                    success: function () {
                        // 设置成功
                    }
                })
                wx.updateAppMessageShareData({
                    title: '闻诚健康学院', // 分享标题
                    link: 'http://wap.wenchengjiankang.com/patient/videoIndex', // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: 'http://wap.wenchengjiankang.com/img/logo.jpg', // 分享图标
                    desc: '', // 分享描述
                    success: function () {
                        // 设置成功
                    }
                })
            })
        },
        methods: {
            onLoad() {
                this.loadData();
            },
            loadData() {
                let that = this;
                //发送 post 请求
                this.$nextTick(() => {
                    postAction('/web/api/index/videoSpecialList', {
                        "pageNo": that.pageNumber, "searchName": this.searchInfo, "catalogId": this.catalogId
                    }).then(function (response) {
                        console.log(response.result);
                        // 异步更新数据
                        for (let i = 0; i < 10; i++) {
                            if (response.result.data.records[i]) {
                                that.list.push(response.result.data.records[i]);
                            }
                        }
                        that.pageNumber = that.pageNumber + 1;
                        // 加载状态结束
                        that.loading = false;
                        // 数据全部加载完成
                        if (that.list.length >= response.result.data.total) {
                            that.finished = true;
                        }
                    })
                })
            },
            getSwipe() {
                let that = this;
                //发送 post 请求
                postAction('/web/api/index/videoSpecialSwipe', {}).then(function (response) {
                    console.log(response.result);
                    // 异步更新数据
                    for (let i = 0; i < response.result.data.length; i++) {
                        if (response.result.data[i]) {
                            // eslint-disable-next-line
                            that.images.push(response.result.data[i]);
                        }
                    }
                })
            },
            onConfirm() {
                this.$refs.item.toggle();
            },
            gridToggle(catalogId) {
                this.catalogId = catalogId
            },
            onSearch() {
                this.list = [];
                this.pageNumber = 1;
                this.loadData();
            },
            goInfo(id) {
                this.$router.push({name: 'patient/videoInfo', query: {id: id}});
            }
        },
        watch: {
            catalogId() {
                this.list = [];
                this.pageNumber = 1;
                this.loadData()
            }
        }
    };
</script>

<style lang="less" scoped>
    @import '/css/video.css';

    .van-grid-item__content span {
        font-size: 8pt !important;
    }
</style>
