<template>
    <div class="container">
        <div class="page-detail">
            <div class="module-pic">
                <div class="container_box" id="audio_detail">
                    <img v-lazy="kyz.$getFileAccessHttpUrl(videoDetail.pic)" class="audio_cover" style="width: 100%"/>
                    <div v-if="hasBuy==0">
                        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
                            当前内容购买专栏后才能查看
                        </van-notice-bar>
                    </div>
                    <div v-if="hasBuy==1">
                        <audio autoplay="autoplay" id="audioPlayer" preload="auto"
                               :src="videoDetail.fileUrl">
                            你的浏览器不支持audio标签
                        </audio>
                        <div class="audioBox">
                            <div class="audioBox_top">
                                <div class="audioBox_left">
                                    {{audioCurrentTime}}
                                </div>
                                <div class="audioBox_slider">
                                    <van-slider v-model="audioTime" @change="onChange" bar-height="4px"/>
                                </div>
                                <div class="audioBox_right">
                                    {{audioAllTime}}
                                </div>
                                <div class="clear"></div>
                            </div>
                            <div class="audioBox_control">
                                <!--播放按钮-->
                                <i class="iconfont icon-bofang1 iconfont_control" @click="playAudio"
                                   v-show="!isPlay"></i>
                                <!--暂停按钮-->
                                <i class="iconfont icon-zanting iconfont_control" @click="pauseAudio"
                                   v-show="isPlay"></i>
                                <!--设置倍速播放-->
                                <div class="audioBox_multiple" @click="changeMultiple" style="display: none">
                                    <div class="multiple_number">{{multipleArray[multipleIndex]}}X</div>
                                    <div class="multiple_des">倍速播放</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="module-main">
                <div class="base-info-block"><!----><!---->
                    <div class="price">
                        <div>
                            <div>
                            <span class="pl-container"
                                  style="color: rgb(255, 68, 68); font-size: 20px; font-weight: 600;">
                                <span class="pl-item">
                                    <span class="pl-price-container ">
                                        <span class="pl-price-symbol">￥</span>
                                        <span class="pl-int-number-1">{{videoSpecial.amount}}</span>
                                    </span>
                                </span>
                            </span>
                            </div>
                            <div class="origin"></div>
                        </div>
                    </div>
                    <div>
                        <div class="title-area">
                            <div>
                                <span class="title">{{videoDetail.title}}</span>
                            </div>
                        </div>
                        <div class="knowledge-info">
                            <table class="table">
                                <tr class="knowledge-info-line">
                                    <td>
                                        <span class="knowledge-info-label">已学</span>
                                    </td>
                                    <td>
                                        <span>{{videoSpecial.playCount}}人</span>
                                    </td>
                                </tr>
                                <tr class="knowledge-info-line">
                                    <td>
                                        <span class="knowledge-info-label">讲师</span>
                                    </td>
                                    <td>
                                        <span>{{videoSpecial.doctorName}}</span>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="column-entry-block" @click="goInfo(videoSpecial.id)">
                    <div class="cover imgWrap" style="width: 80px; height: 46px;">
                        <img v-lazy="kyz.$getFileAccessHttpUrl(videoSpecial.pic)" style="width: 100%"/>
                    </div>
                    <div><p class="title">
                        {{videoSpecial.title}}
                    </p>
                        <div class="desc">
                            <span class="desc-item">已更新{{videoSpecial.fileCount}}期</span>
                            <span class="link">查看专栏
                                <i class="arrow van-icon van-icon-arrow"></i>
                            </span>
                        </div>
                    </div>
                </div>

                <div class="intro-block">
                    <div class="van-cell-group">
                        <div></div>
                        <div>
                            <div class="intro cap-richtext" style="background-color: rgb(255, 255, 255);">
                                <div v-html="videoDetail.remark" v-if="hasBuy==0"></div>
                                <div v-html="videoDetail.buyRemark" v-if="hasBuy==1"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block-exam"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        DropdownMenu,
        DropdownItem,
        Button,
        List, Tab, Tabs,NoticeBar,
        Tag,
        Grid,
        GridItem, Slider,
        Image
    } from 'vant';

    import {postAction} from '@/api/manage';
    import wx from 'weixin-js-sdk'

    let audioInterval;
    const testJson = {
        "code": 0,
        "msg": "success",
        "data": {
            "id": "a_59ba3d1444f58_7yTX18hD",
            "title": "7777",
            "desc": "66666",
            "img_url": "https://img.yzcdn.cn/upload_files/2020/07/20/Fva8E5iOmfbhWgjdAPePDK0sWJP1.jpg!large.jpg",
            "img_url_compressed": "http://wechatapppro-1252524126.file.myqcloud.com/appG1VMUALC2470/image/compress/46cff040af0c42fb3e4ea4198b8e2413.jpg",
            "price": 0,
            "state": 0,
            "comment_count": 421,
            "summary": "summary",
            "audio_length": 2682,
            "start_at": "2017-12-31 22:35:00",
            "created_at": "2017-09-14 16:25:56",
            "is_stop_sell": 0,
            "recycle_bin_state": 0,
            "is_available": 1,
            "audio_url": "http://wechatapppro-1252524126.file.myqcloud.com/appG1VMUALC2470/audio_compressed/1505377565_4fd15d83213bceb23a97ad6af45f1dae.mp3",
            "payment_type": 1,
            "products": [{
                "product_id": "p_5ad844dd39259_dGgElaLz",
                "product_name": "\u65f6\u7a7a\u5fc3\u7075\u5b66\u9662 \u00b7 100\u5802\u7cbe\u534e\u8bfe\u514d\u8d39\u9886"
            }],
            "can_select": 1,
            "view_count": 90994,
            "in_recycle": 0,
            "product_groups": []
        },
        "request_params": {
            "app_id": "appG1VMUALC2470",
            "data": {"resource_id": "a_59ba3d1444f58_7yTX18hD", "user_id": "u_5c2ecc10e1080_ejPPDgjgjO"},
            "use_type": "11"
        }
    }
    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tag.name]: Tag,
            [Grid.name]: Grid,
            [GridItem.name]: GridItem,
            [Image.name]: Image, [Tab.name]: Tab, [Tabs.name]: Tabs,
            [Slider.name]: Slider,[NoticeBar.name]:NoticeBar
        },

        data() {
            return {
                active: 0,
                orderType: 0,
                list: [],
                dataId: this.$route.query.id,
                videoSpecial: {},
                videoDetail: {},
                hasBuy: {},
                kyz: this,
                departId: 0,
                audioDetail: testJson.data,//音频详情
                audioTime: 0,//音频进度百分比
                audioCurrentTime: '00:00',//音频当前播放时间
                audioAllTime: '00:00',//音频总播放时间
                audioAllDuration: 0,//音频总播放秒数
                isPlay: false,//是否正在播放
                multipleArray: [0.75, 1, 1.5, 2],
                multipleIndex: 1,
            };
        },
        mounted() {
            const that=this
            // 微信分享配置
            wx.ready(function () {
                wx.updateTimelineShareData({
                    title: that.videoDetail.title, // 分享标题
                    link: 'http://wap.wenchengjiankang.com/patient/voicePlay?id='+that.videoDetail.id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: that.$getFileAccessHttpUrl(that.videoDetail.pic), // 分享图标
                    success: function () {
                        // 设置成功
                    }
                })
                wx.updateAppMessageShareData({
                    title: that.videoDetail.title, // 分享标题
                    link: 'http://wap.wenchengjiankang.com/patient/voicePlay?id='+that.videoDetail.id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: that.$getFileAccessHttpUrl(that.videoDetail.pic), // 分享图标
                    desc: '', // 分享描述
                    success: function () {
                        // 设置成功
                    }
                })
            })
            this.getVideoInfo()

        },
        methods: {
            getVideoInfo() {
                let that = this;
                //发送 post 请求
                postAction('/web/api/index/videoDataInfo', {
                    "dataId": this.dataId
                }).then(function (response) {
                    console.log(response.result);
                    // 异步更新数据
                    that.videoSpecial = response.result.videoSpecial
                    that.videoDetail = response.result.videoDetail
                    that.hasBuy = response.result.hasBuy
                    document.title = that.videoDetail.title;
                    if(that.hasBuy==1){
                        that.setAudioInterval();
                    }
                })
            },
            goInfo(id) {
                this.$router.push({name: 'patient/videoInfo', query: {id: id}});
            },
            //设置定时检测
            setAudioInterval() {
                audioInterval = setInterval(() => {
                    this.getAudioTime();
                    let audioPlayer = document.getElementById('audioPlayer');
                    if (audioPlayer.ended) {
                        //播放结束后重置数据
                        clearInterval(audioInterval);
                        this.audioTime = 0;
                        audioPlayer.currentTime = 0;
                        this.audioCurrentTime = '00:00';
                        this.isPlay = false;
                    }
                    audioPlayer.paused ? this.isPlay = false : this.isPlay = true
                }, 500)
            },
            //播放
            playAudio() {
                //重设定时器
                clearInterval(audioInterval);
                this.getAudioTime();
                this.setAudioInterval();
                document.getElementById('audioPlayer').play();
                this.isPlay = true;
            },
            //暂停
            pauseAudio() {
                document.getElementById('audioPlayer').pause();
                this.isPlay = false;
            },
            //获取播放时间
            getAudioTime() {
                let audioPlayer = document.getElementById('audioPlayer');
                // console.log("播放总时间--"+realFormatSecond(audioPlayer.duration));
                // console.log("已播放秒数--"+realFormatSecond(audioPlayer.currentTime));
                //展示用
                this.audioAllTime = realFormatSecond(audioPlayer.duration);
                this.audioAllDuration = audioPlayer.duration;
                this.audioCurrentTime = realFormatSecond(audioPlayer.currentTime);
                //计算当前进度百分比
                this.audioTime = parseFloat((audioPlayer.currentTime * 100 / audioPlayer.duration).toFixed(3));
                // console.log("百分比--"+this.audioTime)
            },
            //滑动进度条
            onChange(value) {
                // 设置播放时间
                let audioPlayer = document.getElementById('audioPlayer');
                this.audioCurrentTime = realFormatSecond(this.audioAllDuration * value / 100);
                audioPlayer.currentTime = parseInt(this.audioAllDuration * value / 100)
            },
            //设置倍速播放
            changeMultiple() {
                if (this.multipleIndex < 3) {
                    this.multipleIndex++
                } else {
                    this.multipleIndex = 0
                }
                let audioPlayer = document.getElementById('audioPlayer');
                audioPlayer.playbackRate = this.multipleArray[this.multipleIndex]
            }
        },
        watch: {
            departId() {
                this.list = [];
                this.pageNumber = 1;
                this.loadData()
            }
        }
    };

    //格式化秒
    function realFormatSecond(second) {
        var secondType = typeof second
        if (secondType === 'number' || secondType === 'string') {
            second = parseInt(second)
            // var hours = Math.floor(second / 3600)
            // second = second - hours * 3600
            var mimute = Math.floor(second / 60)
            second = second - mimute * 60
            return ('0' + mimute).slice(-2) + ':' + ('0' + second).slice(-2)
        } else {
            return '00:00'
        }
    }
</script>

<style lang="less" scoped>
    @import '/css/videoPlay.css';
</style>
