<template>
  <div>
    <van-nav-bar
        title="预约检查"
        left-text=""
        right-text="我的预约"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickMyOrderCheckList"
    />
    <div style="margin: 15px 15px 15px 15px ">
      <van-cell icon="friends-o" title="选择家庭成员" is-link @click="choosePatientUser"
                :value="patientUser?patientUser.name:''"/>
      <van-cell title="儿童检查服务" value=""/>
      <van-row>
        <van-col span="2" style="background-color: white; height: 86px;"></van-col>
        <van-col span="22">
          <van-checkbox-group v-model="result">
            <van-cell-group>
              <template v-for="(item, index) in list1">
                <van-cell
                    v-if="item.type===1"
                    clickable
                    :key="item.id"
                    :title="`${item.name}：${item.price}元`"
                    @click="toggle(index)"
                >
                  <template #right-icon>
                    <van-checkbox :name="item" ref="checkboxes"/>
                  </template>
                </van-cell>
              </template>
            </van-cell-group>
          </van-checkbox-group>
        </van-col>
      </van-row>
      <van-cell title="儿童口腔服务" value=""/>
      <van-row>
        <van-col span="2" style="background-color: white; height: 86px;"></van-col>
        <van-col span="22">
          <van-checkbox-group v-model="result">
            <van-cell-group>
              <template v-for="(item, index) in list2">
                <van-cell
                    v-if="item.type===2"
                    clickable
                    :key="item.id"
                    :title="`${item.name}：${item.price}元`"
                    @click="toggle2(index)"
                >
                  <template #right-icon>
                    <van-checkbox :name="item" ref="checkboxes2"/>
                  </template>
                </van-cell>
              </template>
            </van-cell-group>
          </van-checkbox-group>
        </van-col>
      </van-row>
      <van-field
          v-model="address"
          maxlength="200"
          placeholder="地址"
      />
      <van-field
          v-model="message"
          rows="2"
          autosize
          type="textarea"
          maxlength="200"
          placeholder="备注（0-200字）"
          show-word-limit
      />
      <van-cell title="合计" :value="totalPrice" value-class="ttt"/>
      <div style="font-size: 10pt;margin: 10px">
        注：服务时间：8:30-17:30，费用包含挂号费、检验费、取件费用
      </div>
      <van-button type="primary" block style="margin-top: 20px" @click="toAsk" :loading="loading" loading-text="加载中...">
        支付
      </van-button>
    </div>
  </div>
</template>

<script>
import {
  Cell,
  CellGroup,
  Field,
  Dialog,
  Button,
  Search,
  Divider,
  Tabbar,
  TabbarItem,
  NavBar,
  Checkbox,
  CheckboxGroup
} from 'vant';
import {postAction} from '@/api/manage';
import {Toast} from 'vant';
import {PATIENT_USERID} from "@/store/mutation-types";
import Vue from "vue";
import wx from 'weixin-js-sdk'

export default {
  components: {
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [Search.name]: Search, [Divider.name]: Divider,
    [Dialog.Component.name]: Dialog.Component, [Checkbox.name]: Checkbox, [CheckboxGroup.name]: CheckboxGroup,
    [CellGroup.name]: CellGroup, [Tabbar.name]: Tabbar, [TabbarItem.name]: TabbarItem, [NavBar.name]: NavBar
  },
  data() {
    return {
      active: -1,
      userInfo: {},
      message: '',
      totalPrice: 0,
      ordersStatus: {},
      vipDuiShow: false,
      show: true,
      loading: false,
      address: '',
      patientUser: Vue.ls.get(PATIENT_USERID),
      list: [],
      list1: [],
      list2: [],
      result: [],
    };
  },
  mounted() {
    this.getDataInfo();
  },
  watch: {
    result() {
      console.log(this.result)
      var that = this;
      var tempPrice = 0;
      this.result.map(x => {
        tempPrice = tempPrice + parseFloat(x.price);
      })
      this.totalPrice = tempPrice + "元";
    }
  },
  methods: {
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    toggle2(index) {
      this.$refs.checkboxes2[index].toggle();
    },
    choosePatientUser() {
      this.$router.push({name: 'patient/toChoosePatientUser'});
    },
    onClickMyOrderCheckList() {
      this.$router.push({name: 'patient/yuyuejiancha/myOrderCheckList'});
    },
    onClickLeft() {
      this.$router.push({name: 'patient/index'});
    },
    getDataInfo() {
      var that = this;
      postAction('/web/api/patient/checkOrder/checkOrderList', {}).then(function (response) {
        if (response.code == "0000") {
          that.list = response.result.data;
          response.result.data.map(x => {
            if (x.type === 1) {
              that.list1.push(x);
            }
            if (x.type === 2) {
              that.list2.push(x);
            }
          })
        }
      })
    },
    goMy() {
      this.$router.push({name: 'xingqiu/myQuestion'});
    },
    toAsk() {
      let that = this;
      if (!this.patientUser) {
        Toast("请选择家庭成员！");
        return false;
      }
      if (this.totalPrice === 0) {
        Toast("请选择检查项目！");
        return false;
      }
      if (!this.address) {
        Toast("请填写住址！");
        return false;
      }

      if (!this.loading) {
        this.loading = true;
        var checkOrderId = "";
        this.result.map(x => {
          checkOrderId = checkOrderId + x.id + ",";
        })
        postAction('/web/api/patient/checkOrder/toOrder', {
          checkOrderId: checkOrderId,
          patientUserId: that.patientUser.id,
          remark: this.message,
          address: this.address
        }).then(function (response) {
          if (response.code == "0000") {
            postAction('/web/api/patient/checkOrder/getRechargeInfo', {
              "ordersn": response.result.ordersn
            }).then(function (res) {
              console.log(res);
              wx.chooseWXPay({
                timestamp: res.result.data.payInfo.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                nonceStr: res.result.data.payInfo.nonceStr, // 支付签名随机串，不长于 32 位
                package: res.result.data.payInfo.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                signType: res.result.data.payInfo.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                paySign: res.result.data.payInfo.paySign, // 支付签名
                success: function (res) {
                  console.log(res);
                  // 支付成功后的回调函数
                  that.$emit('ok')
                  const toast = Toast.success({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "支付成功",
                  });
                  let second = 3;
                  const timer = setInterval(() => {
                    second--;
                    if (second) {
                      toast.message = "支付成功"
                    } else {
                      clearInterval(timer);
                      // 手动清除 Toast
                      Toast.clear();
                      that.$router.push({name: 'patient/yuyuejiancha/myOrderCheckList'});
                    }
                  }, 1000);
                }
              });
            })
            Toast("提交成功！");
          }
        }).finally(() => {
          this.loading = false;
        })
      }
    },
  }
};
</script>

<style lang="less">
.ttt {
  color: red;
}
</style>
