<template>
    <div>
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div class="tw-order-card" v-for="item in list" :key="item.id" @click="goInfo(item.ordersn)">
                <div class="order-header">
                    <div class="order-header-item">
                        <img class="order-header-icon" src="/img/zixun.png">
                        <span class="order-header-title" v-if="item.orderType!=2">图文咨询</span>
                        <span class="order-header-title" v-if="item.orderType==2">电话咨询</span>
                    </div>
                    <div class="order-header-item"><span
                            class="order-header-time">{{item.createTime|substrs}}</span><span
                            class="order-header-status">{{types(item.orderFlag)}}</span></div>
                </div>
                <div class="order-container">
                    <div class="order-container-question">{{kyz.$sysDictFilter(item.sex,
                        'sex')}},{{item.age}}岁,{{item.ordersChat.remark}}
                    </div>
                    <div class="order-container-tips">
                        <img class="order-container-avatar"
                             :src="kyz.$getFileAccessHttpUrl(item.avatar)"/>
                        <span class="order-container-info">{{item.doctorName}}，{{item.hospName}}</span>
                    </div>
                </div>
                <div class="order-button" v-if="item.orderFlag==0">
                    <van-button round type="primary" @click.stop="toPay(item.ordersn)">立即支付</van-button>
                </div>
                <div class="order-button" v-if="item.orderFlag==20">
<!--                    <van-button round type="primary">完善咨询</van-button>-->
                </div>
                <div class="order-button" v-if="item.orderFlag==30">
                </div>
                <div class="order-button" v-if="item.orderFlag==40">
                    <van-button round type="default" @click.stop="goDoctorInfo(item.doctorId)">再次咨询</van-button>
                    <van-button round type="primary" @click.stop="goCommentInfo(item.ordersn)">评价</van-button>
                </div>
                <div class="order-button" v-if="item.orderFlag==50">
                    <van-button round type="primary" @click.stop="goDoctorInfo(item.doctorId)">再次咨询</van-button>
                </div>
            </div>
        </van-list>
        <br> <br> <br>
        <Footer :active="active"></Footer>
        <ToPay ref="toPay" @ok="refresh()"></ToPay>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        Search,
        DropdownMenu,
        DropdownItem,
        Button,
        List,
        Tag,
    } from 'vant';
    import Footer from '@/view/patient/index/footer'
    import {postAction} from '@/api/manage';
    import ToPay from '@/view/patient/order/toPay'

    export default {
        components: {
            ToPay,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            Footer,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tag.name]: Tag,
            [Search.name]: Search
        },

        data() {
            return {
                active: 2,
                list: [],
                loading: false,
                finished: false,
                pageNumber: 1,
                kyz: this
            };
        },
        methods: {
            onLoad() {
                // 异步更新数据
                // setTimeout 仅做示例，真实场景中一般为 ajax 请求
                this.loadData();
            },
            types(tt) {
                if (tt == 0) {
                    return "待付款"
                } else if (tt == 20) {
                    return "待接单"
                } else if (tt == 30) {
                    return "待完成"
                } else if (tt == 40) {
                    return "待评价"
                } else if (tt == 100) {
                    return "已取消"
                } else if (tt == 50) {
                    return "已评价"
                }
            },
            loadData() {
                let that = this;
                //发送 post 请求
                this.$nextTick(() => {
                    postAction('/web/api/patient/myDoctorRecordList', {
                        "pageNo": that.pageNumber
                    }).then(function (response) {
                        console.log(response.result);
                        // 异步更新数据
                        for (let i = 0; i < 10; i++) {
                            if (response.result.data.records[i]) {
                                that.list.push(response.result.data.records[i]);
                            }
                        }
                        that.pageNumber = that.pageNumber + 1;
                        // 加载状态结束
                        that.loading = false;
                        // 数据全部加载完成
                        if (that.list.length >= response.result.data.total) {
                            that.finished = true;
                        }
                    })
                })
            },
            goDoctorInfo(doctorId) {
                this.$router.push({name: 'doctorInfo', query: {doctorId: doctorId}});
            },
            goCommentInfo(ordersn) {
                this.$router.push({name: 'patient/myOrderCommit', query: {ordersn: ordersn}});
            },
            goInfo(ordersn) {
                this.$router.push({name: 'patient/chat', query: {ordersn: ordersn}});
            },
            toPay(ordersn){
                this.$refs.toPay.toShow(ordersn);
            },
            refresh() {
                window.location.href = window.location.href;
            }
        }
    };
</script>

<style lang="less" scoped>
    .tw-order-card:first-child {
        margin-top: 0;
    }

    .tw-order-card {
        padding: 0 15px 15px;
        margin-top: 9px;
        background: #fff;
    }

    .order-header {
        height: 50px;
        line-height: 50px;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        position: relative;
    }

    .order-container {
        padding-top: 21px;
    }

    .order-button {
        margin-top: 15px;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        justify-content: flex-end;
    }

    .order-header-item {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .order-header-icon {
        width: 24px;
        height: 24px;
    }

    .order-header-title {
        display: inline-block;
        margin-left: 5px;
        font-size: 16px;
        color: #444;
        line-height: 16px;
    }

    .order-header-time {
        padding-right: 9px;
        position: relative;
    }

    .order-header-status {
        padding-left: 9px;
    }

    .order-header-status, .order-header-time {
        color: #ccc;
        line-height: 14px;
        font-size: 14px;
    }

    .order-header-status, .order-header-time {
        color: #ccc;
        line-height: 14px;
        font-size: 14px;
    }

    .order-container-question {
        color: #444;
        font-size: 16px;
        line-height: 24px;
        word-break: break-all;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }

    .order-container-tips {
        margin-top: 9px;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .order-container-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    .order-container-info {
        margin-left: 6px;
        display: inline-block;
        color: #aaa;
        font-size: 14px;
        line-height: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .van-button--normal {
        height: 30px;
        margin-left: 10px;
    }
</style>
