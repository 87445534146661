<template>
    <div>
        <van-nav-bar>
            <div slot="left">
                <div style="margin-left: 1px;">
                    <span style="padding-top: -10px">订单状态：{{this.$sysDictFilter(orderInfo.payFlag, 'payFlag')}}</span>
                </div>
            </div>
        </van-nav-bar>
        <van-cell-group>
            <van-cell-group>
                <van-cell title="标题" v-model="orderInfo.title">
                </van-cell>
                <van-cell title="医生信息" v-model="docName" is-link  @click="goDoctorInfo()">
                </van-cell>
                <van-cell title="订单编号" :value="orderInfo.ordersn"></van-cell>
                <van-cell title="创建时间" :value="orderInfo.createTime"></van-cell>
                <van-cell title="付款时间" v-if="orderInfo.payTime" :value="orderInfo.payTime"></van-cell>
                <van-cell title="讲堂内容">
                </van-cell>
                <div style="margin: 10px 10px 10px 10px" v-html="orderInfo.remark">
                </div>
            </van-cell-group>
        </van-cell-group>
        <van-submit-bar :price="orderInfo.amount*100" :button-text="buttonText" @submit="toPay()" text-align="left"
                        :loading="loading"
                        :disabled="disabled"
                        button-type="primary"/>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        Button, Field,
        List, Card, SubmitBar,
        Tab, Tabs, NavBar, Rate,Toast
    } from 'vant';
    import wx from 'weixin-js-sdk'
    import {postAction} from '@/api/manage';

    export default {
        components: {
            [NavBar.name]: NavBar,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Field.name]: Field,
            [Card.name]: Card,
            [Rate.name]: Rate,
            [SubmitBar.name]: SubmitBar,
        },

        data() {
            return {
                orderInfo: {},
                docName: '',
                buttonText: '去支付',
                disabled: false,
                ordersn: this.$route.query.ordersn
            }
        },
        mounted() {
            this.onLoad()
            document.title = "线下专家讲堂详情"
        },
        methods: {
            onLoad() {
                //发送 post 请求
                let that = this;
                this.$nextTick(() => {
                    postAction('/web/api/patient/myOcInfo', {
                        "ordersn": that.ordersn
                    }).then(function (response) {
                        console.log(response.result);
                        that.orderInfo = response.result.data;
                        that.docName = response.result.docName;
                        if (that.orderInfo.payFlag == 0) {
                            that.disabled = false
                        } else {
                            that.disabled = true
                            that.buttonText = "已支付"
                        }
                    })
                })
            },
            goDoctorInfo() {
                this.$router.push({name: 'doctorInfo', query: {doctorId: this.orderInfo.doctorId}});
            },
            toPay() {
                const that = this;
                postAction('/web/api/payOc/getRechargeInfo', {
                    "ordersn": that.ordersn
                }).then(function (res) {
                    console.log(res.result);
                    wx.chooseWXPay({
                        timestamp: res.result.data.payInfo.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                        nonceStr: res.result.data.payInfo.nonceStr, // 支付签名随机串，不长于 32 位
                        package: res.result.data.payInfo.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                        signType: res.result.data.payInfo.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                        paySign: res.result.data.payInfo.paySign, // 支付签名
                        success: function (res) {
                            console.log(res);
                            // 支付成功后的回调函数
                            that.$emit('ok')
                            const toast = Toast.success({
                                duration: 0, // 持续展示 toast
                                forbidClick: true,
                                message: "支付成功",
                            });
                            let second = 3;
                            const timer = setInterval(() => {
                                second--;
                                if (second) {
                                    toast.message = "支付成功"
                                } else {
                                    clearInterval(timer);
                                    // 手动清除 Toast
                                    Toast.clear();
                                    window.location.href = window.location.href
                                }
                            }, 1000);
                        }
                    });
                }).finally(() => {
                    that.loading = false;
                })
            },
            paySuccess() {
                this.disabled = true;
                this.buttonText = "已支付"
            }
        }
    };
</script>

<style lang="less" scoped>
    .van-doc-demo-block__title {
        margin: 0;
        padding: 7px 14px 0px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 12px;
    }
</style>
