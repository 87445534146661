<template>
  <div>
    <div class="bg2">
      <van-row>
        <van-col span="6" style="text-align: right">
          <van-image class="aui-shop-logo" round error-icon="/img/nologin.png"
                     :src="this.$getFileAccessHttpUrl(userInfo.avatar)"
                     width="75px" height="75px"/>
        </van-col>
        <van-col style="text-align: left">
          <div style="width: 100%;z-index: 99999;margin-top: 54px;margin-left: 10px" v-cloak>
            <!--                <van-button round type="primary" style="height: 30px;" v-if="goLogin">立即登录</van-button>-->
            {{ userInfo.name }}
            <van-icon name="medal-o" color="red" v-if="userInfo.vipFlag==1"/>
            <!--                        <br><span style="font-size: 12pt;color: gray;"></span>-->
          </div>
        </van-col>
      </van-row>
      <div class="p-my-profile--banner-info">
        <div class="vip health-vip" @click="$router.push({name: 'patient/vip'});">
          <div class="title">闻诚会员</div>
          <div class="wand-swipe" style="width: 90%;">
            <div v-if="userInfo.vipFlag==0">
              <p class="info" style="text-align: center;padding-right: 20px">咨询8.8折，追问延长24小时</p>
            </div>
            <div v-if="userInfo.vipFlag==1">
              <p class="info" style="text-align: center;padding-right: 20px">
                到期时间:{{ userInfo.vipTime|substrs3 }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <van-cell-group class="user-group">
      <van-row class="user-links">
        <van-col span="5" @click="goMyOrder(0)">
          <van-icon name="pending-payment" :badge="ordersStatus[0]"/>
          待付款
        </van-col>
        <van-col span="5" @click="goMyOrder(20)">
          <van-icon name="label-o" :badge="ordersStatus[20]"/>
          待接单
        </van-col>
        <van-col span="5" @click="goMyOrder(30)">
          <van-icon name="tosend" :badge="ordersStatus[30]"/>
          待完成
        </van-col>
        <van-col span="5" @click="goMyOrder(40)">
          <van-icon name="comment-circle-o" :badge="ordersStatus[40]"/>
          待评价
        </van-col>
        <van-col span="4" @click="goMyOrder(-1)">
          <van-icon name="records"/>
          全部订单
        </van-col>
      </van-row>
    </van-cell-group>

    <van-cell-group>
      <van-cell icon="like-o" title="我的关注" is-link @click="goMyFollowList()"/>
      <van-cell icon="notes-o" title="我的预约" is-link @click="goMyYuYue()"/>
      <van-cell icon="friends-o" title="我的家庭成员" is-link @click="goMyPatientUser()"/>
      <van-cell icon="notes-o" title="我的专家讲堂" is-link @click="goMyOc()"/>
      <van-cell icon="notes-o" title="我的健康学院" is-link @click="goMyVideoSpecial()"/>
      <van-cell icon="gold-coin-o" title="我的余额" :value="balanceLast+'元'" is-link @click="goMyBalanceList()"/>
      <van-cell icon="phone-o" title="联系我们" :value="this.$sysDictFilter(0, 'telephone')"
                :url="'tel:'+this.$sysDictFilter(0, 'telephone')"/>
      <van-cell icon="envelop-o" title="意见反馈" is-link @click="goFeedback()"/>
      <van-cell icon="envelop-o" title="兑换会员" is-link @click="toCheckLogin"/>
    </van-cell-group>

    <van-dialog
        v-model="vipDuiShow"
        title="兑换会员"
        show-cancel-button
        @confirm="dOk"
        :before-close="beforeClose">
      <van-field
          v-model="vipCode"
          placeholder="请输入您的兑换码"
      />
    </van-dialog>

    <Footer :active="active"></Footer>
  </div>
</template>

<script>
import {Cell, CellGroup, Field, Dialog, Button} from 'vant';
import Footer from '@/view/patient/index/footer'
import {postAction} from '@/api/manage';
import {Toast} from 'vant';

export default {
  components: {
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    Footer,
    [Dialog.Component.name]: Dialog.Component,
    [CellGroup.name]: CellGroup
  },
  data() {
    return {
      active: 4,
      userInfo: {},
      balanceLast: 0,
      ordersStatus: {},
      vipDuiShow: false,
      show: true,
      vipCode: ''
    };
  },
  mounted() {
    this.loadData();
    this.getOrdersStatus();
  },
  methods: {
    toCheckLogin() {
      let that = this;
      postAction('/web/api/patient/toFollowFlagCheck', {}).then(function (response) {
        console.log(response.result);
        that.vipDuiShow = !that.vipDuiShow
      })
    },
    dOk() {
      let that = this;
      if (that.vipCode.length == 0) {
        Toast("请输入兑换码");
      } else {
        this.$nextTick(() => {
          postAction('/web/api/patient/vipCode', {
            "vipCode": that.vipCode,
          }).then(function () {
            Toast('兑换成功')
            window.location.href = window.location.href;
          })
        })
      }

    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        done(false);
      } else {
        done();
      }
    },
    loadData() {
      let that = this;
      //发送 post 请求
      postAction('/web/api/patient/userInfo', {}).then(function (response) {
        console.log(response.result);
        that.userInfo = response.result.data;
        that.balanceLast = response.result.balanceLast;
      }).finally(() => {
      })
    },
    getOrdersStatus() {
      let that = this;
      //发送 post 请求
      postAction('/web/api/patient/getOrdersStatus', {}).then(function (response) {
        console.log(response.result);
        that.ordersStatus = response.result.data;
      }).finally(() => {
      })
    },
    goMyYuYue() {
      this.$router.push({name: 'patient/myWater'});
    },
    goFeedback() {
      this.$router.push({name: 'feedback'});
    },
    goMyFollowList() {
      this.$router.push({name: 'patient/myFollow'});
    },
    goMyBalanceList() {
      this.$router.push({name: 'patient/myBalance'});
    },
    goMyOrder(type) {
      this.$router.push({name: 'patient/myOrder', query: {type: type}});
    },
    goMyPatientUser() {
      this.$router.push({name: 'patient/myPatientUser'});
    },
    goMyOc() {
      this.$router.push({name: 'patient/myOrgCollection'});
    },
    goMyVideoSpecial() {
      this.$router.push({name: 'patient/myVideoSpecial'});
    },
  }
};
</script>

<style lang="less">
.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}

.bg2 {
  background: url('/img/bg.png') no-repeat;
  height: 181px;
}

.bg {
  background: url('/img/bg.png') no-repeat;
  height: 150px;
}

.aui-shop-logo {
  z-index: 1;
  top: 35px;
}

.aui-shop-logo-txt {
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 99999;
  top: 126px;
  color: white;
}

.wHide {
  /*display: none;*/
}

.user-links .van-info {
  right: 25px;
}

.wShow {
  display: inline-block !important;
}

.p-my-profile--banner-info .vip.health-vip {
  background: url('/img/vipbanner2.png') no-repeat;
  background-size: 100%;
  color: #e6cb87;
}

.p-my-profile--banner-info {
  margin-top: 53px;
  text-align: center;
}

.p-my-profile--banner-info .vip {
  left: .512rem;
  display: inline-flex;
  bottom: 0px;
  width: 90%;
  height: 65px;
  padding: 0 .42666667rem;
  line-height: 1.92rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: url('/img/vipbanner.png') no-repeat;
  background-size: 100%;
}

.p-my-profile--banner-info .vip.health-vip .title {
  color: #e6cb87;
}

.p-my-profile--banner-info .vip.health-vip .wand-swipe {
  color: #e6cb87;
  background: url('/img/kuozhan.png') 99% no-repeat;
  background-size: .49866667rem .912rem;
  background-position: right 5px top 18px;
  margin-left: 1px;
  font-size: 10pt;
  line-height: 50px;
}

.p-my-profile--banner-info .vip .title {
  padding-left: 24px;
  background: url('/img/viplogo.png') 0 no-repeat;
  background-position: 0px 16px;
  background-size: 1.05333333rem 1.05333333rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-right: 2.13333333rem;
  font-size: 13pt;
  font-weight: 400;
  line-height: 50px;
  color: #6b331a;
}

.van-icon__image {
  width: 75px;
  height: 75px;
}
</style>
