<template>
  <div>
    <van-nav-bar
        title="我的预约检查"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
    />
    <van-dropdown-menu>
      <van-dropdown-item v-model="value1" :options="option1" @change="initData"/>
    </van-dropdown-menu>
    <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
    >
      <van-empty description="" v-if="this.list.length==0"/>
      <div style="margin: 10px 5px 10px 5px;border:1px solid #f3f2f2;border-radius: 5px;background-color: white"
           v-for="item in list"
           :key="item.doctorId"
           @click="goInfo(item.id)">
        <div style="margin: 10px 10px 0px 10px;">
          <van-row>
            <van-col span="3">
              <van-image
                  radius="5"
                  width="40px"
                  height="40px"
                  :src="kyz.$getFileAccessHttpUrl(item.patientAvatar)"
              />
            </van-col>
            <van-col span="21" style="font-size: 12pt;color:#6d6c6c">
              <div>{{ item.checkName }}-预约检查</div>
              <div style="color: gray;font-size: 9pt">{{ item.patientName }}</div>
              <div style="color: gray;font-size: 9pt">{{ item.createTime.substring(0, 10) }}</div>
            </van-col>
          </van-row>
        </div>
        <van-divider style="margin: 10px 10px 10px 10px"/>
        <div style="margin: 12px 10px 10px 10px;color:#a2a1a1;">
          <van-row>
            <van-col span="10">
              <div style="font-size: 12pt;text-align: left">支付：{{item.checkPrice}}元</div>
            </van-col>
            <!--            <van-col span="14">-->
            <!--              <div style="font-size: 14pt;text-align: right;margin-right: 1px">-->
            <!--                <van-icon name="good-job-o"/>-->
            <!--                <div style="font-size: 12pt;margin-left: 5px;float: right"> 10</div>-->
            <!--              </div>-->
            <!--            </van-col>-->
            <van-col span="14">
              <div style="font-size: 14pt;text-align: right;margin-right: 1px">
                <div style="font-size: 12pt;margin-left: 5px;float: right"> 状态：{{item.checkFlag==1?'处理中':'已处理'}}</div>
              </div>
            </van-col>
          </van-row>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>

import {
  Cell,
  CellGroup,
  Field,
  Dialog, NavBar,
  Button,
  Search,
  Divider,
  Tabbar,
  TabbarItem,
  List,
  Tab,
  Tabs,
  Card,
  DropdownMenu,
  DropdownItem
} from 'vant';
import {postAction} from '@/api/manage';
import {Toast} from 'vant';

export default {
  components: {
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Field.name]: Field,
    [List.name]: List,
    [Dialog.name]: Dialog,
    [Search.name]: Search,
    [Divider.name]: Divider,
    [Card.name]: Card, [NavBar.name]: NavBar,
    [Dialog.Component.name]: Dialog.Component,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [CellGroup.name]: CellGroup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  data() {
    return {
      active: 2,
      userInfo: {},
      value1: 1,
      option1: [
        {text: '全部状态', value: -1},
        {text: '已处理', value: 2},
        {text: '未处理', value: 1},
      ],
      list: [],
      kyz: this,
      searchName: '',
      doctorType: -1,
      tmpPatientId: 0,
      pageNumber: 1,
      loading: false,
      finished: false,
      show: false,
      userName: '',
      listPatient: [],
      pageNumberPatient: 1,
      loadingPatient: false,
      finishedPatient: false,
    };
  },
  mounted() {
  },
  methods: {
    onClickLeft() {
      this.$router.push({name: 'doctor/userCenter'});
    },
    goInfo(msgId) {
      this.$router.push({name: 'doctor/yuyuejiancha/myOrderCheckInfo', query: {id: msgId}});
    },
    onLoad() {
      this.initData()
    },
    initData() {
      let that = this;
      that.pageNumber = 1;
      that.loading = true
      that.finished = false
      that.list = []
      that.loadData();
    },
    loadData() {
      let that = this;
      //发送 post 请求
      this.$nextTick(() => {
        postAction('/web/api/doctor/checkOrder/getMyOrderCheckList', {
          "pageNo": that.pageNumber, checkFlag: this.value1
        }).then(function (response) {
          console.log(response.result);
          // 异步更新数据
          for (let i = 0; i < 10; i++) {
            if (response.result.data.records[i]) {
              that.list.push(response.result.data.records[i]);
            }
          }
          that.pageNumber = that.pageNumber + 1;
          // 加载状态结束
          that.loading = false;
          // 数据全部加载完成
          if (that.list.length >= response.result.data.total) {
            that.finished = true;
          }
        })
      })
    },
  }
};
</script>

<style lang="less" scoped>
.van-doc-demo-block__title {
  margin: 0;
  padding: 7px 14px 0px;
  color: rgba(69, 90, 100, 0.6);
  font-weight: normal;
  font-size: 12px;
  background-color: white;
}

.van-card__footer {
  margin-top: -20px;
}

.van-card__desc {
  margin-top: 10px;
}

.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}

.bg {
  background: linear-gradient(#54b279, #6bc193);
  height: 160px;
}

.aui-shop-logo {
  z-index: 99999;
  top: 35px;
}

.aui-shop-logo-txt {
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 99999;
  top: 126px;
  color: white;
}

.wHide {
  /*display: none;*/
}

.van-info {
  right: 46px;
}

.wShow {
  display: inline-block !important;
}
</style>
