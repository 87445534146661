<template>
    <div>
        <van-tabs v-model="active">
            <van-tab :title="title">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <div v-for="item in list"
                         :key="item.id">
                        <div class="van-doc-demo-block__title"
                             @click="goInfo(item.ordersn)">
                            <van-row>
                                <van-col span="12">
                                    <div style="text-align: left;">下单时间：{{item.createTime}}</div>
                                </van-col>
                                <van-col span="12">
                                    <div style="text-align: right;">状态：{{types(item.orderFlag)}}</div>
                                </van-col>
                            </van-row>
                        </div>
                        <van-card
                                :price="item.amount.toFixed(2)"
                                :desc="kyz.$sysDictFilter(item.sex, 'sex')+','+item.age+'岁,'+item.ordersChat.remark"
                                :title="'[闻诚健康]'+item.doctorName"
                                :thumb="kyz.$getFileAccessHttpUrl(item.avatar)"
                                @click="goInfo(item.ordersn)"
                        >
                            <template #footer v-if="item.orderFlag==40">
                                <van-button size="small" @click.stop="goCommentInfo(item.ordersn)">评价</van-button>
                            </template>
                            <template #footer v-if="item.orderFlag==0">
                                <van-button size="small" @click.stop="toPay(item.ordersn)">支付</van-button>
                            </template>
                        </van-card>
                    </div>
                </van-list>
            </van-tab>
        </van-tabs>
        <ToPay ref="toPay" @ok="refresh()"></ToPay>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        Button, Field,
        List, Card, SubmitBar,
        Tab, Tabs
    } from 'vant';
    import {postAction} from '@/api/manage';
    import ToPay from '@/view/patient/order/toPay'

    export default {
        components: {
            ToPay,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Field.name]: Field,
            [Card.name]: Card,
            [SubmitBar.name]: SubmitBar,
        },

        data() {
            return {
                active: 0,
                list: [],
                loading: false,
                finished: false,
                title: '',
                type: this.$route.query.type,
                pageNumber: 1,
                kyz: this,
            };
        },
        mounted() {
            this.inits();
        },
        methods: {
            onLoad() {
                this.loadData();
            },
            loadData() {
                let that = this;
                //发送 post 请求
                this.$nextTick(() => {
                    postAction('/web/api/patient/myOrderList', {
                        "pageNo": that.pageNumber, "orderFlag": this.type
                    }).then(function (response) {
                        console.log(response.result);
                        // 异步更新数据
                        for (let i = 0; i < 10; i++) {
                            if (response.result.data.records[i]) {
                                that.list.push(response.result.data.records[i]);
                            }
                        }
                        that.pageNumber = that.pageNumber + 1;
                        // 加载状态结束
                        that.loading = false;
                        // 数据全部加载完成
                        if (that.list.length >= response.result.data.total) {
                            that.finished = true;
                        }
                    })
                })
            },
            onSubmit(values) {
                console.log('submit', values);
            },
            inits() {
                if (this.type == 0) {
                    this.title = "待付款"
                    document.title = "待付款";
                } else if (this.type == 20) {
                    this.title = "待接单"
                    document.title = "待接单";
                } else if (this.type == 30) {
                    this.title = "待完成"
                    document.title = "待完成";
                } else if (this.type == 40) {
                    this.title = "待评价"
                    document.title = "待评价";
                } else if (this.type == -1) {
                    this.title = "全部订单"
                    document.title = "全部订单";
                }
            },
            types(tt) {
                if (tt == 0) {
                    return "待付款"
                } else if (tt == 20) {
                    return "待接单"
                } else if (tt == 30) {
                    return "待完成"
                } else if (tt == 40) {
                    return "待评价"
                } else if (tt == 100) {
                    return "已取消"
                } else if (tt == 50) {
                    return "已评价"
                }
            },
            refresh() {
                window.location.href = window.location.href;
            },
            goInfo(ordersn) {
                this.$router.push({name: 'patient/myOrderInfo', query: {ordersn: ordersn}});
            },
            toPay(ordersn) {
                this.$refs.toPay.toShow(ordersn);
            },
            goCommentInfo(ordersn) {
                this.$router.push({name: 'patient/myOrderCommit', query: {ordersn: ordersn}});
            },
        }
    };
</script>

<style lang="less" scoped>
    .van-doc-demo-block__title {
        margin: 0;
        padding: 7px 14px 0px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 12px;
        background-color: white;
    }
</style>
