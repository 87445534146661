<template>
    <div>
        <van-nav-bar right-text="提现">
            <div slot="left">
                <div style="margin-left: 1px;">
                    <span style="padding-top: -10px">我的佣金：55</span>
                </div>
            </div>
        </van-nav-bar>
        <van-tabs v-model="active">
            <van-tab title="来源">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <van-card
                            price="2.00"
                            desc="订单编号：55222556666"
                            title="获得日期：2020-05-06"
                    >
                    </van-card>
                    <van-card
                            price="2.00"
                            title="充值日期"
                    >
                    </van-card>
                </van-list>
            </van-tab>
            <van-tab title="提现">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <van-card
                            price="2.00"
                            desc="审核状态：通过"
                            title="申请日期：2020-05-06"
                    >
                    </van-card>
                    <van-card
                            price="2.00"
                            title="充值日期"
                    >
                    </van-card>
                </van-list>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        Button, Field,
        List, Form, SubmitBar,
        Tag, Step, Steps, Tab, Tabs,Card,NavBar
    } from 'vant';

    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tag.name]: Tag,
            [Step.name]: Step,
            [Field.name]: Field,
            [Form.name]: Form,[NavBar.name]: NavBar,
            [SubmitBar.name]: SubmitBar,
            [Steps.name]: Steps, [Tab.name]: Tab, [Tabs.name]: Tabs, [Card.name]: Card,
        },

        data() {
            return {
                active: 0,
                list: [],
                loading: false,
                finished: false
            };
        },
        methods: {
            onLoad() {
                // 异步更新数据
                // setTimeout 仅做示例，真实场景中一般为 ajax 请求
                document.title = "dd s";
                setTimeout(() => {
                    for (let i = 0; i < 10; i++) {
                        this.list.push(this.list.length + 1);
                    }

                    // 加载状态结束
                    this.loading = false;

                    // 数据全部加载完成
                    if (this.list.length >= 40) {
                        this.finished = true;
                    }
                }, 1000);
            },
            onSubmit(values) {
                console.log('submit', values);
            },
            onConfirm() {
            },
        }
    };
</script>

<style lang="less" scoped>
    .van-card__bottom{
        padding-bottom: 20px;
    }
    .van-doc-demo-block__title {
        margin: 0;
        padding: 7px 14px 0px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 12px;
    }
</style>
