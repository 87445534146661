<template>
    <div class="container">
        <div class="page-detail">
            <div class="module-pic">
                <div class="image">
                    <div class="imgWrap" style="width: auto; height: 210px;">
                        <img v-lazy="kyz.$getFileAccessHttpUrl(videoSpecial.pic)" style="width: 100%"/>
                    </div>
                </div>
            </div>
            <div class="module-main">
                <div class="acticity-bar">
                    <div class="price gradient-new-bg main-text">
                        <i class="lines-bg activity-main-lines-bg"></i>
                        <p class="price-line" v-if="(videoSpecial.amount+'').lastIndexOf('.')==-1">
                            <span>￥</span>
                            <span class="main-price">{{videoSpecial.amount}}</span>
                            <span>.00</span>
                        </p>
                        <p class="price-line" v-if="(videoSpecial.amount+'').lastIndexOf('.')>-1">
                            <span>￥</span>
                            <span class="main-price">{{(videoSpecial.amount+'').substring(0,(videoSpecial.amount+'').lastIndexOf('.'))}}</span>
                            <span>{{(videoSpecial.amount+'').substring((videoSpecial.amount+'').lastIndexOf('.'),(videoSpecial.amount+'').length)}}</span>
                        </p>
                        <p class="other-line">
                            <span class="origin">原价￥{{videoSpecial.marketAmount}}</span>
                        </p>
                    </div>
                </div>
                <van-tabs v-model="active">
                    <van-tab title="专栏介绍">
                        <div class="base-info-block"><!----><!---->
                            <div>
                                <div class="title-area">
                                    <div>
                                        <span class="title">{{videoSpecial.title}}</span>
                                    </div>
                                </div>
                                <div class="knowledge-info">
                                    <table class="table">
                                        <tr class="knowledge-info-line">
                                            <td>
                                                <span class="knowledge-info-label">已学</span>
                                            </td>
                                            <td>
                                                <span>{{videoSpecial.playCount}}人</span>
                                            </td>
                                        </tr>
                                        <tr class="knowledge-info-line">
                                            <td>
                                                <span class="knowledge-info-label">讲师</span>
                                            </td>
                                            <td>
                                                <span>{{videoSpecial.doctorName}}</span>
                                            </td>
                                        </tr>
                                        <tr class="knowledge-info-line">
                                            <td>
                                                <span class="knowledge-info-label">状态</span>
                                            </td>
                                            <td>
                                                <span class="knowledge-info-sub-value">已更新{{videoSpecial.fileCount}}期</span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="intro-block">
                            <div class="van-cell-group intro">
                                <div v-html="videoSpecial.remark"></div>
                            </div>
                        </div>
                    </van-tab>
                    <van-tab title="专栏目录">
                        <div class="column-catalogue-block van-cell-group hide">
                            <div>
                                <div class="head van-cell van-cell--borderless">
                                    <div class="van-cell__title title">
                                        <span>全部内容({{videoSpecial.fileCount}})</span>
                                    </div>
                                </div>
                            </div>
                            <div><!---->
                                <div>
                                    <div role="feed" class="van-list">

                                        <div v-for="item in videoDetail"
                                             :key="item.id"
                                             @click="goInfo(item.id,item.file_flag)"
                                             class="goods-card small" data-alias="27a9v2d9veiyx" style="height: 90px"
                                             data-vtpidvtheme="vtp2">
                                            <div class="img-wrap">
                                                <div class="imgWrap"
                                                     style="width: 110px; height: 62px;">
                                                    <img v-lazy="kyz.$getFileAccessHttpUrl(item.pic)"
                                                         style="width: 100%"/>
                                                </div>
                                                <span class="mini-tag img-tag"
                                                      style="background-color: rgba(50, 50, 51, 0.8);">
                                                    <span class="tag" style="line-height: 16px;">{{kyz.$sysDictFilter(item.file_flag, 'fileFlag')}}</span>
                                                </span>
                                            </div>
                                            <div class="info-wrap">
                                                <h2 class="title">
                                                    {{item.title}}
                                                </h2>
                                                <div class="bottom has-button">
                                                    <div>
                                                        <span class="desc-item">{{item.play_count}}次学习</span>
                                                    </div>
                                                    <button v-if="item.free_flag==1"
                                                            class="button van-button van-button--default van-button--mini van-button--plain van-button--round"
                                                            style="color: rgb(255, 68, 68); border-color: rgb(255, 68, 68);">
                                                        <span class="van-button__text">免费</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="van-list__placeholder"></div>
                                    </div>
                                </div>
                            </div>
                            <div><!----></div>
                        </div>
                    </van-tab>
                </van-tabs>
            </div>
        </div>
        <van-submit-bar :price="videoSpecial.amount*100" button-text="购买" @submit="toSub()" text-align="left" :loading="loading" v-if="hasBuy==0"
                        :disabled="disabled"
                        button-type="danger"/>
        <ToPay ref="toPay" @ok="paySuccess()"></ToPay>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        Search,
        DropdownMenu,
        DropdownItem,
        Button,
        List, Tab, Tabs,
        Tag,
        Grid,
        GridItem, SubmitBar,
        Image
    } from 'vant';
    import ToPay from '@/view/patient/order/toPay'
    import {postAction} from '@/api/manage';
    import wx from 'weixin-js-sdk'

    export default {
        components: {
            ToPay,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tag.name]: Tag,
            [Grid.name]: Grid,
            [GridItem.name]: GridItem, [SubmitBar.name]: SubmitBar,
            [Image.name]: Image, [Tab.name]: Tab, [Tabs.name]: Tabs,
            [Search.name]: Search
        },

        data() {
            return {
                active: 0,
                orderType: 0,
                list: [],
                pageNumber: 1,
                loading: false,
                finished: false,
                disabled:false,
                kyz: this,
                departId: 0,
                dataId: this.$route.query.id,
                videoSpecial: {},
                videoDetail: {},
                hasBuy: {},
            };
        },
        mounted() {
            // 微信分享配置
            const that=this
            wx.ready(function () {
                wx.updateTimelineShareData({
                    title: that.videoSpecial.title, // 分享标题
                    link: 'http://wap.wenchengjiankang.com/patient/videoInfo?id='+that.videoSpecial.id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: that.$getFileAccessHttpUrl(that.videoSpecial.pic), // 分享图标
                    success: function () {
                        // 设置成功
                    }
                })
                wx.updateAppMessageShareData({
                    title: that.videoSpecial.title, // 分享标题
                    link: 'http://wap.wenchengjiankang.com/patient/videoInfo?id='+that.videoSpecial.id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                    imgUrl: that.$getFileAccessHttpUrl(that.videoSpecial.pic), // 分享图标
                    desc: '', // 分享描述
                    success: function () {
                        // 设置成功
                    }
                })
            })
            scroll(0, 0)
            this.getVideoInfo()
        },
        methods: {
            getVideoInfo() {
                let that = this;
                //发送 post 请求
                postAction('/web/api/index/videoSpecialInfo', {
                    "dataId": this.dataId
                }).then(function (response) {
                    console.log(response.result);
                    // 异步更新数据
                    that.videoSpecial = response.result.videoSpecial
                    that.videoDetail = response.result.videoDetail
                    document.title = that.videoSpecial.title;
                    that.hasBuy = response.result.hasBuy
                })
            },
            goInfo(id,fileFlag) {
                if(fileFlag==0){
                    this.$router.push({name: 'patient/voicePlay', query: {id: id}});
                }else{
                    this.$router.push({name: 'patient/videoPlay', query: {id: id}});
                }
            },
            toSub(){
                const that = this;
                that.loading = true;
                postAction('/web/api/order/toOrder', {
                    "orderType":3,
                    "videoSpecialId": this.dataId
                }).then(function (response) {
                    console.log(response.result);
                    that.ordersn = response.result.ordersn;
                    that.loading = false;
                    that.$refs.toPay.toShow(that.ordersn);
                }).finally(() => {
                    that.loading = false;
                })
            }

        },
        watch: {}
    };
</script>

<style lang="less" scoped>
    @import '/css/videoInfo.css';
</style>
