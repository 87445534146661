<template>
  <div style="background-color: #dee0e4">
    <van-nav-bar
        :right-text="types(orderFlag)"
        @click-left="onClickLeft"
        @click-right="onClickRight"
        :fixed="true"
    >
      <div slot="left">
        <div style="width: 40px;margin-left: -10px;">
          <img src="/img/zixun.png" style="width:25px">
        </div>
        <div style="margin-top: -21px;margin-left: 30px;">
          <span style="padding-top: -10px">{{ doctorName }}</span>
        </div>
      </div>
    </van-nav-bar>
    <div style="top: 46px;position: fixed;background-color: white;text-align: center;width: 100%;z-index: 1"
         v-if="orderFlag==30&&orderType!=2">
      <span style="padding-top: -10px;font-size: 9pt;width: 100%">剩余时间：{{ lastTime }}</span>
    </div>
    <div id="new_message">
      <div class="message">
        <br><br><br>
        <div v-if="orderFlag<30">
          <br><br><br>
        </div>
        <div v-if="orderFlag==30&&orderType!=2">
          <br><br><br><br><br><br>
        </div>
        <div v-if="(orderFlag>30&&canSend==1&&orderType!=2)">
          <br>
        </div>
        <div v-for="(item,index) in list" :key="item.id">
          <div class="send" v-if="item.userFlag==0">
            <div class="time">{{ item.createTime|substrs2 }}</div>
            <div class="msg">
              <img :src="kyz.$getFileAccessHttpUrl(patientAvatar)" alt="" style="border-radius: 5px"/>
              <p v-if="item.remark"><i class="msg_input"></i>
                <a v-if="item.remark.indexOf('http')>-1" :href="item.remark">{{ item.remark }}</a>
                <span v-if="item.remark.indexOf('http')===-1">{{ item.remark }}</span>
                <span v-if="patientUser&&index==0"><br>
                                    <span style="font-size: 11pt">【患者信息】</span><br>
                                    <span style="font-size: 11pt">年龄:{{ kyz.$sysGetAge2(patientUser.birthday) }}</span>
                                    <span
                                        style="font-size: 11pt">性别:{{
                                        kyz.$sysDictFilter(patientUser.sex, 'sex')
                                      }}</span><br>
                                    <span style="font-size: 11pt"
                                          v-if="patientUser.chengrenFlag==0">出生体重:{{ patientUser.weight }}<br></span>
                                    <span style="font-size: 11pt"
                                          v-if="patientUser.chengrenFlag==0">分娩方式:{{
                                        kyz.$sysDictFilter(patientUser.fenmianFlag, 'fenmianFlag')
                                      }}<br></span>
                                    <span style="font-size: 11pt"
                                          v-if="patientUser.chengrenFlag==0">孕:{{ patientUser.yunCount }} </span>
                                    <span style="font-size: 11pt"
                                          v-if="patientUser.chengrenFlag==0"> 产:{{ patientUser.chanCount }}<br></span>
                                    <span style="font-size: 11pt"
                                          v-if="patientUser.chengrenFlag==0">喂养方式:{{
                                        kyz.$sysDictFilter(patientUser.weiyangFlag, 'weiyangFlag')
                                      }}<br></span>
                                    <span style="font-size: 11pt"
                                          v-if="patientUser.chengrenFlag==1&&patientUser.sex==0">初潮年龄:{{
                                        patientUser.chuchaoAge
                                      }}<br></span>
                                    <span style="font-size: 11pt"
                                          v-if="patientUser.chengrenFlag==1&&patientUser.sex==0">月经周期:{{
                                        patientUser.yuejingRange
                                      }}<br></span>
                                    <span style="font-size: 11pt"
                                          v-if="patientUser.chengrenFlag==1&&patientUser.sex==0">末次月经周期:{{ patientUser.moyuejingRange }}<br></span>
                                    <span style="font-size: 11pt">过敏史:{{ patientUser.guomin }}</span><br>
                                    <span style="font-size: 11pt">既往疾病:{{ patientUser.jiwangjibing }}</span><br>
                                </span>
              </p>
              <p v-if="item.pics"><i class="msg_input"></i>
                <van-image
                    width="100"
                    height="100"
                    lazy-load
                    :src="kyz.$getFileAccessHttpUrl(item.pics)"
                    @click="showImgs(kyz.$getFileAccessHttpUrl(item.pics))"
                />
              </p>
            </div>
          </div>
          <div class="show" v-if="item.userFlag==1">
            <div class="time">{{ item.createTime|substrs2 }}</div>
            <div class="msg">
              <img :src="kyz.$getFileAccessHttpUrl(doctorAvatar)" alt=""  style="border-radius: 5px"/>
              <p v-if="item.remark"><i clas="msg_input"></i>{{ item.remark }}
              </p>
              <p v-if="item.pics"><i class="msg_input"></i>
                <van-image
                    width="100"
                    height="100"
                    lazy-load
                    :src="kyz.$getFileAccessHttpUrl(item.pics)"
                    @click="showImgs(kyz.$getFileAccessHttpUrl(item.pics))"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="orderFlag==30||(orderFlag>30&&canSend==1)">
      <br><br>
    </div>
    <van-goods-action style="bottom: 50px" v-if="(orderFlag==30)&&orderType==2&&expectCallFlag==1">
      <van-button type="primary" style="margin-left: 10px;    padding-top: 7px; width: 95%"
                  @click="toCall()">拨打电话
      </van-button>
    </van-goods-action>
    <br> <br> <br><br> <br>
    <van-goods-action v-if="(orderFlag==30||(orderFlag>30&&canSend==1))&&orderType!=2">
      <van-field
          id="textarea"
          v-model="value"
          rows="1"
          autosize
          :style="{bottom:bottom+'px'}"
          type="textarea"
          maxlength="100"
          placeholder="请输入发送内容"
          show-word-limit
      >
        <template #button>
<!--          <van-uploader v-model="imagePathShow" :preview-image="false" :multiple="false"-->
<!--                        :after-read="onRead"-->
<!--                        :before-read="beforeRead">-->
<!--            <van-button icon="photo" type="primary"></van-button>-->
<!--          </van-uploader>-->
          <van-button icon="photo" type="primary" @click="uploadImg"></van-button>
          <van-button type="primary" style="margin-left: 10px;    padding-top: 7px;"
                      @click="sendText(value,'')">发送
          </van-button>
        </template>
      </van-field>

      <!--            <van-field v-model="value" placeholder="请输入发送内容" :border="false">-->
      <!--                <template #button>-->
      <!--                    <van-button type="primary">发送</van-button>-->
      <!--                    <van-button type="primary">发送</van-button>-->
      <!--                </template>-->
      <!--            </van-field>-->
    </van-goods-action>
    <van-dialog v-model="dshow" title="标题" show-cancel-button @confirm="dOk">
      <van-cell icon="" title="请选择您的回拨日期:" :value="dDay" is-link @click="ddshow=!ddshow"/>
      <van-cell icon="" title="请选择您的回拨时间:" :value="currentTime" is-link @click="dtshow=!dtshow"/>
      <van-datetime-picker
          v-model="currentTime"
          type="time"
          v-if="dtshow"
          title="选择时间"
          @cancel="dtshow=!dtshow"
          @confirm="dtshow=!dtshow"
      />
    </van-dialog>

    <van-action-sheet
        v-model="ddshow"
        :actions="actions"
        cancel-text="取消"
        @select="onSelect"
        close-on-click-action
    />
    <Footer :active="active"></Footer>
  </div>
</template>

<script>
import SockJS from "sockjs-client"
import Stomp from "stompjs"
import wx from 'weixin-js-sdk'
import {
  Cell,
  CellGroup,
  Tabbar,
  TabbarItem,
  DropdownMenu,
  DropdownItem,
  Uploader,
  Button,
  List, ImagePreview,
  Tag, NavBar, GoodsAction, Field, Dialog, DatetimePicker, ActionSheet
} from 'vant';
import Footer from '@/view/doctor/user/footer'
import {Toast} from 'vant';
import {postAction, postActionMultipart} from '@/api/manage';
import {ACCESS_TOKEN} from "@/store/mutation-types";
import Vue from 'vue'

export default {
  components: {
    Footer,
    [Cell.name]: Cell,
    [Dialog.name]: Dialog,
    [CellGroup.name]: CellGroup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [List.name]: List,
    [Tag.name]: Tag,
    [GoodsAction.name]: GoodsAction,
    [DatetimePicker.name]: DatetimePicker,
    [ImagePreview.name]: ImagePreview,
    [Field.name]: Field, [ActionSheet.name]: ActionSheet,
    [NavBar.name]: NavBar,
    [Dialog.Component.name]: Dialog.Component,
  },

  data() {
    return {
      value: '',
      active: 9,
      list: [],
      ordersn: this.$route.query.ordersn,
      kyz: this,
      bottom: 60,
      doctorName: '',
      doctorAvatar: '',
      patientAvatar: '',
      orderFlag: -1,
      imagePathShow: [],
      lastCount: 0,
      canSend: 0,
      lastTime: '',
      timer: {},
      timer2: {},
      orderType: 0,
      dshow: false,
      patientUser: {},
      currentTime: new Date().getHours() + ":" + (new Date().getMinutes() < 10 ? ('0' + new Date().getMinutes()) : new Date().getMinutes()),
      ddshow: false,
      dtshow: false,
      dDay: '今天',
      expectCallFlag: 0,
      actions: [{name: '今天'}, {name: '明天'}, {name: '后天'}],
      stompClient: null,
      wsPatientId:null,
      wsOrderSn:null
    };
  },
  mounted() {
    this.loadData(true);
    this.getPatientUserInfo();
    window['doTime'] = (val) => {
      this.doTime(val)
    }
    this.timer = setInterval('doTime()', 1000);
    window['doTime2'] = (val) => {
      this.doTime2(val)
    }
  },
  watch: {
    value(val) {
      this.bottom = val.length / 3 + 60;
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
    clearInterval(this.timer2);
    this.timer2 = null;
  },
  methods: {
    doTime() {
      if (this.lastTime.indexOf("分") > 0) {
        var fenTime = parseInt(this.lastTime.split("分")[0]) * 60;
        var totalTime = fenTime + parseInt(this.lastTime.split("分")[1].replace("秒"));
        totalTime = totalTime - 1;
        if (totalTime <= 0) {
          window.location.href = window.location.href;
        }
        this.lastTime = parseInt(totalTime / 60) + "分" + (totalTime % 60) + "秒";
      }
    },
    doTime2() {
      console.log("stompClient:"+this.stompClient&&!this.stompClient.connected)
      if(this.stompClient&&!this.stompClient.connected){
        clearInterval(this.timer2);
        this.timer2 = null;
        this.connect(this.wsPatientId,this.wsOrderSn);
      }
    },
    //-----------------------------------
    connect(doctorId, orderSn) {
      console.log("doctorId:" + orderSn)
      const that = this
      let socket = new SockJS(process.env.VUE_APP_API_BASE_URL+'/websocket')
      this.stompClient = Stomp.over(socket, { timeout: 15000})
      // 向服务器发起websocket连接
      const user = {
        userId: doctorId,
        userType: 1,
        roomId: orderSn
      }
      this.stompClient.connect(user, () => {
        this.stompClient.subscribe('/chats/' + orderSn, (response) => { // 订阅服务端提供的某个topic
          if (response.body) {
            that.loadData(false)
          }
        });
        this.stompClient.subscribe('/owners/' + localStorage.getItem(ACCESS_TOKEN), (response) => { // 订阅服务端提供的某个topic
          if (response.body) {
            Toast(JSON.parse(response.body).content)
          }
        });
        that.timer2 = setInterval('doTime2()', 1000);
      }, (err) => {
        // 连接发生错误时的处理函数
        console.log('失败')
        that.connect(that.wsPatientId,that.wsOrderSn);
      });
    },
    //注意/app前缀，/app/send映射到后端chatController
    send(text, pics) {
      var that = this;
      var message = {};
      message.ordersn = that.ordersn;
      message.text = text;
      message.senderId = localStorage.getItem(ACCESS_TOKEN);
      message.senderType = 1;
      message.pics = pics;
      message.roomId = that.ordersn;
      this.stompClient.send("/app/chat", {}, JSON.stringify(message));
      that.value = "";
    },
    offline() {
      this.stompClient.disconnect();
      this.info += "<br>成功退出系统";
    },
    //注意这里不用前缀
    leave(orderSn) {
      this.stompClient.unsubscribe('/chats/' + orderSn)
    },
    //-----------------------------------
    onSelect(item) {
      this.dDay = item.name;
    },
    toCall() {
      window.location.href = "tel:17034684797";
    },
    loadData(boolInit) {
      let that = this;
      //发送 post 请求
      this.$nextTick(() => {
        postAction('/web/api/doctor/orderChat', {
          "ordersn": that.ordersn,
          "count": this.list.length
        }).then(function (response) {
          console.log(response.result);
          const oldCount = that.list.length;
          const newCount = response.result.data ? response.result.data.length : 0;
          that.doctorName = response.result.doctorName;
          that.doctorAvatar = response.result.doctor;
          that.patientAvatar = response.result.patient;
          that.orderFlag = response.result.orderFlag;
          that.lastTime = response.result.lastTime;
          that.canSend = response.result.canSend;
          that.orderType = response.result.orderType;
          that.expectCallFlag = response.result.expectCallFlag;
          console.log("lastTime:"+that.lastTime)
          // 异步更新数据
          for (let i = that.list.length; i < newCount; i++) {
            if (response.result.data[i]) {
              that.list.push(response.result.data[i]);
            }
          }
          if (oldCount < newCount) {
            that.scrollToBottom();
          }
          if (that.orderFlag != 30) {
            // clearInterval(that.timer);
            // that.timer = null;
          }
          if(boolInit) {
            that.wsPatientId=response.result.patientId
            that.wsOrderSn=response.result.orderSn
            that.connect(response.result.doctorId, response.result.orderSn)
          }
        })
      })
    },
    sendText(text, pics) {
      let that = this;
      if(text==''&&pics==''){
        Toast('不能发送空信息！')
        return;
      }
      if (this.orderFlag > 30) {
        postAction('/web/api/doctor/orderChatCheck', {
          "ordersn": that.ordersn,
        }).then(function (response) {
          console.log(response.result);
          if (response.result.code == "0000") {
            Dialog.confirm({
              message: response.result.info + "，还要继续发送吗？",
            }).then(() => {
              that.send(text, pics);
            }).catch(() => {
            });
          }
        })
      } else if (this.orderFlag == 30) {
        //发送 post 请求
        that.send(text, pics);
      }
    },
    scrollToBottom: function () {
      this.$nextTick(() => {
        const container = this.$el.querySelector("#new_message");
        console.log(container.scrollHeight)
        document.documentElement.scrollTop = container.scrollHeight;
        console.log(document.documentElement.scrollTop)

      });
    },
    onClickLeft() {
    },
    getPatientUserInfo() {
      let that = this;
      //发送 post 请求
      this.$nextTick(() => {
        postAction('/web/api/patient/user/getPatientUserByOrdersn', {
          "ordersn": that.ordersn
        }).then(function (response) {
          console.log(response.result);
          that.patientUser = response.result.data
        })
      })
    },
    dOk() {
      let that = this;
      this.$nextTick(() => {
        postAction('/web/api/doctor/acceptCall', {
          "ordersn": that.ordersn,
          "dDay": that.dDay,
          "currentTime": that.currentTime
        }).then(function () {
          Toast('预约成功，请不要忘记拨打电话哦')
          that.loadData();
        })
      })
    },
    onClickRight() {
      let that = this;
      if (this.orderFlag == 20) {
        if (that.orderType == 2) {
          that.dshow = true;
        } else {
          postAction('/web/api/doctor/acceptOrder', {
            "ordersn": that.ordersn
          }).then(function (response) {
            Toast(response.content)
            window.location.href = window.location.href;
          })
        }
      } else if (this.orderFlag == 30) {
        if (that.orderType == 2) {
          that.dshow = true;
        }
      }
    },
    showImgs(imgs) {
      ImagePreview({
        images: [imgs
        ],
        maxZoom:10,
        closeable: true,
      });
    },
    types(tt) {
      if (tt == 0) {
        return "状态：待付款"
      } else if (tt == 20) {
        return "接单"
      } else if (tt == 30) {
        if (this.orderType == 2) {
          return "调整时间"
        } else {
          return "状态：问诊中"
        }
      } else if (tt == 40) {
        return "状态：待评价"
      } else if (tt == 50) {
        return "状态：已评价"
      } else if (tt == 100) {
        return "状态：已取消"
      }
    },
    dataURLtoFile(dataurl, filename) { // 将base64转换为file文件
      if (dataurl.indexOf(',') >= 0) {
        let arr = dataurl.split(',')
        let mime = arr[0].match(/:(.*?);/)[1]
        let bstr = atob(arr[1])
        let n = bstr.length
        let u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], filename, {type: mime})
      }
    },
    beforeRead(file) { // 上传之前检测图片类似返回true和false会影响到onRead函数
      let regex = /(.jpg|.jpeg|.png|.bmp)$/
      if (!regex.test(file.type)) {
        Toast('图片格式不支持上传')
        return false
      } else {
        return true
      }
    },
    onRead(file) { // 上传图片
      // 大于10MB的图片都缩小像素上传
      const that = this;
      if (file.file.size > 10485760) {
        let canvas = document.createElement('canvas') // 创建Canvas对象(画布)
        let context = canvas.getContext('2d')
        let img = new Image()
        img.src = file.content // 指定图片的DataURL(图片的base64编码数据)
        img.onload = () => {
          canvas.width = 400
          canvas.height = 300
          context.drawImage(img, 0, 0, 400, 300)
          file.content = canvas.toDataURL(file.file.type, 0.92) // 0.92为默认压缩质量
          let files = this.dataURLtoFile(file.content, file.file.name)
          const data = new FormData()
          data.append('file', files)
          data.append('type', 'chat')
          postActionMultipart("publicinterface/getImgurlbyfile?imgtype=chat", {data}).then(res => {
            that.sendText("", this.$getFileAccessHttpUrl(res.content))
          })
        }
      } else { //小于10M直接上传
        const data = new FormData()
        data.append('file', file.file)
        data.append('type', 'chat')
        postActionMultipart("publicinterface/getImgurlbyfile?imgtype=chat", data).then(res => {
          that.sendText("", this.$getFileAccessHttpUrl(res.content))
        })
      }
    },
    uploadImg() {
      const that = this;
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['original', 'compressed'], // 能够指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 能够指定来源是相冊还是相机，默认二者都有
        success: function (res) {
          var localIds = res.localIds; // 返回选定照片的本地ID列表，localId能够作为img标签的src属性显示图片
          that.upImg(localIds);
        }
      });
    },
    upImg(localIds){
      const that = this;
      wx.uploadImage({
        localId: localIds.toString(), // 须要上传的图片的本地ID，由chooseImage接口获得
        isShowProgressTips: 1, // 默觉得1，显示运行进度提示
        success: function (res) {
          var serverId = res.serverId; // 返回图片的服务器端ID
          postAction('publicinterface/downloadMedia', {
            "mediaId": serverId,
          }).then(function (res) {
            that.sendText("", that.$getFileAccessHttpUrl(res.content))
          }).finally(() => {
          })
        },error(e){
          alert(e)
        }
      });
    }
  }
};
</script>

<style scoped>
@import '/css/chat.css';

.van-field__word-limit {
  display: none;
}

.van-nav-bar {
  position: fixed;
  width: 100%;
}
</style>
