<template>
    <div>
        <van-form>
            <div class="order-card-title">意见反馈</div>
            <van-field
                    v-model="message"
                    rows="6"
                    autosize
                    label=""
                    type="textarea"
                    placeholder="请留下您宝贵的意见和建议！"
                    maxlength="500"
                    show-word-limit
            />
            <div style="margin: 16px;">
                <van-button round block type="primary" @click="onSubmit()">
                    提交
                </van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        Button, Field,
        List, Form, Radio, RadioGroup, Calendar, Uploader, SubmitBar,
        Tag, Step, Steps
    } from 'vant';
    import {Toast} from 'vant';
    import {postAction} from '@/api/manage';

    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tag.name]: Tag,
            [Step.name]: Step,
            [Field.name]: Field,
            [Form.name]: Form,
            [Radio.name]: Radio,
            [Calendar.name]: Calendar,
            [RadioGroup.name]: RadioGroup,
            [Uploader.name]: Uploader,
            [SubmitBar.name]: SubmitBar,
            [Steps.name]: Steps,
        },

        data() {
            return {
                message: '',
            };
        },
        methods: {
            onSubmit() {
                let that = this;
                console.log(this.message);
                if (this.message == "") {
                    Toast("请留下您宝贵的意见");
                    return
                }
                if (this.message.length < 20) {
                    Toast("意见不能少于20字哦！");
                    return
                }
                postAction('/web/api/user/feedback', {
                    "content": that.message
                }).then(function (response) {
                    that.message = "";
                    Toast(response.content);
                })
            }
        }
    };
</script>

<style lang="less" scoped>
    .order-card-title {
        padding: 13px 0;
        font-size: 18px;
        text-align: center;
        font-weight: 500;
        color: #000;
    }

    .image-upload-tile {
        font-size: 16px;
        padding-top: 10px;
        color: #666;
        background-color: white;
        padding-left: 10px;
    }

    .card-tip {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 15px;
        font-size: 14px;
        color: #aaa;
    }

    .protocol-url {
        font-size: 14px;
        color: #00b4a4;
        text-decoration: none;
    }

    .van-step__title {
        font-weight: bold;
    }
</style>
