<template>
    <div>
        <van-nav-bar>
            <div slot="left">
                <div style="margin-left: 1px;">
                    <span style="padding-top: 10px">欢迎注册</span>
                </div>
            </div>
        </van-nav-bar>
        <van-field
                v-model="mobile"
                name="amount"
                left-icon="user-o"
                label="手机号"
                placeholder="请输入手机号"
        />
        <van-field
                v-model="code"
                left-icon="coupon-o"
                name="amount"
                label="验证码"
                placeholder="请输入验证码"
        >
            <template #button>
                <van-button size="small" type="primary" @click="sendCode" :disabled="codedisabled">{{codeTip}}
                </van-button>
            </template>
        </van-field>
        <div style="margin-left: 10px;margin-top: 10px;font-size: 9pt">
            <van-checkbox v-model="checked" shape="square" checked-color="#07c160">我已经认真阅读，理解并同意
                <router-link :to="{ name: 'doctor/agreement'}">《用户注册协议》</router-link>
            </van-checkbox>
        </div>
        <div style="margin: 16px;">
            <van-button round block type="primary" style="background-color: #07c160" @click="toSub()"
                        :disabled="!checked">
                注册
            </van-button>
        </div>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        Button, Field,
        List, Form, SubmitBar,
        Tag, NavBar, Checkbox, CheckboxGroup, Toast
    } from 'vant';
    import Vue from 'vue';
    import {ACCESS_TOKEN} from '@/store/mutation-types'
    import {postAction} from '@/api/manage';

    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tag.name]: Tag, [Toast.name]: Toast,
            [Field.name]: Field,
            [Form.name]: Form, [NavBar.name]: NavBar,
            [SubmitBar.name]: SubmitBar, [CheckboxGroup.name]: CheckboxGroup, [Checkbox.name]: Checkbox,
        },

        data() {
            return {
                mobile: '',
                code: '',
                checked: false,
                codeTip: '发送验证码',
                codedisabled: false,
                openId: this.$route.query.openId,
                urls: decodeURI(this.$route.query.urls)
            };
        },
        methods: {
            onLoad() {
            },
            sendCode() {
                const that = this;
                if (this.mobile == "") {
                    Toast('请填写手机号');
                    return
                } else {
                    postAction("/web/api/user/sendMsg", {mobile: this.mobile}).then(res => {
                        console.log(res)
                        Toast("发送成功");
                        let second = 60;
                        that.codedisabled = true;
                        const timer = setInterval(() => {
                            second--;
                            if (second) {
                                that.codeTip = "剩余" + second + "秒";
                            } else {
                                clearInterval(timer);
                                that.codeTip = "发送验证码";
                                that.codedisabled = false;
                            }
                        }, 1000);
                    }).finally(() => {
                    })
                }
            },
            toSub() {
                if (this.mobile == "") {
                    Toast('请填写手机号');
                    return
                } else if (this.code == "") {
                    Toast('请填写验证码');
                    return
                } else if (this.openId == "") {
                    Toast('获取认证失败，请重试');
                    return
                }

                const that = this;
                postAction("/web/api/user/register", {
                    verCode: this.code, openId: this.openId, typeFlag: 0, mobile: this.mobile
                }).then(res => {
                    console.log(res)
                    localStorage.setItem(ACCESS_TOKEN, res.result.token)
                    if (res.result.userFlag == 0) {
                        localStorage.setItem(ACCESS_TOKEN, res.result.token)
                        if (that.urls.indexOf("/doctor/") > -1) {
                            that.$router.push({name: 'patient/index'});
                        } else {
                            if (that.urls == undefined || that.urls == ""||that.urls == "undefined") {
                                that.$router.push({name: 'patient/index'});
                            } else {
                                window.location.href = that.urls;
                            }
                        }
                    } else if (res.result.userFlag == 1) {
                        localStorage.setItem(ACCESS_TOKEN, res.result.token)
                        //医生
                        if (that.urls.indexOf("/doctor/") > -1) {
                            window.location.href = that.urls;
                        } else {
                            if (that.urls == undefined || that.urls == ""||that.urls == "undefined") {
                              if (this.urls.indexOf("/xingqiu/") > -1) {
                                window.location.href = that.urls;
                              }else{
                                that.$router.push({name: 'doctor/userCenter'});
                              }
                            } else {
                                window.location.href = that.urls;
                            }
                        }
                    } else {
                        if (that.urls.indexOf("/doctor/") > -1) {
                            //医生
                            that.$router.push({name: 'doctor/register', query: {openId: res.result.openId}});
                        } else {
                            that.$router.push({name: 'patient/register', query: {openId: res.result.openId}});
                        }
                    }
                }).finally(() => {
                })
            },
        }
    };
</script>

<style lang="less" scoped>
    .van-card__bottom {
        padding-bottom: 20px;
    }

    .van-doc-demo-block__title {
        margin: 0;
        padding: 7px 14px 0px;
        color: rgba(69, 90, 100, 0.6);
        font-weight: normal;
        font-size: 12px;
    }
</style>
