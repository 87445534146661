<template>
    <div>
        <div>
            <van-list
                    v-model="loading"
                    :finished="finished"
                    finished-text="没有更多了"
                    @load="onLoad"
            >
                <van-empty description="" v-if="this.list.length==0"/>
                <div class="page-consult--item"
                     v-for="item in list"
                     :key="item.id"
                     @click="goDoctorInfo(item.doctorId)">

                    <img :src="kyz.$getFileAccessHttpUrl(item.avatar)"
                         class="avatar x-img-fadein" normal="loaded">
                    <div class="info">
                        <h3>
                            <em>{{item.doctorName}}</em>
                            <span class="doctorLevel">{{kyz.$sysDictFilter(item.postionFlag, 'postionFlag')}}</span>
                            <span class="doctorLevel"> {{kyz.$sysDictFilter(item.hospDepartFlag, 'hospDepartFlag')}}</span>
                        </h3>
                        <p class="hosp">
                            <van-tag type="success" size="medium" v-if="item.a3Flag!=0">{{kyz.$sysDictFilter(item.a3Flag, 'a3Flag')}}</van-tag>
                            {{item.hospitalName}}
                        </p>
                        <p class="good">擅长：{{item.goodAt}}</p>
                        <div class="price">
                            <p>¥{{item.price}}</p>
                            <span>问诊量：<em>{{item.diagnosisCount}}</em></span>
                            <span style="margin-left: 10px">好评率：<em>{{((item.commitSourceTotal / item.commitCount / 10).toFixed(2) * 100||'0') + '%'}}</em></span>
                        </div>
                    </div>
                </div>
            </van-list>
        </div>
        <br><br>
        <Footer :active="active"></Footer>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        Search,
        DropdownMenu,
        DropdownItem,
        Button,
        List,
        Tag,
        Grid,
        GridItem,
        Image
    } from 'vant';
    import {postAction} from '@/api/manage';
    import Footer from '@/view/patient/index/footer'

    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tag.name]: Tag,
            [Grid.name]: Grid,
            [GridItem.name]: GridItem,
            [Image.name]: Image,
            Footer,
            [Search.name]: Search
        },

        data() {
            return {
                active: 9,
                orderType: 0,
                hospitalLevel: 0,
                list: [],
                pageNumber: 1,
                loading: false,
                finished: false,
                kyz: this,
            };
        },
        methods: {
            onLoad() {
                this.loadData();
            },
            loadData() {
                let that = this;
                //发送 post 请求
                this.$nextTick(() => {
                    postAction('/web/api/patient/followList', {
                        "pageNo": that.pageNumber
                    }).then(function (response) {
                        console.log(response.result);
                        // 异步更新数据
                        for (let i = 0; i < 10; i++) {
                            if (response.result.data.records[i]) {
                                that.list.push(response.result.data.records[i]);
                            }
                        }
                        that.pageNumber = that.pageNumber + 1;
                        // 加载状态结束
                        that.loading = false;
                        // 数据全部加载完成
                        if (that.list.length >= response.result.data.total) {
                            that.finished = true;
                        }
                    })
                })
            },
            goDoctorInfo(doctorId) {
                this.$router.push({name: 'doctorInfo', query: {doctorId: doctorId}});
            }
        },
        watch: {}
    };
</script>

<style lang="less" scoped>
    .erke {
        color: #48cf91
    }

    .xinli {
        color: #c62520
    }

    .fuchan {
        color: #dba2bf
    }

    em {
        font-weight: bold;
        font-style: normal
    }

    .selectChecked span {
        display: block;
        float: left;
        line-height: 26px;
        text-align: center;
        margin-right: 10px;
        margin-bottom: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .selectChecked span input:checked + label {
        background: #3f86ff;
        color: #fff;
    }

    .selectChecked span input {
        visibility: hidden;
        width: 0;
        height: 0;
    }

    .selectChecked span label {
        display: inline-block;
        min-width: 56px;
        height: 26px;
        padding: 0 12px;
        border-radius: 12px;
        background: #f5f6f6;
        color: #83889a;
    }

    .selectTitle {
        line-height: 22px;
        margin-bottom: 12px;
        font-size: 14px;
        color: #28354c;
    }

    .selectTitleDiv {
        margin: 10px 10px 10px 10px
    }

    .page-consult--item {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: .85333333rem .712rem;
        border-bottom: 1px solid #e0e1e6;
    }

    .page-consult--item .avatar {
        display: block;
        width: 3.048rem;
        height: 3.048rem;
        margin-right: .512rem;
        margin-top: .9rem;
        border-radius: 50%;
    }

    .page-consult--item .info {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        width: 1%;
        margin-left: 0.4rem;
    }

    .page-consult--item .info p.hosp {
        margin-bottom: .256rem;
        color: #28354c;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 1.0rem;
        margin-top: 0.5rem;
    }

    .page-consult--item .info p {
        font-size: .59733333rem;
        color: #61687c;
    }

    .page-consult--item .info h3 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: .256rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: .59733333rem;
        color: #28354c;
    }

    .page-consult--item .info p.num {
        margin-bottom: .42666667rem;
        color: #61687c;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .page-consult--item .info p.good {
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        font-size: 0.9rem;
    }

    .page-consult--item .info .price {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        margin-top: .42666667rem;
        font-size: .89733333rem;
        color: #28354c;
    }

    .page-consult--item .info .price p {
        -webkit-box-flex: 1;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        font-size: .89733333rem;
        font-weight: 700;
        color: #ff695c;
    }

    .page-consult--item .info h3 em {
        margin-right: .29866667rem;
        font-size: 1.18266667rem;
        font-weight: 700;
        font-style: normal;
    }

    .doctorLevel {
        font-weight: normal;
        margin-left: 10px;
        font-size: 0.9rem;
    }
</style>
