<template>
  <div v-if="msgData.message">
    <div style="border:1px solid #f3f2f2;background-color: white">
      <div style="margin: 10px 10px 0px 10px;">
        <van-row>
          <van-col span="3">
            <van-image
                style="margin-top: 5px"
                radius="5"
                width="35px"
                height="35px"
                :src="kyz.$getFileAccessHttpUrl(msgData.message.askAvatar)"
            />
          </van-col>
          <van-col span="16" style="font-size: 12pt;color:#6d6c6c;margin-left: -8px">
            <div>{{ msgData.message.askName }}</div>
            <div style="color: gray;font-size: 9pt">{{ msgData.message.createTime.substring(0, 10) }} 提问</div>
          </van-col>
        </van-row>
      </div>
      <div style="margin: 20px 10px 20px 10px;font-weight: bold;font-size: 13pt">
        {{ msgData.message.content }}
      </div>
    </div>
    <div style="margin-top: 6px">
      <div style="border:1px solid #f3f2f2;background-color: white">

        <div style="margin: 10px 10px 0px 10px;"
             v-for="item in msgData.msgDetailDoctorList"
             :key="item.id">
          <van-row>
            <van-col span="3">
              <van-image
                  style="margin-top: 5px"
                  radius="5"
                  width="35px"
                  height="35px"
                  :src="kyz.$getFileAccessHttpUrl(item.ansAvatar)"
              />
            </van-col>
            <van-col span="16" style="font-size: 12pt;color:#6d6c6c;margin-left: -8px">
              <div>{{ item.ansName }}</div>
              <div style="color: gray;font-size: 9pt">{{ item.createTime.substring(0, 10) }} 回答了问题</div>
            </van-col>
          </van-row>
          <div style="margin: 5px 10px 5px 10px;font-weight: bold;font-size: 14pt;color: #4f998a" v-if="item.title">
            {{ item.title }}
          </div>
          <div style="margin: 5px 10px 5px 10px;font-size: 11pt;">
            {{ item.content }}
          </div>
        </div>
        <div style="margin: 15px 10px 5px 10px;font-size: 10pt;color: gray">
          {{ msgData.message.readCount }}人看过
        </div>
      </div>
    </div>

    <div style="margin-top: 6px" v-if="msgData.msgDetailPatientList&& msgData.msgDetailPatientList.length>0">
      <div style="border:1px solid #f3f2f2;background-color: white">
        <div style="margin: 10px 10px 0px 10px;font-weight: bold;font-size: 12pt;color: #3b3a3a">
          留言({{ msgData.msgDetailPatientList.length }})
        </div>
        <div style="margin: 10px 10px 0px 10px;" v-for="item in msgData.msgDetailPatientList"
             :key="item.id">
          <van-row>
            <van-col span="2">
              <van-image
                  style="margin-top: 2px"
                  radius="5"
                  width="25px"
                  height="25px"
                  :src="kyz.$getFileAccessHttpUrl(item.ansAvatar)"
              />
            </van-col>
            <van-col span="16" style="font-size: 10pt;color:#6d6c6c;margin-left: 0px">
              <div>{{ item.ansName }}</div>
            </van-col>
          </van-row>
          <div style="margin: 0px 10px 5px 40px;font-size: 10pt;">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="doctorType!=1">
      <div v-if="userType==''||userType=='0'">
        <van-goods-action>
          <van-goods-action-icon icon="wap-home-o" text="首页" @click="goIndex"/>
          <van-goods-action-button
              style="background-color: rgb(42, 159, 147);color: white"
              text="留 言"
              @click="onClickButton"
          />
        </van-goods-action>
        <van-popup v-model="show1" position="bottom" round>
          <div style="margin: 10px 10px 10px 10px">
            <van-field
                v-model="content"
                rows="4"
                autosize
                type="textarea"
                maxlength="50"
                placeholder="内容（0-50字）"
                show-word-limit
            />
            <van-button type="primary" @click="addMessage" block round>提交</van-button>
          </div>
        </van-popup>
        <!--        <van-row>-->
        <!--          <van-col span="18">-->
        <!--            <van-field-->
        <!--                v-model="content"-->
        <!--                rows="2"-->
        <!--                autosize-->
        <!--                type="textarea"-->
        <!--                maxlength="50"-->
        <!--                placeholder="我要留言（0-50字）"-->
        <!--                show-word-limit-->
        <!--            />-->
        <!--          </van-col>-->
        <!--          <van-col span="6" style="font-size: 10pt;color:#6d6c6c;margin-left: 0px">-->
        <!--            <van-button type="primary" @click="addMessage">发送</van-button>-->
        <!--          </van-col>-->
        <!--        </van-row>-->
      </div>
      <div v-else>
        <van-goods-action>
          <van-goods-action-icon icon="wap-home-o" text="首页" @click="goIndex"/>
          <van-goods-action-button
              style="background-color: rgb(42, 159, 147);color: white"
              text="回 答"
              @click="showPopup"
          />
        </van-goods-action>

        <div style="margin: 0px 10px 0px 10px">
          <!--          <van-button type="primary" @click="showPopup" block round>我要回答</van-button>-->
          <van-popup v-model="show" position="bottom" round>
            <div style="margin: 10px 10px 10px 10px">
              <van-field
                  v-model="title"
                  autosize
                  placeholder="建议（尽量短）"
                  show-word-limit
              />
              <van-field
                  v-model="content"
                  rows="4"
                  autosize
                  type="textarea"
                  maxlength="500"
                  placeholder="内容（0-500字）"
                  show-word-limit
              />
              <van-button type="primary" @click="addMessage" block round>提交</van-button>
            </div>
          </van-popup>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Cell,
  CellGroup,
  Field,
  Dialog,
  Button,
  Divider,
  Tabbar,
  TabbarItem,
  Popup,
  GoodsAction,
  GoodsActionIcon,
  GoodsActionButton
} from 'vant';
import {postAction} from '@/api/manage';
import {Toast} from 'vant';
import wx from 'weixin-js-sdk'

export default {
  components: {
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [Popup.name]: Popup,
    [Divider.name]: Divider,
    [GoodsAction.name]: GoodsAction,
    [GoodsActionIcon.name]: GoodsActionIcon,
    [GoodsActionButton.name]: GoodsActionButton,
    [Dialog.Component.name]: Dialog.Component,
    [CellGroup.name]: CellGroup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem
  },
  data() {
    return {
      active: 4,
      userInfo: {},
      msgId: this.$route.query.msgId,
      msgData: {},
      doctorType: -1,
      vipDuiShow: false,
      kyz: this,
      title: '',
      content: '',
      userType: '',
      show: false,
      show1: false
    };
  },
  mounted() {
    this.loadUserData();
    this.loadData();

  },
  methods: {
    goIndex() {
      this.$router.push({name: 'xingqiu/index'});
    },
    loadUserData() {
      let that = this;
      //发送 post 请求
      this.$nextTick(() => {
        postAction('/web/api/xingqiu/getUserInfo', {}).then(function (response) {
          console.log(response.result);
          that.doctorType = response.result.doctorType;
        })
      })
    },
    loadData() {
      let that = this;
      postAction('/web/api/xingqiu/messageInfo', {msgId: that.msgId}).then(function (response) {
        console.log(response.result);
        that.userType = response.result.userType
        that.msgData = response.result
        // 微信分享配置
        wx.ready(function () {
          wx.updateTimelineShareData({
            title: '闻诚星球-' + that.msgData.message.content, // 分享标题
            link: 'http://wap.wenchengjiankang.com/xingqiu/questionInfo?msgId=' + that.msgId, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'http://wap.wenchengjiankang.com/img/logo.jpg', // 分享图标
            success: function () {
              // 设置成功
            }
          })
          wx.updateAppMessageShareData({
            title: '闻诚星球-' + that.msgData.message.content, // 分享标题
            link: 'http://wap.wenchengjiankang.com/xingqiu/questionInfo?msgId=' + that.msgId, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: 'http://wap.wenchengjiankang.com/img/logo.jpg', // 分享图标
            desc: that.msgData.message.content, // 分享描述
            success: function () {
              // 设置成功
            }
          })
        })
      })
    },
    addMessage() {
      if (this.content == '' || this.content.trim() == '') {
        Toast("请输入内容信息");
        return
      }
      let that = this;
      postAction('/web/api/xingqiu/addMessage', {
        msgId: that.msgId,
        content: this.content,
        title: this.title
      }).then(function (response) {
        console.log(response.result);
        that.title = ''
        that.content = ''
        that.show = false
        that.show1 = false
        that.loadData();
      })
    },
    showPopup() {
      this.show = true
    },
    onClickButton() {
      this.show1 = true
    }
  }
};
</script>

<style lang="less">
.xuanfu {
  left: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 100;
  height: 80px;
  background-color: white;
  text-align: center;
  font-size: 15pt;
  line-height: 70px;
  color: #5889de;
}
</style>
