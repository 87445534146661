<template>
    <div>
        <van-nav-bar
                right-text="提现"
                @click-left="onClickLeft"
                @click-right="onClickRight"
        >
            <div slot="left">
                <div>
                    <span style="padding-top: -10px">我的佣金:{{balanceLast}}</span>
                </div>
            </div>
        </van-nav-bar>

        <van-tabs v-model="active" style="top: 46px" @click="onClick">
            <van-tab title="佣金记录">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <van-card
                            v-for="item in list" :key="item.id"
                            :price="item.amount.toFixed(2)"
                            :title="times(item.createTime)"
                            :desc="'订单编号：'+item.ordersn"
                    >
                        <template #footer>
                            <span>获取方式：{{kyz.$sysDictFilter(item.sourceType,'sourceType')}}}</span>
                        </template>
                    </van-card>
                </van-list>
            </van-tab>
            <van-tab title="提现记录">
                <van-list
                        v-model="loading"
                        :finished="finished2"
                        finished-text="没有更多了"
                        @load="onLoad2"
                >
                    <van-card
                            v-for="item in list2" :key="item.id"
                            :price="item.amount.toFixed(2)"
                            :title="times2(item.createTime)"
                            :desc="'申请编号：'+item.ordersn"
                    >
                        <template #footer>
                            <span>审核状态：{{kyz.$sysDictFilter(item.verifyFlag, 'verifyFlag')}}</span>
                        </template>
                    </van-card>
                </van-list>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar, NavBar,
        TabbarItem,
        Button, Field,
        List, Form, SubmitBar,
        Tag, Step, Steps, Tab, Tabs, Card
    } from 'vant';
    import {postAction} from '@/api/manage';

    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tag.name]: Tag,
            [Step.name]: Step,
            [Field.name]: Field,
            [Form.name]: Form, [NavBar.name]: NavBar,
            [SubmitBar.name]: SubmitBar,
            [Steps.name]: Steps, [Tab.name]: Tab, [Tabs.name]: Tabs, [Card.name]: Card,
        },

        data() {
            return {
                active: 0,
                list: [],
                list2: [],
                loading: false,
                finished: false,
                finished2: false,
                pageNumber: 1,
                balanceLast: 0,
                urls: "balanceList",
                kyz: this,
            };
        },
        mounted() {
            this.getBalance();
        },
        methods: {
            onLoad() {
                this.loadData();
            },
            getBalance() {
                let that = this;
                //发送 post 请求
                postAction('/web/api/doctor/userInfo', {}).then(function (response) {
                    console.log(response.result);
                    that.balanceLast = response.result.balanceLast;
                }).finally(() => {
                })
            },
            onLoad2() {
                this.loadData2();
            },
            loadData() {
                let that = this;
                //发送 post 请求
                this.$nextTick(() => {
                    postAction('/web/api/doctor/balanceList', {
                        "pageNo": that.pageNumber,
                        "type": 0
                    }).then(function (response) {
                        console.log(response.result);
                        // 异步更新数据
                        for (let i = 0; i < 10; i++) {
                            if (response.result.data.records[i]) {
                                that.list.push(response.result.data.records[i]);
                            }
                        }
                        that.pageNumber = that.pageNumber + 1;
                        // 加载状态结束
                        that.loading = false;
                        // 数据全部加载完成
                        if (that.list.length >= response.result.data.total) {
                            that.finished = true;
                        }
                    })
                })
            },
            loadData2() {
                let that = this;
                //发送 post 请求
                this.$nextTick(() => {
                    postAction('/web/api/doctor/cashList', {
                        "pageNo": that.pageNumber,
                        "type": 0
                    }).then(function (response) {
                        console.log(response.result);
                        // 异步更新数据
                        for (let i = 0; i < 10; i++) {
                            if (response.result.data.records[i]) {
                                that.list2.push(response.result.data.records[i]);
                            }
                        }
                        that.pageNumber = that.pageNumber + 1;
                        // 加载状态结束
                        that.loading = false;
                        // 数据全部加载完成
                        if (that.list2.length >= response.result.data.total) {
                            that.finished2 = true;
                        }
                    })
                })
            },
            onClickLeft() {
            },
            onClickRight() {
                this.$router.push({name: 'doctor/myCashOut'});
            },
            onClick() {
                this.pageNumber = 1;
            },
            times(t) {
                return "佣金日期：" + this.$formatDate(t, 'yyyy-MM-dd');
            },
            times2(t) {
                return "申请日期：" + this.$formatDate(t, 'yyyy-MM-dd');
            }
        }
    };
</script>

<style lang="less" scoped>
    .van-card__bottom {
        padding-bottom: 20px;
    }

    .van-card__desc {
        margin-top: 6px;
    }

    .van-card__title {
        font-size: 11pt;
    }

    .van-card__price {
        font-size: 13pt !important;
    }

    .van-card__price-integer {
        font-size: 13pt !important;
    }

    .van-nav-bar {
        position: fixed;
        width: 100%;
    }
</style>
