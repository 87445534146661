<template>
  <div>
    <div style="margin: 15px 15px 15px 15px ">
      <van-field
          v-model="message"
          rows="2"
          autosize
          type="textarea"
          maxlength="200"
          placeholder="请输入问题（5-200字）"
          show-word-limit
      />
      <van-button type="primary" block style="margin-top: 20px" @click="toAsk">提交问题</van-button>
    </div>
    <van-tabbar v-model="active">
      <van-tabbar-item icon="home-o"  @click="goIndex()">推荐</van-tabbar-item>
      <van-tabbar-item icon="user-o" @click="goMy()">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import {Cell, CellGroup, Field, Dialog, Button, Search, Divider, Tabbar, TabbarItem} from 'vant';
import {postAction} from '@/api/manage';
import {Toast} from 'vant';

export default {
  components: {
    [Cell.name]: Cell,
    [Button.name]: Button,
    [Field.name]: Field,
    [Dialog.name]: Dialog,
    [Search.name]: Search, [Divider.name]: Divider,
    [Dialog.Component.name]: Dialog.Component,
    [CellGroup.name]: CellGroup, [Tabbar.name]: Tabbar, [TabbarItem.name]: TabbarItem
  },
  data() {
    return {
      active: -1,
      userInfo: {},
      message: '',
      ordersStatus: {},
      vipDuiShow: false,
      show: true
    };
  },
  mounted() {
    postAction('/web/api/xingqiu/askCheck', {}).then(function (response) {
    })
  },
  methods: {
    goIndex() {
      this.$router.push({name: 'xingqiu/index'});
    },
    goMy() {
      this.$router.push({name: 'xingqiu/myQuestion'});
    },
    toAsk() {
      let that = this;
      postAction('/web/api/xingqiu/addAsk', {content:this.message}).then(function (response) {
        if(response.code=="0000"){
          Toast("提交成功！");
          that.$router.push({name: 'xingqiu/index'});
        }
      })
    },
  }
};
</script>

<style lang="less">
</style>
