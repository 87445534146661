<template>
    <div>
        <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div class="evaluationitem-mqJ-N" v-for="item in list" :key="item.id">
                <div class="line"></div>
                <div class="evaluationitem-3LfGS">
                    <div class="evaluationitem-3S6Wr">{{kyz.$parseStringToStar(item.patientName)}}</div>
                    <div class="evaluationitem-1dNw_">
                        <div value="5" class="next-rating next-rating-small">
                            <div class="next-rating-base">
                                <div class="next-rating-underlay">
                                    <van-rate v-model="item.star" allow-half void-icon="star" void-color="#eee"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="evaluationitem-33h8R">{{item.createTime|substrs}}</div>
                </div>
                <div class="evaluationitem-AID8H">{{item.content}}</div>
            </div>
        </van-list>
        <br><br><br>
        <Footer :active="active"></Footer>
    </div>
</template>

<script>
    import {
        Cell,
        CellGroup,
        Tabbar,
        TabbarItem,
        DropdownMenu,
        DropdownItem,
        Button,
        List,
        Tag,
        Rate
    } from 'vant';
    import Footer from '@/view/patient/index/footer'
    import {postAction} from '@/api/manage';

    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [Tabbar.name]: Tabbar,
            [TabbarItem.name]: TabbarItem,
            Footer,
            [DropdownMenu.name]: DropdownMenu,
            [DropdownItem.name]: DropdownItem,
            [Button.name]: Button,
            [List.name]: List,
            [Tag.name]: Tag,
            [Rate.name]: Rate,
        },

        data() {
            return {
                searchInfo: '',
                option: [
                    {text: '综合排序', value: 0},
                    {text: '按咨询量', value: 1},
                    {text: '按价格升序', value: 3},
                    {text: '按价格降序', value: 4},
                ],
                list: [],
                loading: false,
                finished: false,
                pageNumber: 1,
                doctorId: this.$route.query.doctorId,
                kyz: this
            };
        },
        mounted() {
        },
        methods: {
            onLoad() {
                let that = this;
                //发送 post 请求
                this.$nextTick(() => {
                    postAction('/web/api/index/doctorCommentList', {
                        "pageNo": that.pageNumber, "searchName": this.searchInfo, "doctorId": this.doctorId
                    }).then(function (response) {
                        console.log(response.result);
                        // 异步更新数据
                        for (let i = 0; i < 10; i++) {
                            if (response.result.data.records[i]) {
                                that.list.push(response.result.data.records[i]);
                            }
                        }
                        that.pageNumber = that.pageNumber + 1;
                        // 加载状态结束
                        that.loading = false;
                        // 数据全部加载完成
                        if (that.list.length >= response.result.data.total) {
                            that.finished = true;
                        }
                    })
                })
            }
        }
    };
</script>

<style lang="less" scoped>
    .evaluationitem-mqJ-N {
        padding: 0 13px 22px;
        text-align: left;
        background-color: #fff;
    }

    .line {
        width: 100%;
        height: 1px;
        background-color: #f4f4f4;
        -webkit-transform: scaleY(.5);
        transform: scaleY(.5);
    }

    .evaluationitem-mqJ-N .evaluationitem-3LfGS {
        position: relative;
        height: 14px;
        margin-top: 22px;
    }

    .evaluationitem-mqJ-N .evaluationitem-AID8H {
        margin-top: 15px;
        line-height: 24px;
        color: #666;
        font-size: 14px;
        word-wrap: break-word;
    }

    .evaluationitem-mqJ-N .evaluationitem-3LfGS .evaluationitem-3S6Wr {
        color: #aaa;
        font-size: 14px;
        display: inline-block;
    }

    .evaluationitem-mqJ-N .evaluationitem-3LfGS .evaluationitem-1dNw_ {
        margin-left: 8px;
        display: inline-block;
    }

    .evaluationitem-mqJ-N .evaluationitem-3LfGS .evaluationitem-33h8R {
        position: absolute;
        top: 0;
        right: 0;
        padding: 1px 0;
        color: #ccc;
        font-size: 12px;
    }
</style>
