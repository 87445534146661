<template>
  <div>
    <van-field
        clickable
        v-model="userInfo.name"
        label="昵称"
        placeholder="请输入昵称"
    />
    <van-field name="radio" label="性别">
      <template #input>
        <van-radio-group v-model="userInfo.sex" direction="horizontal" checked-color="#07c160">
          <van-radio name="1">男</van-radio>
          <van-radio name="0">女</van-radio>
        </van-radio-group>
      </template>
    </van-field>
    <van-field
        readonly
        clickable
        name="calendar"
        v-model="userInfo.birthday"
        label="出生日期"
        placeholder="点击选择日期"
        @click="showCalendar = true"
    />
    <van-field clickable
               v-model="userInfo.idcard"
               label="身份证号"
               placeholder="请输入身份证号"
    />
    <van-field v-if="userInfo.chengrenFlag==0"
               clickable
               type="number"
               v-model="userInfo.weight"
               label="出生体重"
               placeholder="请输入出生体重"
    />
    <van-field v-if="userInfo.chengrenFlag==1&&userInfo.sex==0"
               type="digit"
               v-model="userInfo.yuejingRange"
               placeholder="填写月经周期和末次月经周期(2选1)"
               label="月经周期"
    />
    <van-field v-if="userInfo.chengrenFlag==1&&userInfo.sex==0"
               type="digit"
               v-model="userInfo.moyuejingRange"
               placeholder="填写月经周期和末次月经周期(2选1)"
               label="末次月经周期"
    >
    </van-field>
    <van-field v-if="userInfo.chengrenFlag==0"
               v-model="userInfo.yunCount"
               label="孕"
    >
      <template #input v-if="userInfo.chengrenFlag==0">
        <van-stepper v-model="userInfo.yunCount"/>
      </template>
    </van-field>
    <van-field v-if="userInfo.chengrenFlag==0"
               v-model="userInfo.chanCount"
               label="产"
    >
      <template #input v-if="userInfo.chengrenFlag==0">
        <van-stepper v-model="userInfo.chanCount"/>
      </template>
    </van-field>

    <van-field v-if="userInfo.chengrenFlag==1&&userInfo.sex==0"
               type="digit"
               v-model="userInfo.chuchaoAge"
               label="初潮年龄"
    />


    <van-field name="radio" label="分娩方式" v-if="userInfo.chengrenFlag==0">
      <template #input>
        <van-radio-group v-model="userInfo.fenmianFlag" direction="horizontal" checked-color="#07c160">
          <van-radio name="0">剖宫产</van-radio>
          <van-radio name="1">平产</van-radio>
          <van-radio name="2">器械助产</van-radio>
        </van-radio-group>
      </template>
    </van-field>
    <van-field
        name="radio"
        label="喂养方式"
        v-if="userInfo.chengrenFlag==0"
    >母乳/混合/配方
      <template #input>
        <van-radio-group v-model="userInfo.weiyangFlag" direction="horizontal" checked-color="#07c160">
          <van-radio name="0">母乳</van-radio>
          <van-radio name="1">混合</van-radio>
          <van-radio name="2">配方</van-radio>
        </van-radio-group>
      </template>
    </van-field>

    <van-popup v-model="showCalendar" position="bottom" round>
      <van-datetime-picker
          @confirm="onConfirm"
          type="date"
          title="选择年月日"
          :min-date="minDate"
          :max-date="maxDate"
      />
    </van-popup>

<!--    <van-calendar v-model="showCalendar" @confirm="onConfirm" :min-date="minDate" @select="onSelect"/>-->
    <van-field
        v-model="userInfo.guomin"
        rows="3"
        autosize
        label="过敏史"
        maxlength="500"
        show-word-limit
        type="textarea"
        placeholder="请描述过敏史，如果没有可以忽略"
    />
    <van-field
        v-model="userInfo.jiwangjibing"
        rows="3"
        autosize
        label="既往疾病"
        maxlength="500"
        show-word-limit
        type="textarea"
        placeholder="请描述既往疾病，如果没有可以忽略"
    />
    <div style="margin: 0px 10px 0px 10px">
      <van-button type="primary" round block @click="toSub">保存
      </van-button>

      <van-button type="danger" round block @click="delUserInfo" style="margin-top: 10px" v-if="userInfo.id">删除
      </van-button>
    </div>

    <br>
  </div>
</template>

<script>
import {
  Cell,
  CellGroup,
  Tabbar,
  TabbarItem,Popup,
  Button, Field,DatetimePicker,
  List, Form, SubmitBar,
  Tag, Step, Card, RadioGroup, Radio, Calendar, Stepper,
} from 'vant';
import {Empty} from 'vant';
import {postAction} from '@/api/manage';
import {Toast, Dialog} from 'vant';
import {PATIENT_USERID} from '@/store/mutation-types'
import Vue from "vue";

export default {
  components: {
    [Cell.name]: Cell, [Popup.name]: Popup,
    [CellGroup.name]: CellGroup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [Button.name]: Button,
    [List.name]: List,
    [Tag.name]: Tag, [DatetimePicker.name]: DatetimePicker,
    [Step.name]: Step,
    [Field.name]: Field,
    [Form.name]: Form,
    [Empty.name]: Empty,
    [Radio.name]: Radio,
    [Stepper.name]: Stepper,
    [RadioGroup.name]: RadioGroup,
    [Calendar.name]: Calendar,
    [SubmitBar.name]: SubmitBar, [Card.name]: Card,
  },

  data() {
    return {
      userInfo: {
        id: this.$route.query.id,
        sex: '',
        name: '',

        fenmianFlag: '',
        birthday: '',
        guomin: '',
        weight: '',
        idcard: '',
        jiwangjibing: '',
        moyuejingRange: '',
        yuejingRange: '',
        yunCount: 1,
        chuchaoAge: '',
        chanCount: 1,
        chengrenFlag: 0,
        weiyangFlag: '',
      },
      showCalendar: false,
      maxDate:new Date(2025, 10, 1),
      minDate: new Date(1950, 0, 1),
      wx: this
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    getDaysBetween(dateString1) {
      var startDate = Date.parse(dateString1);
      var endDate = new Date();
      if (startDate > endDate) {
        return 0;
      }
      if (startDate == endDate) {
        return 1;
      }
      var days = (endDate - startDate) / (1 * 24 * 60 * 60 * 1000);
      return days;
    },
    toSub() {
      let that = this;

      if (this.userInfo.name == "") {
        Toast("请输入昵称");
        return false;
      }
      if (this.userInfo.birthday == "") {
        Toast("请选择出生日期");
        return false;
      }
      debugger
      if (this.getDaysBetween(this.userInfo.birthday) > 30) {
        if (!(this.userInfo.idcard&&this.userInfo.idcard.trim()!=='')) {
          Toast("请输入身份证号");
          return false;
        }
      }

      if (this.userInfo.chengrenFlag == 1) {
        if (this.userInfo.chuchaoAge == "") {
          Toast("请输入初潮年龄");
          return false;
        }
        if (this.userInfo.moyuejingRange != "" || this.userInfo.yuejingRange != "") {
          console.log(1)
        } else {
          Toast("请输入末次月经周期或月经周期");
          return false;
        }
      } else {
        if (this.userInfo.weight == "" || this.userInfo.weight == 0) {
          Toast("请输入体重");
          return false;
        }
      }
      //发送 post 请求
      this.$nextTick(() => {
        postAction('/web/api/patient/user/savePatientUser', {
          "weight": that.userInfo.weight,
          "name": that.userInfo.name,
          "sex": that.userInfo.sex,
          "id": that.userInfo.id,
          "idcard": that.userInfo.idcard,
          "birthday": that.userInfo.birthday,
          "chengrenFlag": that.userInfo.chengrenFlag,
          "yuejingRange": that.userInfo.yuejingRange,
          "guomin": that.userInfo.guomin,
          "weiyangFlag": that.userInfo.weiyangFlag,
          "chanCount": that.userInfo.chanCount,
          "yunCount": that.userInfo.yunCount,
          "jiwangjibing": that.userInfo.jiwangjibing,
          "fenmianFlag": that.userInfo.fenmianFlag,
          "chuchaoAge": that.userInfo.chuchaoAge,
          "moyuejingRange": that.userInfo.moyuejingRange
        }).then(function (response) {
          console.log(response.result);
          Toast.success("保存成功");
          history.go(-1);
        })
      })
    },
    getUserInfo() {
      let that = this;
      //发送 post 请求
      if (that.userInfo.id) {
        this.$nextTick(() => {
          postAction('/web/api/patient/user/getPatientUser', {
            "id": that.userInfo.id
          }).then(function (response) {
            console.log(response.result);
            response.result.data.weiyangFlag = response.result.data.weiyangFlag + ''
            response.result.data.fenmianFlag = response.result.data.fenmianFlag + ''
            response.result.data.sex = response.result.data.sex + ''
            console.log(response.result);
            that.userInfo = response.result.data
          })
        })
      } else {
        that.userInfo.sex = "1"
        that.userInfo.fenmianFlag = "0"
        that.userInfo.weiyangFlag = "0"
      }
    },
    delUserInfo() {
      let that = this;
      Dialog.confirm({
        message: '确定要删除吗？',
      })
          .then(() => {
            this.$nextTick(() => {
              postAction('/web/api/patient/user/delPatientUser', {
                "id": that.userInfo.id
              }).then(function (response) {
                console.log(response.result);
                Toast.success("删除成功");
                if (Vue.ls.get(PATIENT_USERID)) {
                  if (that.userInfo.id == Vue.ls.get(PATIENT_USERID).id) {
                    Vue.ls.set(PATIENT_USERID, null)
                  }
                }
                history.go(-1);
              })
            })
          })
          .catch(() => {
            // on cancel
          });
    },
    onConfirm(date) {
      console.log()
      const moth = `${date.getMonth() + 1}`
      const day = `${date.getDate()}`
      this.userInfo.birthday = `${date.getFullYear()}-` + (moth < 10 ? ('0' + moth) : moth) + `-` + (day < 10 ? ('0' + day) : day);
      if (this.$sysGetAge(this.userInfo.birthday) > 18) {
        //成人
        // this.userInfo.sex = "0";
        this.userInfo.chengrenFlag = 1;
      } else {
        //小孩
        this.userInfo.chengrenFlag = 0;
      }
      this.showCalendar = false;
    },
    onSelect() {
      console.log()
    },

  }
};

</script>

<style lang="less" scoped>
.van-card__bottom {
  padding-bottom: 20px;
}

.van-card__title {
  font-size: 12pt;
}

.van-card__price {
  font-size: 13pt !important;
}

.van-card__price-integer {
  font-size: 13pt !important;
}

.van-card__header {
  height: 105px;
}

.bgray {
  color: #7d7c7c;
}

.descc {
  font-size: 12pt;
  padding-top: 10px;
}
</style>
