<template>
    <van-action-sheet
            :description="description"
            v-model="show"
            :actions="actions"
            cancel-text="取消"
            @select="onSelect"
            close-on-click-action
    />
</template>

<script>
    import {
        ActionSheet, Toast
    } from 'vant';
    import wx from 'weixin-js-sdk'
    import {postAction} from '@/api/manage';

    export default {
        name: 'ToPay',
        props: {},
        components: {
            [ActionSheet.name]: ActionSheet,
            [Toast.name]: Toast,
        },
        data() {
            return {
                orderInfo: {},
                show: false,
                ordersn: '',
                balance: 0,
                groupCan: 0,
                groupCount: 0,
                description: '',
                vipFlag: 0,
                actions: [
                    // {name: '微信支付', color: '#07c160'}
                ],
            };
        },
        mounted() {
        },
        methods: {
            toShow(ordersn) {
                const that = this;
                that.actions = [
                    // {name: '微信支付', color: '#07c160'}
                ]
                that.ordersn = ordersn;
                postAction('/web/api/order/getOrderToPay', {
                    "ordersn": ordersn
                }).then(function (response) {
                    console.log(response.result);
                    that.orderInfo = response.result.orders;
                    that.balance = response.result.balance;
                    that.groupCan = response.result.groupCan;
                    that.groupCount = response.result.groupCount;
                    that.vipFlag = response.result.vipFlag;
                    if (that.orderInfo.orderFlag == 0) {
                        if (that.orderInfo.moonFlag == 1) {
                            // that.actions.push({name: '套餐支付', subname: '剩余可用' + that.groupCount + '次', disabled: true});
                            that.show = true;
                            that.description = '请选择支付方式';
                            that.actions=[{name: '月子专享', subname: '0元支付', color: '#07c160'}];
                        } else {
                            that.show = true;
                            that.description = '该订单金额为' + that.orderInfo.amount + '元' + (that.vipFlag == 1 ? '(vip专享价)' : '') + '，请选择支付方式';
                            if (that.orderInfo.orderType == 1) {
                                if (that.balance != 0) {
                                    if (that.balance >= that.orderInfo.amount) {
                                        that.actions.push({name: '余额支付', subname: '余额' + that.balance + '元'});
                                    } else {
                                        that.actions.push({
                                            name: '余额支付',
                                            subname: '余额' + that.balance + '元,不足支付',
                                            disabled: true
                                        });
                                    }
                                }
                            } else if (that.orderInfo.orderType == 2) {
                                if (that.balance != 0) {
                                    if (that.balance >= that.orderInfo.amount) {
                                        that.actions.push({name: '余额支付', subname: '余额' + that.balance + '元'});
                                    } else {
                                        that.actions.push({
                                            name: '余额支付',
                                            subname: '余额' + that.balance + '元,不足支付',
                                            disabled: true
                                        });
                                    }
                                }
                            }
                            if (that.groupCan != 0) {
                                // that.actions.push({name: '套餐支付', subname: '剩余可用' + that.groupCount + '次', disabled: true});
                                that.actions.push({name: '套餐支付', subname: '剩余可用' + that.groupCount + '次'});
                            }
                        }

                    } else {
                        Toast.message("订单状态不正确");
                    }
                }).finally(() => {
                })
            },
            onSelect(item) {
                // 默认情况下点击选项时不会自动收起
                // 可以通过 close-on-click-action 属性开启自动收起
                this.show = false;
                const that = this;
                let payFormFlag = 1;
                if (item.name == "微信支付") {
                    payFormFlag = 1;
                }
                if (item.name == "余额支付") {
                    payFormFlag = 2;
                }
                if (item.name == "套餐支付") {
                    payFormFlag = 3;
                }
                if (item.name == "月子专享") {
                    payFormFlag = 4;
                }
                if (payFormFlag == 4) {
                    postAction('/web/api/order/otherPay', {
                        "ordersn": that.ordersn, "payFormFlag": payFormFlag
                    }).then(function (response) {
                        console.log(response.result);
                        that.$emit('ok')
                        const toast = Toast.success({
                            duration: 0, // 持续展示 toast
                            forbidClick: true,
                            message: response.content,
                        });
                        let second = 3;
                        const timer = setInterval(() => {
                            second--;
                            if (second) {
                                toast.message = response.content;
                            } else {
                                clearInterval(timer);
                                // 手动清除 Toast
                                Toast.clear();
                                that.$router.push({name: 'patient/doctorRecord'});
                            }
                        }, 1000);
                    }).finally(() => {
                    })
                }else if (payFormFlag == 2 || payFormFlag == 3) {
                    postAction('/web/api/order/otherPay', {
                        "ordersn": that.ordersn, "payFormFlag": payFormFlag
                    }).then(function (response) {
                        console.log(response.result);
                        that.$emit('ok')
                        const toast = Toast.success({
                            duration: 0, // 持续展示 toast
                            forbidClick: true,
                            message: response.content,
                        });
                        let second = 3;
                        const timer = setInterval(() => {
                            second--;
                            if (second) {
                                toast.message = response.content;
                            } else {
                                clearInterval(timer);
                                // 手动清除 Toast
                                Toast.clear();
                                that.$router.push({name: 'patient/doctorRecord'});
                            }
                        }, 1000);
                    }).finally(() => {
                    })
                } else {
                    postAction('/web/api/order/getRechargeInfo', {
                        "ordersn": that.ordersn
                    }).then(function (res) {
                        console.log(res.result);
                        wx.chooseWXPay({
                            timestamp: res.result.data.payInfo.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                            nonceStr: res.result.data.payInfo.nonceStr, // 支付签名随机串，不长于 32 位
                            package: res.result.data.payInfo.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
                            signType: res.result.data.payInfo.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                            paySign: res.result.data.payInfo.paySign, // 支付签名
                            success: function (res) {
                                console.log(res);
                                // 支付成功后的回调函数
                                that.$emit('ok')
                                const toast = Toast.success({
                                    duration: 0, // 持续展示 toast
                                    forbidClick: true,
                                    message: "支付成功",
                                });
                                let second = 3;
                                const timer = setInterval(() => {
                                    second--;
                                    if (second) {
                                        toast.message = "支付成功"
                                    } else {
                                        clearInterval(timer);
                                        // 手动清除 Toast
                                        Toast.clear();
                                        if(that.orderInfo.orderType==3){
                                            that.$router.push({name: 'patient/myVideoSpecial'});
                                        }else{
                                            that.$router.push({name: 'patient/doctorRecord'});
                                        }
                                    }
                                }, 1000);
                            }
                        });
                    })
                }
            },
        }
    };
</script>

<style lang="less" scoped>
</style>
