<template>
  <div>
    <van-steps :active="active" active-icon="success">
      <van-step>提交问题</van-step>
      <van-step>{{type==='5'?'线下预约':'问诊咨询'}}</van-step>
      <van-step>医生诊断</van-step>
      <van-step>医生评价</van-step>
    </van-steps>
    <div class="order-card-title"></div>
    <!--        <div class="order-card-title">选择家庭成员</div>-->
    <!--        <van-field name="radio" label="性别">-->
    <!--            <template #input>-->
    <!--                <van-radio-group v-model="sex" direction="horizontal" checked-color="#07c160">-->
    <!--                    <van-radio name="1">男</van-radio>-->
    <!--                    <van-radio name="0">女</van-radio>-->
    <!--                </van-radio-group>-->
    <!--            </template>-->
    <!--        </van-field>-->
    <!--        <van-field-->
    <!--                readonly-->
    <!--                clickable-->
    <!--                name="calendar"-->
    <!--                :value="value"-->
    <!--                label="出生日期"-->
    <!--                placeholder="点击选择日期"-->
    <!--                @click="showCalendar = true"-->
    <!--        />-->
    <!--        <van-divider />-->
    <van-cell icon="friends-o" title="选择家庭成员" is-link @click="choosePatientUser"
              :value="patientUser?patientUser.name:''"/>
    <van-calendar v-model="showCalendar" @confirm="onConfirm" :min-date="minDate"/>
    <div class="order-card-title">病情描述</div>
    <van-field
        v-model="message"
        rows="6"
        autosize
        label=""
        maxlength="500"
        show-word-limit
        type="textarea"
        placeholder="请详细描述你的性别、年龄，疾病发生的部位、主要症状、持续时间、已就诊的信息和主治医生的建议，您也可以上传症状部位照片或者检查单，完整的病历资料，会得到医生更详细的指导！"
    />
    <div class="image-upload-tile">添加照片 (疾病部位、检查单等)</div>
    <van-field name="uploader" label="">
      <template #input>
        <van-uploader v-model="imagePathShow" :max-count="6"
                      :after-read="onRead"
                      :before-delete="onDelete"
                      :before-read="beforeRead"/>
      </template>
    </van-field>

    <div class="card-tip">
      <div style="line-height: 21px;">
        <van-icon name="warning-o"/>
        温馨提醒：将由医生为您提供健康咨询服务，您的信息将被严格保密，仅医生可见。
        <!--                <a class="protocol-url">《服务说明》</a>-->
      </div>
    </div>
    <br> <br> <br>
    <van-submit-bar :price="price" :button-text="buttonText" @submit="toSub()" text-align="left" :loading="loading"
                    :disabled="disabled"
                    button-type="primary"/>
    <ToPay ref="toPay" @ok="paySuccess()"></ToPay>
  </div>
</template>

<script>
import {
  Cell,
  CellGroup,
  Tabbar,
  TabbarItem,
  DropdownMenu,
  DropdownItem,
  Button, Field,
  List, Form, Radio, RadioGroup, Calendar, Uploader, SubmitBar,
  Tag, Step, Steps, ActionSheet
} from 'vant';
import {Divider} from 'vant';
import {Toast} from 'vant';
import ToPay from '@/view/patient/order/toPay'
import {postAction, postActionMultipart} from '@/api/manage';
import Vue from "vue";
import {PATIENT_USERID} from '@/store/mutation-types'

export default {
  components: {
    ToPay,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Button.name]: Button,
    [List.name]: List,
    [Divider.name]: Divider,
    [Tag.name]: Tag,
    [Step.name]: Step,
    [Toast.name]: Toast,
    [Field.name]: Field,
    [Form.name]: Form,
    [Radio.name]: Radio,
    [Calendar.name]: Calendar,
    [RadioGroup.name]: RadioGroup,
    [Uploader.name]: Uploader,
    [SubmitBar.name]: SubmitBar,
    [Steps.name]: Steps,
    [ActionSheet.name]: ActionSheet,
  },

  data() {
    return {
      minDate: new Date(1950, 0, 1),
      active: 0,
      username: '',
      password: '',
      sex: '1',
      value: '',
      showCalendar: false,
      message: '',
      imagePath: [],
      imagePathTmp: [],
      imagePathShow: [],
      doctorId: this.$route.query.doctorId,
      type: this.$route.query.type,
      doctorInfo: {},
      price: 0,
      disabled: false,
      loading: false,
      buttonText: '去支付',
      vipFlag: 0,
      vipRatio: this.$sysDictFilter(4, 'chatRule'),
      patientUser: Vue.ls.get(PATIENT_USERID)
    };
  },
  mounted() {
    this.getDoctorInfo();
    if (this.patientUser) {
      this.value = this.patientUser.birthday
      this.sex = this.patientUser.sex
    }
  },
  methods: {
    toSub() {

      console.log('Do some thing, ' + new Date());
      // if (this.imagePath.length == 0) {
      //     Toast('请至少上传一张照片')
      //     return false
      // }
      if (!this.patientUser) {
        Toast('请选择家庭成员')
        return false
      }
      if (this.message == '') {
        Toast('请填写病情描述')
        return false
      }
      // if (this.message.length < 20) {
      //     Toast('病情描述至少20字哦')
      //     return false
      // }
      // if (this.value == "") {
      //     Toast('请选择出生日期')
      //     return false
      // }
      this.loading = true;
      const that = this;
      postAction('/web/api/order/toOrder', {
        "sex": that.sex,
        "orderType": that.type,
        "doctorId": that.doctorId,
        "birthday": that.value,
        "remark": that.message,
        "pics": that.imagePath.join(','),
        "userId": that.patientUser.id
      }).then(function (response) {
        console.log(response.result);
        that.ordersn = response.result.ordersn;
        that.loading = false;
        that.$refs.toPay.toShow(that.ordersn);
      }).finally(() => {
        that.loading = false;
      })
    },
    getDoctorInfo() {
      console.log(this.doctorId)
      var that = this;
      postAction('/web/api/index/doctorInfo', {
        "doctorId": this.doctorId
      }).then(function (response) {
        console.log(response.result);
        that.doctorInfo = response.result.doctor;
        that.vipFlag = response.result.vipFlag;
        if (that.type == 0) {
          that.price = that.doctorInfo.price * 100;
        } else if (that.type == 2) {
          that.price = that.doctorInfo.callPrice * 100;
        } else if (that.type == 1) {
          that.price = that.doctorInfo.groupPrice * 100;
        }else if (that.type == 5) {
          that.price = that.doctorInfo.waterPrice * 100;
        }
      })
    },
    paySuccess() {
      this.disabled = true;
      this.buttonText = "已支付"
    },
    choosePatientUser() {
      this.$router.push({name: 'patient/toChoosePatientUser'});
    },
    onConfirm(date) {
      this.value = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
      this.showCalendar = false;
    },
    dataURLtoFile(dataurl, filename) { // 将base64转换为file文件
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, {type: mime})
    },
    beforeRead(file) { // 上传之前检测图片类似返回true和false会影响到onRead函数
      let regex = /(.jpg|.jpeg|.png|.bmp)$/
      if (!regex.test(file.type)) {
        Toast('图片格式不支持上传')
        return false
      } else {
        return true
      }
    },
    onDelete(file) { // 上传之前检测图片类似返回true和false会影响到onRead函数
      console.log(this.imagePath)
      // eslint-disable-next-line no-debugger
      debugger
      var ipt = [];
      for (let i = 0; i < this.imagePathTmp.length; i++) {
        var index = this.imagePathTmp[i].url.indexOf(file.url);
        // eslint-disable-next-line no-empty
        if (index > -1) {ip1
        } else {
          ipt.push(this.imagePathTmp[i]);
        }
      }

      var urlTmp = file.url.substring(file.url.lastIndexOf("/att"));
      var  ip1= [];
      for (let i = 0; i < this.imagePath.length; i++) {
        var indexpath = this.imagePath.indexOf(urlTmp);
        // eslint-disable-next-line no-empty
        if (indexpath > -1) {
        } else {
          ip1.push(this.imagePath[i]);
        }
      }
      this.imagePathTmp= ipt;
      this.imagePath = ip1;
      this.imagePathShow = ipt;
      return true
    },
    onRead(file) { // 上传图片
      // 大于10MB的图片都缩小像素上传
      if (file.file.size > 1485760) {
        let canvas = document.createElement('canvas') // 创建Canvas对象(画布)
        let context = canvas.getContext('2d')
        let img = new Image()
        img.src = file.content // 指定图片的DataURL(图片的base64编码数据)
        img.onload = () => {
          canvas.width = 400
          canvas.height = 300
          context.drawImage(img, 0, 0, 400, 300)
          file.content = canvas.toDataURL(file.file.type, 0.92) // 0.92为默认压缩质量
          let files = this.dataURLtoFile(file.content, file.file.name)
          const data = new FormData()
          data.append('file', files)
          data.append('type', 'chat')
          postActionMultipart("publicinterface/getImgurlbyfile?imgtype=chat", {data}).then(res => {
            this.imagePath.push(res.content)
            console.log(222)
            this.imagePathTmp.push({url: this.$getFileAccessHttpUrl(res.content)})
            this.imagePathShow = this.imagePathTmp;
            Toast('上传成功')
          })
        }
      } else { //小于10M直接上传
        const data = new FormData()
        data.append('file', file.file)
        data.append('type', 'chat')
        console.log(1111)
        postActionMultipart("publicinterface/getImgurlbyfile?imgtype=chat", data).then(res => {
          this.imagePath.push(res.content)
          this.imagePathTmp.push({url: this.$getFileAccessHttpUrl(res.content)})
          console.log(333)
          this.imagePathShow = this.imagePathTmp;
          console.log(this.imagePathShow);
          Toast('上传成功')
        })
      }
    }
  }
};
</script>

<style lang="less" scoped>
.order-card-title {
  padding: 13px 0;
  font-size: 18px;
  text-align: center;
  font-weight: 500;
  color: #000;
}

.image-upload-tile {
  font-size: 16px;
  padding-top: 10px;
  color: #666;
  background-color: white;
  padding-left: 10px;
}

.card-tip {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px;
  font-size: 14px;
  color: #aaa;
}

.protocol-url {
  font-size: 14px;
  color: #00b4a4;
  text-decoration: none;
}

.van-step__title {
  font-weight: bold;
}
</style>
