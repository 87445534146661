import { render, staticRenderFns } from "./doctorCommitList.vue?vue&type=template&id=5f560c65&scoped=true&"
import script from "./doctorCommitList.vue?vue&type=script&lang=js&"
export * from "./doctorCommitList.vue?vue&type=script&lang=js&"
import style0 from "./doctorCommitList.vue?vue&type=style&index=0&id=5f560c65&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f560c65",
  null
  
)

export default component.exports