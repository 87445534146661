<template>
    <van-address-list
            v-model="chosenAddressId"
            :list="list"
            add-button-text="添加家庭成员"
            @add="onAdd"
            @edit="onEdit"
            @select="onSelect"
    />
</template>

<script>
    import {
        ActionSheet, Toast, AddressList
    } from 'vant';
    import {postAction} from '@/api/manage';
    import Vue from "vue";
    import {PATIENT_USERID} from '@/store/mutation-types'

    export default {
        name: 'ToChoosePatientUser',
        props: {},
        components: {
            [ActionSheet.name]: ActionSheet,
            [Toast.name]: Toast,
            [AddressList.name]: AddressList,
        },
        data() {
            return {
                chosenAddressId: '1',
                list: []
            };
        },
        mounted() {
            this.loadUserList();
        },
        methods: {
            loadUserList() {
                const that = this;
                this.$nextTick(() => {
                    postAction('/web/api/patient/user/patientUserList', {pageNo: 1}).then(function (response) {
                        console.log(response.result);
                        let i = 0;
                        response.result.data.records.map(x => {
                            var jsonObj = {
                                "id": x.id,
                                "name": x.name,
                                "address": ("性别:" + that.$sysDictFilter(x.sex, 'sex') + " 年龄:" +
                                    that.$sysGetAge2(x.birthday.substring(0, 10)) + ""),
                                "tel": "",
                                "isDefault": i == 0
                            };
                            if (i == 0) {
                                if( Vue.ls.get(PATIENT_USERID)){
                                    that.chosenAddressId = Vue.ls.get(PATIENT_USERID).id;
                                }else{
                                    that.chosenAddressId = x.id;
                                }

                            }
                            i = i + 1;
                            that.list.push(jsonObj);
                        })
                    })
                })
            },
            onAdd() {
                this.$router.push({name: 'patient/myPatientUserInfo'});
            },
            onSelect(item) {
                Vue.ls.set(PATIENT_USERID, item)
                history.go(-1);
            },
            onEdit(item) {
                this.$router.push({name: 'patient/myPatientUserInfo', query: {id: item.id}});
            },
        }
    };
</script>

<style lang="less" scoped>
</style>
